import { deleteProviderWorkingPlace, addProviderWorkingPlace, getProviderWorkingPlaces } from '../../api/providers';
import { notification } from 'antd';

export const useProviderWorkingPlaces = (updateChanges: any, setLoading: any) => {

  const getProviderWorkPlaces = (providerId: string) => {
    setLoading(true);
    return getProviderWorkingPlaces(providerId)
        .then((resp: any) => {
            if (resp.status === 'ERROR') {
                return notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            }
            return resp
        })
        .catch(() => {
            notification.error({
                message: 'Something went wrong!',
                duration: 2
            })
        })
  }

  const deleteProviderWorkPlace = (providerId: string, serviceAreaIds: any) => {
    setLoading(true);
    return deleteProviderWorkingPlace(providerId, { serviceAreaIds })
        .then((resp: any) => {
            if (resp.status === 'ERROR') {
                notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            }
            updateChanges()
        })
        .catch(() => {
            notification.error({
                message: 'Something went wrong!',
                duration: 2
            })
        })
  }

  const addProviderWorkPlace = (providerId: string, payload: any) => {
    setLoading(true);
      return addProviderWorkingPlace(providerId, { details: payload })
          .then((resp: any) => {
              if (resp.status === 'ERROR') {
                  notification.error({
                      message: 'Something went wrong!',
                      duration: 2
                  })
              }
              updateChanges()
          })
          .catch(() => {
              notification.error({
                  message: 'Something went wrong!',
                  duration: 2
              })
          })
  }

  return { deleteProviderWorkPlace, addProviderWorkPlace, getProviderWorkPlaces };

};
