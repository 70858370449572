import React, { useContext, useState } from 'react';
import { Card, Typography, Space, Button, Modal, Popconfirm } from 'antd';
import { getTypeText, QuestionModalContent } from './HelpCenter';
import { deleteItem } from '../../../api/helpCenterItems';
import { MainContext } from '../../../App';
import { isUserRoleMarketing } from '../../../utils/helpers';

const { Text } = Typography;

export interface HelpCenterItemType {
    id: string;
    serviceType: string;
    text: string;
}

interface HelpCenterItemProps {
    item: HelpCenterItemType;
    language: string;
    onUpdate: () => void;
}

const DELETE_CONFIRM_TEXT = 'Are you sure to delete this question';

export const HelpCenterItem: React.FC<HelpCenterItemProps> = ({item, onUpdate, language}) => {
    const [deletLoading, setDeleteLoading] = useState(false);
    const [isQuestionModalOpened, toggleQuestionModal] = useState(false);
    const { userAuthData } = useContext(MainContext);
    const handleItemDelete = async () => {
        setDeleteLoading(true);
        const deleteResp = await deleteItem(item.id);
        setDeleteLoading(false);
        if (deleteResp.status !== 'ERROR') {
            onUpdate();
        }
    }

    const handleEditClick = () => {
        toggleQuestionModal(true);
    }

    return (
        <React.Fragment>
            <Card>
                <Text style={styles.ItemHeading as React.CSSProperties} strong>
                    {getTypeText(item.serviceType, language)}
                </Text>
            </Card>
            <br/>
            <Card>
                <Text>{item.text}</Text>
                <Space style={styles.ButtonsContainer as React.CSSProperties}>
                    <Popconfirm placement="bottom" title={DELETE_CONFIRM_TEXT} onConfirm={handleItemDelete} okText="Yes" cancelText="No" disabled={isUserRoleMarketing(userAuthData)}>
                        <Button loading={deletLoading} danger style={styles.Button} disabled={isUserRoleMarketing(userAuthData)}>Delete</Button>
                    </Popconfirm>
                    <Button onClick={handleEditClick} style={styles.Button} type='primary' disabled={isUserRoleMarketing(userAuthData)}>Edit</Button>
                </Space>
            </Card>
            <Modal
                visible={isQuestionModalOpened}
                title='Edit Question'
                footer={null}
                onCancel={() => toggleQuestionModal(false)}
            >
                <QuestionModalContent
                    questionData={item}
                    onModalClose={() => toggleQuestionModal(false)}
                    onUpdatePage={onUpdate}
                />
            </Modal>
        </React.Fragment>
    )
}

const styles = {
    ItemHeading: {
        textAlign: 'center',
        fontSize: 18
    },
    ButtonsContainer: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'center'
    },
    Button: {
        width: 90,
        margin: '0 8px'
    }
}