import React from "react";
import { Input, Typography, Select } from "antd";
import { PromoCodeRequest, PromoCodeSubType, PromoCodeType, UpdatedContent } from "./campaingTypes";
import { styles } from "./styles";
import { FreeTimeTypeContent } from "./FreeTimeTypeContent";
import { AmountDiscountTypeContent } from "./AmountDiscountTypeContent";
import { DiscountPercentTypeContent } from "./DiscountPercentTypeContent";
import { containOnlyLettersAndNumbers } from '../../utils/helpers';

const { Text } = Typography;
const { Option } = Select;

interface InviteFriendCampaingContentProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    providerTypes: any[];
    disabled: boolean;
}


export const InviteFriendCampaingContent: React.FC<InviteFriendCampaingContentProps> = ({ modalContent, setModalContent, providerTypes, disabled }) => {

    const handlePromoCodeNameChange = (event: any) => {
        if(!containOnlyLettersAndNumbers(event.nativeEvent.target.value)){
            return
        }
        setModalContent({
            ...modalContent,
            name: event.nativeEvent.target.value
        })
    }

    const handlePromoCodeSubTypeChangeByProperty = (property: UpdatedContent) => (subType: PromoCodeSubType) => {
        setModalContent({
            ...modalContent,
            [property]: {
                startDate: modalContent[property]?.startDate,
                endDate: modalContent[property]?.endDate,
                subType,
            }
        })
    }

    const handleBonusChange = (updatedContent: UpdatedContent) => (event: any) => {
        const { value } = event.nativeEvent.target;
        const numbers = /^[0-9]+$/;
        if (value.match(numbers) || value === '') {
            setModalContent({
                ...modalContent,
                [updatedContent]: {
                    ...modalContent[updatedContent],
                    bonusAmount : parseInt(event.nativeEvent.target.value) || undefined,
                    subType: PromoCodeSubType.PROVIDER_BONUS,
                }
            })
        }
    }

    const getCapmaingByPromoCodeSubType = (updatedContent: UpdatedContent) => (promoCodeSubType?: PromoCodeSubType) => {
        switch(promoCodeSubType) {
            case PromoCodeSubType.CLIENT_ORDER_FREE_TIME:
                return <FreeTimeTypeContent updatedContent={updatedContent} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled}/>;
            case PromoCodeSubType.CLIENT_ORDER_AMOUNT_DISCOUNT:
                return <AmountDiscountTypeContent updatedContent={updatedContent} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            case PromoCodeSubType.CLIENT_ORDER_DISCOUNT_PERCENT:
                return <DiscountPercentTypeContent updatedContent={updatedContent} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            default: return null;
        }
    }

    const handleChangeProviderType = (providerTypesArr: any) => {
        const ids : string[] = providerTypesArr.map((i:any) => i.value);
        setModalContent({
            ...modalContent,
            providerTypeIds: ids
        })
    }

    const options = providerTypes.map((providerType: any) => {
        return { value: providerType.id, label: providerType.name }
    })
    const value = options.filter((option: any) =>{
        return modalContent?.providerTypeIds?.find((id) => option.value === id)
    })

    return (
        <div>
            {(
                modalContent.type === PromoCodeType.INVITE_CLIENT_TO_PROVIDER_N2
                || modalContent.type === PromoCodeType.INVITE_CLIENT_TO_PROVIDER
                || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_PROVIDER_N2
                || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_PROVIDER
            ) && <Select
                    labelInValue
                    mode="multiple"
                    showSearch={false}
                    placeholder="Provider type"
                    onChange={handleChangeProviderType}
                    style={styles.modalRowItem}
                    showArrow
                    disabled={disabled}
                    options={options}
                    value={value}
            />}
            <Input
                value={modalContent.name || ''}
                onChange={handlePromoCodeNameChange}
                style={styles.modalRowItem}
                placeholder='Promo Code Name'
                maxLength={32}
                disabled={disabled}
            />
            <div style={styles.modalRowItem}>
                <Text>Inviter</Text>
            </div>
            {(modalContent.type === PromoCodeType.INVITE_CLIENT_TO_CLIENT || modalContent.type === PromoCodeType.INVITE_CLIENT_TO_PROVIDER || modalContent.type === PromoCodeType.INVITE_CLIENT_TO_CLIENT_N2 || modalContent.type === PromoCodeType.INVITE_CLIENT_TO_PROVIDER_N2)
                && <Select value={modalContent.inviterDetails?.subType} onChange={handlePromoCodeSubTypeChangeByProperty(UpdatedContent.inviterDetails)} placeholder='Select The Campaign' style={styles.modalRowItem} disabled={disabled}>
                <Option value={PromoCodeSubType.CLIENT_ORDER_FREE_TIME}>Free Time</Option>
                <Option value={PromoCodeSubType.CLIENT_ORDER_AMOUNT_DISCOUNT}>Amount Discount</Option>
                <Option value={PromoCodeSubType.CLIENT_ORDER_DISCOUNT_PERCENT}>Discount %</Option>
                <Option value={PromoCodeSubType.NO_CAMPAIGN}>No Campaign</Option>
            </Select>}
            {getCapmaingByPromoCodeSubType(UpdatedContent.inviterDetails)(modalContent.inviterDetails?.subType)}
            {(modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_CLIENT || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_PROVIDER || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_CLIENT_N2 || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_PROVIDER_N2) &&
                <Select value={modalContent.inviterDetails?.subType} onChange={handlePromoCodeSubTypeChangeByProperty(UpdatedContent.inviterDetails)} placeholder='Select The Campaign' style={styles.modalRowItem}  disabled={disabled}>
                    <Option value={PromoCodeSubType.PROVIDER_BONUS}>Bonus</Option>
                    <Option value={PromoCodeSubType.NO_CAMPAIGN}>No Campaign</Option>
                </Select>
            }
            {modalContent.inviterDetails?.subType === PromoCodeSubType.PROVIDER_BONUS && <Input
                value={modalContent[UpdatedContent.inviterDetails]?.bonusAmount || ''}
                onChange={handleBonusChange(UpdatedContent.inviterDetails)}
                style={styles.modalRowItem}
                placeholder='Bonus Amount'
                disabled={disabled}
            />}
            <div style={styles.modalRowItem}>
                <Text>Receiver</Text>
            </div>
            {(modalContent.type === PromoCodeType.INVITE_CLIENT_TO_CLIENT || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_CLIENT || modalContent.type === PromoCodeType.INVITE_CLIENT_TO_CLIENT_N2 || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_CLIENT_N2)
                && <Select value={modalContent.receiverDetails?.subType} onChange={handlePromoCodeSubTypeChangeByProperty(UpdatedContent.receiverDetails)} placeholder='Select The Campaign' style={styles.modalRowItem} disabled={disabled}>
                <Option value={PromoCodeSubType.CLIENT_ORDER_FREE_TIME}>Free Time</Option>
                <Option value={PromoCodeSubType.CLIENT_ORDER_AMOUNT_DISCOUNT}>Amount Discount</Option>
                <Option value={PromoCodeSubType.CLIENT_ORDER_DISCOUNT_PERCENT}>Discount %</Option>
                <Option value={PromoCodeSubType.NO_CAMPAIGN}>No Campaign</Option>
            </Select>}
            {getCapmaingByPromoCodeSubType(UpdatedContent.receiverDetails)(modalContent.receiverDetails?.subType as PromoCodeSubType)}
            {(modalContent.type === PromoCodeType.INVITE_CLIENT_TO_PROVIDER || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_PROVIDER || modalContent.type === PromoCodeType.INVITE_CLIENT_TO_PROVIDER_N2 || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_PROVIDER_N2) &&
                <Select value={modalContent.receiverDetails?.subType} onChange={handlePromoCodeSubTypeChangeByProperty(UpdatedContent.receiverDetails)} placeholder='Select The Campaign' style={styles.modalRowItem} disabled={disabled}>
                    <Option value={PromoCodeSubType.PROVIDER_BONUS}>Bonus</Option>
                    <Option value={PromoCodeSubType.NO_CAMPAIGN}>No Campaign</Option>
                </Select>
            }
            {modalContent.receiverDetails?.subType === PromoCodeSubType.PROVIDER_BONUS  &&
              <Input
                value={modalContent[UpdatedContent.receiverDetails]?.bonusAmount || ''}
                onChange={handleBonusChange(UpdatedContent.receiverDetails)}
                style={styles.modalRowItem}
                placeholder='Bonus Amount'
                disabled={disabled}
            />}
        </div>
    )
}