import React, { useContext, useState } from 'react';
import { Card, Typography, Space, Button, Modal, Popconfirm } from 'antd';
import { getFAQTypeText, FAQModalContent } from './FAQ';
import { deleteFAQ} from '../../../api/faqs';
import { isUserRoleMarketing } from '../../../utils/helpers';
import { MainContext } from '../../../App';

const { Text } = Typography;

export interface FAQType {
    id: string;
    serviceType: string;
    question: string;
    answer: string;
}

interface ActiveFAQProps {
    faq: FAQType;
    language: string;
    onUpdate: () => void;
    appType: string;
}

const DELETE_CONFIRM_TEXT = 'Are you sure to delete this FAQ';

export const ActiveFAQ: React.FC<ActiveFAQProps> = ({faq, onUpdate, language, appType}) => {
    const [deletLoading, setDeleteLoading] = useState(false);
    const [isFAQModalOpened, toggleFAQModal] = useState(false);
    const { userAuthData } = useContext(MainContext);
    const handleItemDelete = async () => {
        setDeleteLoading(true);
        const deleteResp = await deleteFAQ(faq.id);
        setDeleteLoading(false);
        if (deleteResp.status !== 'ERROR') {
            onUpdate();
        }
    }

    const handleEditClick = () => {
        toggleFAQModal(true);
    }

    return (
        <React.Fragment>
            <Card>
                <Text style={styles.ItemHeading as React.CSSProperties} strong>
                    {getFAQTypeText(faq.serviceType, language)}
                </Text>
            </Card>
            <br/>
            <Card>
                <Text style={styles.FirstSubHeading}>Question</Text>
                <Text>{faq.question}</Text>
                <Text style={styles.SecondSubHeading}>Answer</Text>
                <Text>{faq.answer}</Text>
                <Space style={styles.ButtonsContainer as React.CSSProperties}>
                    <Popconfirm placement="bottom" title={DELETE_CONFIRM_TEXT} onConfirm={handleItemDelete} okText="Yes" cancelText="No" disabled={isUserRoleMarketing(userAuthData)}>
                        <Button loading={deletLoading} danger style={styles.Button} disabled={isUserRoleMarketing(userAuthData)}>Delete</Button>
                    </Popconfirm>
                    <Button onClick={handleEditClick} style={styles.Button} type='primary' disabled={isUserRoleMarketing(userAuthData)}>Edit</Button>
                </Space>
            </Card>
            {isFAQModalOpened && <Modal
                visible={isFAQModalOpened}
                title='Edit Question'
                footer={null}
                onCancel={() => toggleFAQModal(false)}
            >
                <FAQModalContent
                    FAQData={faq}
                    onModalClose={() => toggleFAQModal(false)}
                    onUpdatePage={onUpdate}
                    appType={appType}
                />
            </Modal>}
        </React.Fragment>
    )
}

const styles = {
    ItemHeading: {
        textAlign: 'center',
        fontSize: 18
    },
    ButtonsContainer: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'center'
    },
    Button: {
        width: 90,
        margin: '0 8px'
    },
    FirstSubHeading: {
        display: 'block',
        fontSize: 16,
        fontWeight: 500,
        margin: '0 0 8px'
    },
    SecondSubHeading: {
        display: 'block',
        fontSize: 16,
        fontWeight: 500,
        margin: '16px 0 8px'
    }
}