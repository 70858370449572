import { authorisedRequest } from './authorisedRequest';

export const getTopServices = (payload: any) => {
    let query = '?';
    if (payload) {
        for (let [key, value] of Object.entries(payload)) {
            query += `&${key}=${value}`;
        }
    }
    return authorisedRequest(`/dashboard/top-services${query}`);
}

export const getPayments = (payload: any) => {
    let query = '?';
    if (payload) {
        for (let [key, value] of Object.entries(payload)) {
            query += `&${key}=${value}`;
        }
    }
    return authorisedRequest(`/dashboard/payments${query}`);
}