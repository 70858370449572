import { authorisedRequest } from './authorisedRequest';

export const fetchTicketDetails = (id: string) => {
  return authorisedRequest(`/admin/help-center/tickets/${id}`);
}

export const updateTicketStatus = (id: string, status: string) => {
    return authorisedRequest(`/help-center/tickets/${id}`, 'PUT', { status })
}

