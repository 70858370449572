import React from 'react';
import { Layout, Avatar, Tooltip, Typography } from 'antd';
import { InnerLayout } from '../../components/InnerLayout';
import { Pager } from '../../components/pager/Pager';
import { formatDate } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import { WarningFilled } from '@ant-design/icons';

const { Text } = Typography;

export const Clients = () => {
  const history = useHistory();
  const configs =[
    {
      label: 'All',
      params: {
        exportable: true,
      }
    },
  ];

  const url = `/admin/customers`;
  const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: {
        multiple: 1
      },
      render(fullName: any, client: any) {
        return (
          <div>
            <Avatar src={client.profileUrl || defaultImage} /><Text style={{ marginLeft: 8 }}>{fullName}</Text>
            {client.user?.deactivated &&
             <Tooltip title='Deactivated'>
               <WarningFilled style={styles.warningIcon} />
            </Tooltip>}
          </div>
        ) 
      },

    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      render(email: string) {
        return <a href={`mailto: ${email}`}>{email}</a>
      },
    },
    {
      title: 'Phone',
      dataIndex: ['user', 'mobile'],
    },
    {
      title: 'Registration date',
      key: 'createdDate',
      dataIndex: ['user', 'createdDate'],
      sorter: {
        multiple: 1
      },
      render(createdDate: string) {
        return formatDate(createdDate, 'DD-MM-YYYY HH:mm');
      }
    },
    {
      title: 'Last Order Date',
      dataIndex: 'lastOrderDate',
      key: 'lastOrderDate',
      sorter: {
        multiple: 1
      },
      render(lastOrderDate: string) {
        return lastOrderDate ? formatDate(lastOrderDate, 'DD-MM-YYYY HH:mm') : 'N/A';
      }
    },
    {
      title: 'Last Active',
      dataIndex: ['user', 'lastActiveDate'],
      render(lastActiveDate: string) {
        return formatDate(lastActiveDate, 'DD-MM-YYYY HH:mm');
      }
    },
    {
      title: 'Bank Info',
      dataIndex: 'hasPaymentMethod',
      key: 'hasPaymentMethod',
      sorter: {
        multiple: 1
      },
      render(hasPaymentMethod: boolean) {
        return hasPaymentMethod ? 'Yes' : 'No';
      }
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
    },
  ];

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
        <Pager url={url} columns={columns} configs={configs} rowClick={(id: string, _1: number, _2: any, currentPage: number) => {
          history.push(`/clients/${id}/${currentPage}`)
        }
        }  />
      </Layout.Content>
    </InnerLayout>
  );
};

const styles = {
  warningIcon: {
    marginLeft: 10,
    color: '#ee7080',
  }
}
