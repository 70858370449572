import React, { useEffect, useState } from 'react';
import { Card, Layout, Switch, Space,Typography } from 'antd';
import { InnerLayout } from '../../../components/InnerLayout';
import { Row, Col } from 'antd';
import { getPublicProfileConfigs, changePublicProfileConfigs } from '../../../api/billBoard';
import './billboard.css';
const { Text } = Typography;

export interface PublicProfileConfig {
    id: string;
    fieldName: string;
    enabled: boolean;
}

const  FieldNames: { [key: string]: string } = {
    'longBio': 'Long Bio',
    'shouldShowQrCode': 'Qr Code',
    'shortBio': 'Short Bio',
    'experience': 'Experience',
    'languages': 'Languages',
    'rating': 'Rate',
    'fullName': 'Full Name',
    'availability': 'Availability',
    'gender': 'Gender',
    'addresses': 'Address',
    'mobile': 'Phone Number',
    'email': 'Email',
    'profileUrl': 'Profile Picture',
    'workingPlaces': 'Working Places',
    'serviceTypeIds': 'Service types',
    'birthDate': 'Date of Birth',
    'serviceAreas': 'Service Areas',
    'position': 'Position'
}


export const Billboard = () => {
    const [billBoardData, setBillBoardData] = useState<PublicProfileConfig[]>([])
    useEffect(()=>{
        getPublicProfileConfigs()
            .then((data: PublicProfileConfig[])=>{
                setBillBoardData(data as PublicProfileConfig[])
            })
    }, [])

    const onChange = (id: string) => {
        const newBillBoardData = billBoardData.map((field: PublicProfileConfig) => {
            if( id === field.id){
                return {...field, enabled: !field.enabled}
            }
            return field
        })
        changePublicProfileConfigs(id)
            .then(() => {
                setBillBoardData(newBillBoardData as PublicProfileConfig[])
            })

    }

    return(
        <InnerLayout>
            <Layout.Content className="site-layout" style={{ padding: '16px 35px', marginTop: 64 }}>
                <Card className={'card'}>
                    <Text className={'titleText'}>Public information</Text>
                        {
                            !!billBoardData?.length &&
                            <Row gutter={[24, 24]}>
                                {
                                    billBoardData.map(({fieldName,enabled, id}: PublicProfileConfig) => {
                                        return(
                                            <Col span={5} key={id}>
                                                <Card>
                                                    <Space align="center">
                                                        <Text className={'fieldName'}>{FieldNames[fieldName]}</Text>
                                                        <Switch
                                                            checked={enabled}
                                                            onChange={() => onChange(id)}
                                                            style={{backgroundColor: enabled ? '#7956D8' : ''}}
                                                        />
                                                    </Space>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        }
                </Card>
            </Layout.Content>
        </InnerLayout>)
}