import React, { CSSProperties, useContext, useState } from 'react';
import { Tooltip, Space, Descriptions, Card, Typography, Upload, Button, DatePicker, Modal, Image, Carousel, Input } from 'antd';
import moment from 'moment'
import { UploadOutlined, FileImageOutlined } from '@ant-design/icons';
import arrowLeftIcon from '../../icons/arrowLeftIcon.svg';
import arrowRightIcon from '../../icons/arrowRightIcon.svg';
import {
    uploadNewDocuments,
    updateDocumentExpirationDate,
    acceptDocument,
    completeSignUp,
    rejectDocument,
    archiveDocument,
    addAdminComment
} from '../../api/providers';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import './carousel.css';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { getUserRoles, isUserRoleMarketing, UserRole } from '../../utils/helpers';
import { MainContext } from '../../App';
const { TextArea } = Input;
const { Text } = Typography;

export const AllCitizenHouseHoldHelperDocumentsModels =
    [
        { title: 'Profile Picture', type: 'PROFILE' },
        { title: 'ID Front', type: 'NATIONAL_ID_FRONT' },
        { title: 'ID Back', type: 'NATIONAL_ID_BACK' },
        { title: 'Optional documents', type: 'OTHER' },
    ];

export const AllNonCitizenHouseHoldHelperDocumentsModels =
    [
        { title: 'Profile Picture', type: 'PROFILE' },
        { title: 'Passport', type: 'PASSPORT' },
        { title: 'Residence permit front', type: 'RESIDENCE_PERMIT_FRONT' },
        { title: 'Residence permit back', type: 'RESIDENCE_PERMIT_BACK' },
        { title: 'Optional documents', type: 'OTHER' },
    ]

export const AllCitizenCareHelperDocumentsModels =
    [
        { title: 'Profile Picture', type: 'PROFILE' },
        { title: 'ID Front', type: 'NATIONAL_ID_FRONT' },
        { title: 'ID Back', type: 'NATIONAL_ID_BACK' },
        { title: 'Professional Certificate', type: 'PROFESSIONAL_CERTIFICATE' },
        { title: 'Professional liability insurance', type: 'PROFESSIONAL_LIABILITY_INSURANCE' },
        { title: 'Police Certificate', type: 'CRIMINAL_REPORT' },
        { title: 'Optional documents', type: 'OTHER' },
    ]

export const AllNonCitizenCareHelperDocumentsModels =
    [
        { title: 'Profile Picture', type: 'PROFILE' },
        { title: 'Passport', type: 'PASSPORT' },
        { title: 'Residence permit front', type: 'RESIDENCE_PERMIT_FRONT' },
        { title: 'Residence permit back', type: 'RESIDENCE_PERMIT_BACK' },
        { title: 'Professional Certificate', type: 'PROFESSIONAL_CERTIFICATE' },
        { title: 'Professional liability insurance', type: 'PROFESSIONAL_LIABILITY_INSURANCE' },
        { title: 'Police Certificate', type: 'CRIMINAL_REPORT' },
        { title: 'Optional documents', type: 'OTHER' },
    ]
const isWithExpDate = (docType: string) => {
    switch (docType) {
        case 'PASSPORT':
        case 'NATIONAL_ID_FRONT':
        case 'NATIONAL_ID_BACK':
        case 'RESIDENCE_PERMIT_FRONT':
        case 'RESIDENCE_PERMIT_BACK':
            return true;
        default: return false;
    }
}

export const REQUIRED_CITIZEN_DOCUMENTS = [
    'PROFILE',
    'NATIONAL_ID_FRONT',
    'NATIONAL_ID_BACK',
];

export const REQUIRED_NON_CITIZEN_DOCUMENTS = [
    'PROFILE',
    'PASSPORT',
    'RESIDENCE_PERMIT_FRONT',
    'RESIDENCE_PERMIT_BACK',
];
export const commonDocuments = [
    'PROFESSIONAL_CERTIFICATE', 'PROFESSIONAL_LIABILITY_INSURANCE', 'CRIMINAL_REPORT', 'OTHER'
];

interface ProviderDocumentsProps {
    providerId: any;
    provider: any;
    providerTypes: any;
    onUpdate: () => void;
    hasRequiredDocs: boolean
}

export const ProviderDocuments: React.FC<ProviderDocumentsProps> = ({ providerId, provider, onUpdate, hasRequiredDocs, providerTypes }) => {
    const { userAuthData } = useContext(MainContext);
    const providerTypeName = providerTypes.find((type: any) => type.id === provider.providerTypeId)?.name;
    const AllCitizenDocumentsModels =
        providerTypeName !== 'Household Helper'?
            // documents.find((doc: any) => commonDocuments.slice(0, commonDocuments.length-1).includes(doc.type))) ?
            // || oldDocuments.find((doc: any) => commonDocuments.slice(0, commonDocuments.length-1).includes(doc.type))) ?
        AllCitizenCareHelperDocumentsModels : AllCitizenHouseHoldHelperDocumentsModels;


    const AllNonCitizenDocumentsModels =
        providerTypeName !== 'Household Helper' ?
            // documents.find((doc: any) => commonDocuments.slice(0, commonDocuments.length-1).includes(doc.type)))?
            // || oldDocuments.find((doc: any) => commonDocuments.slice(0, commonDocuments.length-1).includes(doc.type))) ?
            AllNonCitizenCareHelperDocumentsModels : AllNonCitizenHouseHoldHelperDocumentsModels;

    const [completeLoading, setCompleteLoading] = useState(false);

    const handleCompleteClick = async () => {
        setCompleteLoading(true);
        const completeResp = await completeSignUp(providerId);
        setCompleteLoading(false);
        if (completeResp.status !== 'ERROR') {
            onUpdate();
        }
    }

    return (
        <Card>
            <Descriptions
                column={4}
            >
                {(provider.isCitizen ? AllCitizenDocumentsModels : AllNonCitizenDocumentsModels).map(documentModel => {
                    const documentType = provider?.documentTypes?.find((docType: any) => docType.type === documentModel.type)

                    return (
                        <Descriptions.Item key={documentModel.type} style={styles.ProviderDocContainer}>
                            <DocumentSection
                                documentType={documentType}
                                providerId={providerId}
                                title={documentModel.title}
                            />
                        </Descriptions.Item>
                    )
                })}
            </Descriptions>

            <Space style={styles.CompleteButton as React.CSSProperties}>
                {provider.status === 'DRAFT' &&
                <Button
                    loading={completeLoading}
                    onClick={handleCompleteClick}
                    disabled={!hasRequiredDocs || isUserRoleMarketing(userAuthData)}
                    type="primary"
                >
                    Complete
                </Button>}
            </Space>
        </Card>
    )
}

interface DocumentType {
    type: string;
    id: string;
    adminComment: null | string;
    providerComment: null | string;
    documents: any[];
}

interface DocumentSectionProps {
    title: string;
    expDate?: Date;
    providerId: string;
    documentType: DocumentType
}

const DocumentSection: React.FC<DocumentSectionProps> = ({ title, providerId,  documentType: documentTypeItem }) => {
    const [uploadLoading, setUploadLoading] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [archiveLoading, setArchiveLoading] = useState(false);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [documentType, setDocumentType] = useState(documentTypeItem);
    const [numPages, setNumPages] = useState(null);
    const [comment, setComment] = useState<string>(documentType?.adminComment || '');
    let CarouselRef: any;
    const { userAuthData } = useContext(MainContext);
    const  { providerComment, adminComment, id, type, documents } = documentType;

    const handleModalOnClose = () => {
        setComment('');
        setModalVisibility(false);
    }

    const customRequest = async (option: RcCustomRequestOptions) => {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append('file', option.file);
        const uploadResp = await uploadNewDocuments(providerId, type, formData);
        setUploadLoading(false);
        if (uploadResp.status !== 'ERROR') {
            if (!commonDocuments.includes(uploadResp.type)) {
                setDocumentType({
                    ...documentType,
                    documents: [uploadResp, { ...documents[0], status: documents[0].status !== 'KO'? 'ARCHIVE' : documents[0].status }],
                });
            } else {
                setDocumentType({
                    ...documentType,
                    documents: [uploadResp, ...documents],
                });
            }
        }
    }

    const handleViewClick = () => {
        setModalVisibility(true);
        setComment(adminComment as string);
    }

    const handleAcceptClick = (document: any) => () => {
        handleDocumentAccept(document);
    }

    const handleRejectClick = (document: any) => () => {
        handleDocumentReject(document);
    }
    const handleArchiveClick = (document: any) => () => {
        handleDocumentArchive(document);
    }

    const handleDocumentAccept = async (acceptedDocument: any) => {
        setAcceptLoading(true);
        const acceptResp = await acceptDocument(providerId, acceptedDocument.id);
        setAcceptLoading(false);
        if (acceptResp.status !== 'ERROR') {
            const updatedDocuments = documents.map((doc: any) => {
                if (doc.id === acceptedDocument.id && type === 'CRIMINAL_REPORT') {
                    if(allDocuments.length === 1){
                        setModalVisibility(false);
                    }
                    return { ...acceptedDocument, status: 'OK', amazonUrl: '' };
                } else if (doc.id === acceptedDocument.id){
                    return { ...acceptedDocument, status: 'OK' };
                }
                return doc;
            })
            setDocumentType({...documentType, documents: [...updatedDocuments]})
        }
    }

    const handleDocumentReject = async (rejectedDocument: any) => {
        setRejectLoading(true);
        const rejectResp = await rejectDocument(providerId, rejectedDocument.id);
        setRejectLoading(false);
        if (rejectResp.status !== 'ERROR') {
            const updatedDocuments = documents.map((doc: any) => {
                if (doc.id === rejectedDocument.id) {
                    return { ...rejectedDocument, status: 'KO' };
                }
                return doc;
            })
            setDocumentType({...documentType, documents: [...updatedDocuments]})
        }
    }

    const handleDocumentArchive = async (archivedDocument: any) => {
        setArchiveLoading(true);
        const archiveResp = await archiveDocument(providerId, archivedDocument.id);
        setArchiveLoading(false);
        if (archiveResp.status !== 'ERROR') {
            const updatedDocuments = documents.map((doc: any) => {
                if (doc.id === archivedDocument.id) {
                    return { ...archivedDocument, status: 'ARCHIVE' };
                }
                return doc;
            })
            setDocumentType({...documentType, documents: [...updatedDocuments]})
        }
    }

    const hasToApprovedDoc = documents.some((doc: any) => doc.status === 'TO_APPROVE');
    const uploadButtonDisabled = () => {
        const allDocsCount = documents.length;
        const activeDocsCount = documents.filter((doc: any) => doc.status === 'KO' || doc.status === 'ARCHIVE').length;
        const countAllowDocs = allDocsCount - activeDocsCount;
        return (commonDocuments.includes(type) && countAllowDocs >= 5)
    }

    // const hasRejected = documents?.some((document: any) => {
    //     return document.status === 'KO';
    // });
    // const allArchived = documents?.every((document: any) => {
    //     return document.status === 'ARCHIVE';
    // });
    // const hasAccepted = documents?.some((document: any) => {
    //     return document.status === 'OK';
    // });

    const hasRejected = documents?.filter((item: any) => item.status !== 'ARCHIVE')?.some((document: any) => {
        return document.status === 'KO';
    });
    const allArchived = documents?.every((document: any) => {
        return document.status === 'ARCHIVE';
    });
    const hasAccepted = documents?.filter((item: any) => item.status !== 'ARCHIVE')?.some((document: any) => {
        return document.status === 'OK';
    });

    function onDocumentLoadSuccess(data: any) {
        setNumPages(data.numPages);
    }

    const handleChangeComment = (e: any) => {
        const value = e.target.value;
        setComment(value);
    }

    const handleSaveComment = async () => {
        if(adminComment !== comment.trim()){
            const resp = await addAdminComment(providerId, type, id, { adminComment: comment });
            if (resp.status !== 'ERROR') {
                setDocumentType({...documentType, adminComment: comment })
                setModalVisibility(false);
            }
        }
    }

   const filteredCriminalReport = documents.filter((doc:any) => doc?.amazonUrl);
   const allDocuments = type !== 'CRIMINAL_REPORT'? documents: filteredCriminalReport;

    return (
        <div
            style={(hasToApprovedDoc ? styles.UpdatedDocumentSectionContainer : styles.DocumentSectionContainer) as React.CSSProperties}>
            <Space style={styles.SectionTitleContainer as React.CSSProperties}>
                <Tooltip placement="topLeft" title={title}>
                    <Text style={ (hasToApprovedDoc &&
                        commonDocuments.includes(type)) || hasRejected && commonDocuments.includes(type) ?
                        styles.SectionShortTitle as React.CSSProperties: styles.SectionTitle as React.CSSProperties}
                    >
                        {title}
                    </Text>
                </Tooltip>
                {/*{*/}
                {/*    documents[0]?.status === 'OK' && !commonDocuments.includes(type) && <Text style={styles.AcceptedText}>Accepted</Text>*/}
                {/*}*/}
                {/*{*/}
                {/*    documents[0]?.status === 'KO' && !commonDocuments.includes(type) && <Text style={styles.RejectedText as React.CSSProperties}>Rejected</Text>*/}
                {/*}*/}
                {/*{*/}
                {/*    (documents[0]?.status === 'ARCHIVE' || allArchived) && !commonDocuments.includes(type) && <Text style={styles.AcceptedText}>Archived</Text>*/}
                {/*}*/}
                {
                    documents[0]?.status === 'OK' && !hasRejected && !commonDocuments.includes(type) && <Text style={styles.AcceptedText}>Accepted</Text>
                }
                {
                    hasRejected  &&  !hasToApprovedDoc && !commonDocuments.includes(type) && <Text style={styles.RejectedText as React.CSSProperties}>Rejected</Text>
                }
                {
                    !!documents.length && allArchived && !commonDocuments.includes(type) && <Text style={styles.AcceptedText}>Archived</Text>
                }
                {
                    hasToApprovedDoc ?
                        !commonDocuments.includes(type) ?
                            <div>
                                <Button
                                    loading={acceptLoading}
                                    onClick={handleAcceptClick(documents[0])}
                                    style={styles.AcceptButton as React.CSSProperties}
                                    type="text"
                                    disabled={isUserRoleMarketing(userAuthData)}
                                >
                                    Accept
                                </Button>
                                <Button
                                    loading={rejectLoading}
                                    onClick={handleRejectClick(documents[0])}
                                    style={styles.RejectButton}
                                    type="text"
                                    disabled={isUserRoleMarketing(userAuthData)}
                                >
                                    Reject
                                </Button>
                            </div> :
                            <Text style={styles.AcceptButton as React.CSSProperties}>Needs action</Text>
                        : null
                }
                {
                    !hasToApprovedDoc  && !hasRejected  && hasAccepted && commonDocuments.includes(type)  &&
                    <Text style={styles.AcceptedText}>Accepted</Text>
                }
                {
                    !hasToApprovedDoc && hasRejected && commonDocuments.includes(type)  &&
                    <Text style={styles.RejectedText as React.CSSProperties} >Rejected</Text>
                }
                {
                    !hasToApprovedDoc && !hasRejected && !hasAccepted && allArchived && commonDocuments.includes(type) && documents[0]?.status === 'ARCHIVE' &&
                    <Text style={styles.AcceptedText}>Archived</Text>
                }
            </Space>
            {isWithExpDate(type) ? <ExpDatePart providerId={providerId} document={documents[0]} /> : null}

            <Space style={ type !== 'PROFILE' ? styles.commentWrapper as CSSProperties: styles.commentWrapperForProfile as CSSProperties}>
                {type === 'CRIMINAL_REPORT' && documents.length &&  !filteredCriminalReport.length ?
                    <Space style={styles.commentItem}>
                        <Text style={styles.criminalReport as CSSProperties}>The police report document has been accepted</Text>
                    </Space>: null
                }
                {
                    type !== 'PROFILE' ?
                        <Space style={styles.commentItem}>
                            <Text style={styles.commentTitle as CSSProperties}>Provider comment</Text>
                            {
                                providerComment?
                                    <>
                                        {
                                            providerComment?.length > 45 ?
                                                <>
                                                    <Text style={styles.cardComment as CSSProperties}>
                                                        {providerComment.slice(0, 45)}...
                                                        <Tooltip placement="topLeft" title={providerComment}>
                                                            <Text style={styles.seeMore}>See More</Text>
                                                        </Tooltip>
                                                    </Text>
                                                </> :
                                                <>
                                                    <Text style={styles.cardComment as CSSProperties}>{providerComment}</Text>
                                                </>
                                        }
                                    </>:
                                    <Text>-</Text>
                            }
                        </Space>:
                        null
                }
                <Space style={styles.commentItem}>
                    <Text style={styles.commentTitle as CSSProperties}>Comment</Text>
                    {
                        adminComment ?
                            <>
                                {
                                    adminComment?.length > 45 ?
                                        <>
                                            <Text style={styles.cardComment as CSSProperties}>
                                                {adminComment.slice(0, 45)}...
                                                <Tooltip placement="topLeft" title={adminComment}>
                                                    <Text style={styles.seeMore}> See More</Text>
                                                </Tooltip>
                                            </Text>
                                        </> :
                                        <>
                                            <Text style={styles.cardComment as CSSProperties}>{adminComment}</Text>
                                        </>
                                }
                            </>:
                            <Text>-</Text>
                    }
                </Space>
            </Space>
            <Space style={styles.SectionFooter as React.CSSProperties}>
                <Upload
                    showUploadList={false}
                    customRequest={customRequest}
                    accept="image/*,.pdf"
                >
                    <Button
                        style={styles.UploadButton}
                        shape="round"
                        icon={<UploadOutlined />}
                        loading={uploadLoading}
                        disabled={uploadButtonDisabled() || isUserRoleMarketing(userAuthData)}
                    >
                        Upload
                    </Button>
                </Upload>
                {allDocuments.length > 0 ?
                    <Button
                        type="text"
                        icon={<FileImageOutlined />}
                        style={styles.ViewButton}
                        onClick={handleViewClick}
                    >
                        View
                    </Button> : null}
            </Space>
            <Modal
                footer={null}
                onCancel={handleModalOnClose}
                visible={isModalVisible}
                title={'Documents'}
            >
                {allDocuments.length > 1 &&
                <div onClick={() => CarouselRef && CarouselRef.prev()} style={styles.LeftArrow as React.CSSProperties}>
                    <Image preview={false}  src={arrowLeftIcon} width={42} height={42} />
                </div>}
                <Carousel draggable ref={ref => CarouselRef = ref} dots={false}>
                    {allDocuments.map((document: any) => {
                        return (
                            <div key={document.id}>
                                <div style={styles.ModalButtonsContainer as React.CSSProperties}>
                                    <Text style={styles.status}>Document Status</Text>
                                    {document.status === 'TO_APPROVE' &&
                                        <div>
                                            <Button
                                                loading={rejectLoading}
                                                onClick={handleRejectClick(document)}
                                                style={styles.RejectButtonOnModal as React.CSSProperties}
                                                disabled={isUserRoleMarketing(userAuthData)}
                                            >
                                                Reject
                                            </Button>
                                            <Button
                                                loading={acceptLoading}
                                                onClick={handleAcceptClick(document)}
                                                type="primary"
                                                disabled={isUserRoleMarketing(userAuthData)}
                                            >
                                                Accept
                                            </Button>
                                        </div>
                                    }
                                    {document.status === 'KO' &&
                                        <div>
                                            <Button
                                                loading={archiveLoading}
                                                onClick={handleArchiveClick(document)}
                                                type="primary"
                                                disabled={getUserRoles(userAuthData) === UserRole.ROLE_MARKETING}
                                            >
                                                Archive
                                            </Button>
                                        </div>
                                    }
                                    {document.status === 'OK' &&
                                        <Text style={styles.AcceptedTextOnModal as React.CSSProperties}>Accepted</Text>}
                                    {document.status === 'ARCHIVE' &&
                                        <Text style={styles.ArchivedTextOnModal as React.CSSProperties}>Archived</Text>}
                                    { document.documentContentType === 'application/pdf' && <Button
                                        type="link"
                                        target="_blank"
                                        href={document.amazonUrl}
                                    >
                                        Open In New Tab
                                    </Button>}
                                </div>
                                {
                                    document.documentContentType === 'application/pdf' ?
                                    <Document
                                        file={document.amazonUrl}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        className={'viewer'}
                                    >
                                        {Array.from(
                                            new Array(numPages),
                                            (el, index: number) => (
                                                <Page  pageNumber={index + 1} />
                                            ))}
                                    </Document>:
                                    <Image
                                        preview={true}
                                        src={document.amazonUrl}
                                        style={styles.docImage as CSSProperties}
                                    />
                                }
                            </div>
                        )
                    })}
                </Carousel>
                {allDocuments.length > 1 &&
                <div onClick={() => CarouselRef && CarouselRef.next()} style={styles.RightArrow as React.CSSProperties}>
                    <Image preview={false}  src={arrowRightIcon} />
                </div>}
                {
                    type !== 'PROFILE' ?
                        <Space align='start' style={styles.providerCommentWrapper as CSSProperties}>
                            <Text style={styles.providerCommentTitle}>Provider Comment</Text>
                            <Text style={styles.providerCommentText}>{providerComment ? providerComment : '-'}</Text>
                        </Space>:
                        null
                }
                <TextArea
                    rows={4}
                    placeholder='Comment'
                    onChange={handleChangeComment}
                    value={comment}
                    style={styles.adminComment as React.CSSProperties}
                    disabled={isUserRoleMarketing(userAuthData)}
                />
                <Button
                    onClick={()=>handleSaveComment()}
                    style={styles.saveButton as React.CSSProperties}
                    type="primary"
                    disabled={isUserRoleMarketing(userAuthData)}
                >
                    Save
                </Button>
            </Modal>
        </div>
    )
}

const ExpDatePart: React.FC<{ document: any, providerId: string }> = ({ document, providerId }) => {
    const [expDate, setExpDate] = useState(document?.expirationDate);
    const { userAuthData } = useContext(MainContext);
    let expDateRef: any;
    const dateFormat = 'YYYY/MM/DD';
    const expDateFormat = 'YYYY-MM-DD';

    const handleSelectDate = async (momentObj: any) => {
        const oldExpDate = expDate;
        expDateRef.blur();
        setExpDate(momentObj._d);
        const expDateUploadPayload = { expirationDate: moment(momentObj._d).format(expDateFormat) };
        const updateRes = await updateDocumentExpirationDate(providerId, document.id, expDateUploadPayload);
        if (updateRes.successCode !== 'OK') {
            setExpDate(oldExpDate)
        } else {
            setExpDate(updateRes.content.expirationDate);
        }
    }

    return (
        <Space style={styles.ExpDatePartContainer}>
            <Text>Exp. Date:</Text>
            <DatePicker
                disabled={!document?.amazonUrl || isUserRoleMarketing(userAuthData)}
                ref={(ref) => expDateRef = ref}
                disabledDate={(current) => current && current < moment().endOf('day')}
                format={dateFormat}
                bordered={false}
                allowClear={false}
                value={expDate && moment(expDate, expDateFormat)}
                onChange={handleSelectDate}
            />
        </Space>
    )
}

const styles = {
    DocumentSectionContainer: {
        border: '1px solid #d9d9d9',
        padding: '0 10px',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,
        height: 240,
        alignItems: 'flex-start',
        width: '100%',
    },
    UpdatedDocumentSectionContainer: {
        border: '2px solid #1a90ff',
        padding: '0 10px',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,
        height: 240,
        alignItems: 'flex-start',
        width: '100%'
    },
    SectionFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        width: '100%',
        borderTop: '1px solid #d9d9d9',
        padding: '10px 10px 10px 0'
    },
    SectionTitle: {
        color: '#5b5b5b',
        fontSize: 14,
        cursor: 'pointer'
    },
    SectionShortTitle: {
        color: '#5b5b5b',
        fontSize: 14,
        maxWidth: 115,
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'block',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
    },
    UploadButton: {
        color: '#1a90ff',
        width: 95,
        fontWeight: 600,
        backgroundColor: '#f0f2f5',
        border: 'none'
    },
    ExpDatePartContainer: {
        flexGrow: 1,
    },
    commentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        alignItems: 'start',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-around'
    },
    commentWrapperForProfile: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        alignItems: 'start',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'end',
        padding: '5px 0',
    },
    commentItem: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'start',
        height: 40,
    },
    cardComment: {
        maxWidth: 225,
        width: '100%',
        height: '100%',
        textAlign: 'start',
        color: '#5B5B5B',
        fontSize: 12,
        fontWeight: 400,
    },
    commentTitle: {
        display: 'inline-block',
        width: 57,
        height: '100%',
        color: '#5B5B5B',
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'start',
    },
    criminalReport: {
        height: '100%',
        color: '#5B5B5B',
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'start',
    },
    seeMore: {
       cursor: 'pointer',
       color: '#448EF7',
       fontSize: 12,
       fontWeight: 400,
    },
    ProviderDocContainer: {
        padding: 5,
    },
    ViewButton: {
        color: '#1a90ff'
    },
    SectionTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: 42
    },
    AcceptedText: {
        color: '#5b5b5b',
        fontSize: 16,
        fontWeight: 500,
    },
    RejectedText: {
        color: '#ff4d4f',
        fontSize: 16,
        fontWeight: 500,
        whiteSpace: 'nowrap',
    },
    AcceptButton: {
        color: '#1a90ff',
        whiteSpace: 'nowrap',
    },
    RejectButton: {
        color: '#ff4d4f',
    },
    CompleteButton: {
        position: 'absolute',
        display: 'flex',
        top: -105,
        left: 280,
    },
    LeftArrow: {
        position: 'absolute',
        left: '-10%',
        top: '50%',
        cursor: 'pointer',
        zIndex: 1,
    },
    RightArrow: {
        position: 'absolute',
        right: '-10%',
        top: '50%',
        cursor: 'pointer',
        zIndex: 1,
    },
    AcceptedTextOnModal: {
        color: '#5b5b5b',
        fontSize: 16,
        fontWeight: 500,
        alignSelf: 'flex-end',
        paddingBottom: 5,
    },
    RejectedTextOnModal: {
        color: '#ff4d4f',
        fontSize: 16,
        fontWeight: 500,
        alignSelf: 'flex-end',
        paddingBottom: 5,
    },
    ArchivedTextOnModal: {
        color: '#ff4d4f',
        fontSize: 16,
        fontWeight: 500,
        alignSelf: 'flex-end',
        paddingBottom: 5,
    },
    RejectButtonOnModal: {
        color: '#ff4d4f',
        border: '1px solid #ff4d4f',
        marginRight: 17,
    },
    status: {
        color: '#001629',
        fontSize: 14,
        fontWeight: 400,
        marginRight: 'auto',
    },
    ModalButtonsContainer: {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: 16,
        width: 470,
        alignItems: 'center',
    },
    docImage: {
        width: 450,
        height: 400,
    },
    providerCommentWrapper: {
        paddingTop: 16,
    },
    providerCommentTitle: {
        color: '#5B5B5B',
        fontSize: 12,
        fontWeight: 700,
    },
    providerCommentText: {
        color: '#5B5B5B',
        fontSize: 12,
        fontWeight: 400,
    },
    adminComment: {
        position: 'relative',
        marginTop: 16,
        padding: '5px 80px 5px 5px',
    },
    saveButton: {
        marginLeft: -70,
        position: 'absolute',
        bottom: 30,
    },

}



