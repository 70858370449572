export const configs = [
    {
        label: 'Pending approval',
        params: {
            statuses: 'IN_REVIEW',
            exportable: true,
            removeIndex: [1, 2, 3],
        },
        sort: 'updatedDate,desc',
    },
    {
        label: 'Approved',
        params: {
            statuses: 'ACTIVE',
            exportable: true,
            removeIndex: [1, 2, 3],
        },
        sort: 'updatedDate,desc',
    },
    {
        label: 'Rejected',
        params: {
            statuses: 'REJECTED',
            exportable: true,
            removeIndex: [1, 2, 3],
        },
        sort: 'updatedDate,desc',
    },
    {
        label: 'Not Registered',
        params: {
            url: '/admin/users/draft-user',
            type: 'PROVIDER',
            exportable: false,
            removeIndex: [8, 9]
        }
    },
    {
        label: 'Draft',
        params: {
            statuses: 'DRAFT',
            exportable: true,
            removeIndex: [1, 2, 3],
        },
        sort: 'updatedDate,desc',
    },
    {
        label: 'Out Of Service',
        params: {
            outOfService: true,
            statuses: 'ACTIVE,REJECTED',
            exportable: true,
            removeIndex: [1, 2, 3],
        },
        sort: 'updatedDate,desc',
    },
    {
        label: 'Needs Action',
        params: {
            needsAction: true,
            exportable: true,
            removeIndex: [1, 2, 3],
        },
        sort: 'updatedDate,desc',
    },
    {
        label: 'All',
        params: {
            exportable: true,
            statuses: '',
            removeIndex: [1, 2, 3],
        },
        sort: 'updatedDate,desc',
    },
];