import React, { useState, useMemo } from 'react';
import { Layout, notification, Spin, Button, Modal, Input, Select, Checkbox, Space } from 'antd';
import { IconType } from 'antd/lib/notification';
import { EditTwoTone, CheckCircleFilled , CloseCircleOutlined } from '@ant-design/icons';
import './releases.css';
import { useSettingsAction } from './useSettingsActions';
import { InnerLayout } from '../../../components/InnerLayout';
import { Pager } from '../../../components/pager/Pager';
import { formatDate } from '../../../utils/helpers';
import { DatePicker } from 'antd';
import moment from "moment";

export interface ModalContent {
  userType: string;
  version: string;
  releaseDate: null | Date;
  force?: boolean,
  platformTypes?: string[];
  id?: string;
}
export interface EditPayload {
  isForce?: boolean;
  platformTypes?: string[];
}
const defaultModalContent: ModalContent = {
  userType: 'Client',
  version: '',
  releaseDate: null,
}
const defaultRecord: ModalContent = {
  userType: 'Client',
  version: '',
  releaseDate: null,
  force: false,
  platformTypes: [],
  id: '',
}
let configs = [
  {
    label: 'Client',
    params: {
      userType: 'CLIENT'
    },
  },
  {
    label: 'Provider',
    params: {
      userType: 'PROVIDER'
    },
  },
]
const appType = ['Client', 'Provider'];
const updateType = [{ name: 'Suggest', value: false }, { name: 'Force', value: true }];
const url = `/app-versions`;

export const Releases = () => {
  const [forceReload, setForceReload] = useState(0);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [labelIndex, setLabel] = useState<number>(0);
  const [index, setIndex] = useState<number | null>(null);
  const [modalContent, setModalContent] = useState<ModalContent>(defaultModalContent);
  const [editedReleaseRecord, setEditedReleaseRecord] = useState<ModalContent>(defaultRecord);

  const [record, setRecord] = useState<ModalContent>(defaultRecord);
  const getEditingParams = () => {
    const newEditingParams = configs.map((config: any, i: number) =>{
      if( labelIndex === i ){
        return {...config, editedParams: { index, rowRecord: editedReleaseRecord }}
      }
      return  {...config, editedParams: { index: null, rowRecord: {} }}
    })
    return newEditingParams;
  }
  const editingParams = useMemo(() => getEditingParams(), [labelIndex, editedReleaseRecord])
  const handleIOSCheckBoxChange =  (e: any) => {
    const checked = e.target.checked;
    const platformTypes = checked? [...editedReleaseRecord?.platformTypes as string[],'IOS'] : editedReleaseRecord?.platformTypes?.filter((type: string) => type !== 'IOS')
    const newRecord = { ...editedReleaseRecord, platformTypes };
    setEditedReleaseRecord(newRecord)
  }
  const handleAndroidCheckBoxChange = (e: any) => {
    const checked = e.target.checked;
    const platformTypes = checked? [...editedReleaseRecord?.platformTypes as string[],'ANDROID'] : editedReleaseRecord?.platformTypes?.filter((type: string) => type !== 'ANDROID')
    const newRecord = { ...editedReleaseRecord, platformTypes };
    setEditedReleaseRecord(newRecord)
  }
  const handleChangeUpdateType = (updateType: any) => {
    const newRecord = { ...editedReleaseRecord, force: updateType };
    setEditedReleaseRecord(newRecord)
  }
  const handleEditClick = (record: ModalContent, index: number) => () => {
    setRecord(record)
    setEditedReleaseRecord(record)
    setIndex(index)
  }

  const checkPlatformTypesChangesExist = (editedReleaseRecord: any, record: any) => {
    return (record?.platformTypes?.length !== editedReleaseRecord?.platformTypes?.length ||
        record?.platformTypes?.some((type: string) => !editedReleaseRecord?.platformTypes?.includes(type))
    )
  }

  const handleSaveEditClick = () => {
    if(editedReleaseRecord.force === record.force &&
        !checkPlatformTypesChangesExist(editedReleaseRecord, record)){
      setIndex(null)
      return
    }
    const payload:EditPayload = {}
    if(checkPlatformTypesChangesExist(editedReleaseRecord, record)){
      payload.platformTypes = editedReleaseRecord.platformTypes
    }
    if(record.force !== editedReleaseRecord.force){
      payload.isForce = editedReleaseRecord.force
    }
    editAppRelease(payload as EditPayload, editedReleaseRecord.id as string)
  }
  const handleCancelClick = () => {
    setEditedReleaseRecord(record)
    setIndex(null)
  }
  const updatePage = () => {
      setForceReload(Math.random());
  }
  const closeModal = () => {
      setModalVisible(false);
      setModalContent(defaultModalContent);
  }
  const openNotification = (type: IconType, message: string) => {
      notification[type]({
        message,
        duration: 2
      })
  }
  const onErrorAction = (message?: string) => {
    openNotification('error', message || 'Something went wrong!');
  }
  const onSuccessAction = (arg: string) => {
    openNotification('success', `${arg} successfully added.`);
  }
  const { addAppRelease, addLoading, editAppRelease, editLoading } = useSettingsAction(updatePage, onErrorAction, onSuccessAction, closeModal, setIndex);
  const handleCancel = () => {
    setModalVisible(false);
    setModalContent(defaultModalContent);
  }
  const handleOk = () => {
      addAppRelease({...modalContent, userType: modalContent.userType.toUpperCase()});
  }
  const handleChangeAppType = (userType: string) => {
    setModalContent({...modalContent, userType})
  }
  const handleChangeVersionNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalContent({...modalContent, version: e.target.value})
  }
  const handleChangeReleaseDate  = (date: any) => {
    setModalContent({...modalContent, releaseDate: date?.format('YYYY-MM-DD[T]HH:mm:ss')})
  }
  const renderModalContent = () => {
    if (addLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin size='default' />
        </div>
      )
    }
  const handleDisabledDate = (date : any) => {
    return date && date < moment().startOf('day');
  }

    return (
        <>
          <Select style={{ width: '100%', marginBottom: 16 }} value={modalContent.userType} onChange={handleChangeAppType}>
            {appType.map((type: string) => {
              return (
                  <Select.Option key={type} value={type}>{type}</Select.Option>
              )
            })}
          </Select>
          <Space>
            <Input placeholder='Version number' value={modalContent.version} onChange={handleChangeVersionNumber} className='newReleaseModalItem'/>
            <DatePicker
                value={modalContent.releaseDate?moment(modalContent.releaseDate):null}
                onChange={handleChangeReleaseDate}
                disabledDate={handleDisabledDate}
                className='newReleaseModalItem'
                placeholder={'Release date'}
            />
          </Space>
          <Space className='buttonsContainer'>
            <Button onClick={handleCancel} className='canselButton'>Cancel</Button>
            <Button  onClick={handleOk} className='addButton' type='primary'>Add</Button>
          </Space>
        </>
    )
  }

  const columns = [
    {
      title: 'Release version number ',
      dataIndex: 'version',
    },
    {
      title: 'Update type',
      dataIndex: 'force',
      render: (force: string, record: ModalContent, i: number) => {
        return(
              index !== i ?
                <>{force ? 'Force' : 'Suggest'}</> :
                <Select className='updateTypeSelect' value={editedReleaseRecord.force as any} onChange={handleChangeUpdateType}>
                  {updateType.map(({name, value}) => {
                    return (
                        <Select.Option key={name} value={value as any}>{name}</Select.Option>
                    )
                  })}
                </Select>
        )
      }
    },
    {
      title: 'IOS',
      dataIndex: 'platformTypes',
      render: (platformTypes: string[], record: ModalContent, i: number) => {
        return(
            <Checkbox onChange={handleIOSCheckBoxChange} checked={index !== i ?platformTypes.includes('IOS') : editedReleaseRecord?.platformTypes?.includes('IOS')} disabled={index !== i} />
        )
      }
    },
    {
      title: 'Android',
      dataIndex: 'platformTypes',
      render: (platformTypes: string[], record: ModalContent, i: number) => {
        return(
            <Checkbox onChange={handleAndroidCheckBoxChange} checked={index !== i ?platformTypes.includes('ANDROID') : editedReleaseRecord?.platformTypes?.includes('ANDROID')} disabled={index !== i} />
        )
      }
    },
    {
      title: 'Release date',
      dataIndex: 'releaseDate',
      render(releaseDate: string) {
        return formatDate(releaseDate, 'DD-MM-YYYY HH:mm');
      }
    },
    {
        title: '',
        dataIndex: 'id',
        render: (id: string, record: ModalContent, i: number) => {
          return (
              <div style={{ width: 'fit-content'}}>
                {index !== i ?
                    <Button
                        disabled={index !== null}
                        type='link'
                        icon={<EditTwoTone />}
                        onClick={handleEditClick(record, i)}
                    /> :
                    <>
                      <Button
                          disabled={editLoading}
                          type='link'
                          icon={<CloseCircleOutlined style={{ color: '#5B5B5B' }} />}
                          onClick={handleCancelClick}
                      />
                      <Button
                          disabled={editLoading}
                          type='link'
                          icon={<CheckCircleFilled style={{ fontSize: '16px', color: '#23C983' }} />}
                          onClick={handleSaveEditClick}
                      />
                    </>
                }
              </div>
          )}
      },
  ];


  const onAdd = () => {
    setModalVisible(true);
    setModalTitle('Add New Release');
  }

  const headerComponentsInRight = [
    <Button key='Add new Release' onClick={onAdd} type='primary' className='add-button'>Add new Release</Button>,
  ]

  const handleLabelChange = (labelIndex: number) => {
    setIndex(null)
    setLabel(labelIndex);
  }

  return (
    <InnerLayout>
        <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
          <Pager
              headerComponentsInRight={headerComponentsInRight}
              url={url}
              columns={columns}
              configs={configs}
              rowClick={() => {}}
              forceReload={forceReload}
              onLabelChange={handleLabelChange}
              notSearchable={true}
              editingParams={editingParams}
          />
        </Layout.Content>
        <Modal 
            style={{ display: 'flex' }}
            title={modalTitle}
            visible={isModalVisible}
            footer={null}
            onCancel={handleCancel}
        >
          {renderModalContent()}
        </Modal>
    </InnerLayout>
  )
}
