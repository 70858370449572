import { useState, useEffect, useCallback } from 'react';
import { ICityPayload } from '../../pages/settings/ServiceAreas/ServiceCities';
import { authorisedRequest } from '../../api/authorisedRequest';

interface IPagerHook {
  loading: boolean;
  data: any;
  currentPage: number;
  workingLocations: ICityPayload[];
  filterConfigs: any;
  setCurrentPage: (page: number) => void;
  setCurrentQuery: (status: string) => void;
  setSortConfigs: (sortConfig: any) => void;
  setFilterConfigs: (filterConfigs: any) => void;
  currentQuery: string;
  setSearchingCity: (city: string) => void;
  searchingCity: string;
}

export function useFetchList(url: string, currentConfig: any, forceReload: number, currentPageFromProps?: number): IPagerHook {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(currentPageFromProps || 0);
  const [currentQuery, setCurrentQuery] = useState('');
  const [sortConfigs, setSortConfigs] = useState<any>({});
  const [filterConfigs, setFilterConfigs] = useState<any>({});
  const [workingLocations, setWorkingLocations] = useState<ICityPayload[]>([]);
  const [prevConfig, setPrevConfig] = useState(currentConfig.params);
  let   [searchingCity, setSearchingCity] = useState('');

  const getCitiesList = useCallback(() => {
    if ((!url.includes('providers') && !url.includes('orders')) || url.includes('dashboard')) {
      return;
    }
    searchingCity = !searchingCity? '':  searchingCity
    authorisedRequest(`/service-cities?desc&page=0&size=10&searchKey=${searchingCity}`)
    .then((data: any = {}) => {
      const newWorkingLocations = data.content.map((item: ICityPayload) => ({...item, label:item.city, value: item.city}))
      setWorkingLocations(newWorkingLocations);
      const  workingLocation = newWorkingLocations.find((workingLocation: any) => workingLocation.city === searchingCity)
      if (!searchingCity) {
        let newFilterConfigs = filterConfigs;
        delete newFilterConfigs.serviceCityId;
        setFilterConfigs({
          ...newFilterConfigs
        })
      } else if ((newWorkingLocations.length === 1  && newWorkingLocations[0]?.id) || workingLocation) {
        const serviceCityId = newWorkingLocations[0]?.id || workingLocation.id;
        setFilterConfigs({
          ...filterConfigs,
          serviceCityId,
        })
      }
    });
  }, [url,searchingCity])

  useEffect(() => {
    getCitiesList();
    // eslint-disable-next-line
  }, [searchingCity])

  useEffect(() => {
    setLoading(true);
    let queryString = '';
    let sortingQuery = '';
    let filterQuery = '';

    const urlFromConfig = currentConfig && currentConfig.params.url;

    if (currentConfig) {
      setPrevConfig(currentConfig.params)
      for (let [key, value] of Object.entries(currentConfig.params)) {
        queryString += `&${key}=${value}`;
      }
    }

    if (sortConfigs) {
      for (let [key, value] of Object.entries(sortConfigs)) {
        sortingQuery += `&sort=${key},${value === 'ascend' ? 'asc' : 'desc'}`;
      }
    }

    if (filterConfigs) {
      for (let [key, value] of Object.entries(filterConfigs)) {
        filterQuery += `&${key}=${value}`;
      }
    }
  const page = JSON.stringify(currentConfig.params) !== JSON.stringify(prevConfig) ? 0 : currentPage;
    authorisedRequest(`${urlFromConfig || url}?searchKey=${encodeURIComponent(currentQuery)}${queryString}${sortingQuery}${filterQuery}&${currentConfig?.sort ? `sort=${currentConfig?.sort}&` : 'sort=createdDate,desc&'}page=${page}`)
      .then((data: any = {}) => {
          setData(data);
          if (JSON.stringify(currentConfig.params) !== JSON.stringify(prevConfig)){
            setCurrentPage(0)
          }
        setLoading(false);
      });
  }, [url, currentConfig, currentPage, currentQuery, forceReload, sortConfigs, filterConfigs]);

  return { loading, data, setCurrentPage, setCurrentQuery, currentPage, setSortConfigs, workingLocations, setFilterConfigs, filterConfigs, currentQuery, setSearchingCity, searchingCity };
}
