import moment from 'moment';
import jwt_decode from "jwt-decode";
import { UserAuthData } from '../context/mainContext';

export const getHours = (hoursCount: number) => {
  const hours = moment.utc(hoursCount * 3600 * 1000);
  let duration = "";
  if (hours) {
    const hour = hours.format("H");
    const minute = hours.format("m");
    duration = hour + (Number(hour) > 1 ? " hrs" : "hr");
    if (minute && Number(minute) > 0) {
      duration += ` ${minute} min`;
    }
  }
  return duration;
};

export const formatDate = (date?: string, formatType = 'DD-MM-YYYY HH:mm a') => {
  return moment.utc(date).format(formatType);
}

export const textToSentence = (text: string): string => {
  const result = text.replace( /([A-Z])/g, " $1" );
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const getUserRoles = (userAuthData: UserAuthData | null) => {
  if (userAuthData) {
    let token = userAuthData.accessToken;
    let decoded : any = jwt_decode(token);
    return decoded.authorities
  }
  return [];
}

export enum UserRole {
  ROLE_PROVIDER = "ROLE_PROVIDER",
  ROLE_CLIENT = "ROLE_CLIENT",
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
  ROLE_MARKETING = "ROLE_MARKETING",
}

export const isUserSuperAdmin = (userAuthData: UserAuthData | null): boolean => {
  let userRoles = getUserRoles(userAuthData);
  return userRoles.find((role: UserRole) => role === UserRole.ROLE_SUPER_ADMIN)
}
export const isUserRoleMarketing = (userAuthData: UserAuthData | null): boolean => {
  let userRoles = getUserRoles(userAuthData);
  return userRoles.includes(UserRole.ROLE_MARKETING) && !userRoles.includes(UserRole.ROLE_SUPER_ADMIN)
}

export const getSortingConfigs = (sorter: any) => {
  if (!sorter.order && !sorter.length) {
    return {};
  } else {
    let sortConfigs: any = {};
    if (sorter.length) {
      sorter.forEach((sorterEl: any) => {
        sortConfigs[sorterEl.columnKey] = sorterEl.order
      });
    } else {
      sortConfigs = {
        [sorter.columnKey]: sorter.order
      }
    }
    return sortConfigs;
  }
}

export const containOnlyLettersAndNumbers = (str: string) => {
  return /^[A-Za-z0-9]*$/.test(str);
}

export const isProduction = () => {
  return window.location.hostname === 'admin.flexxi.care'
}

export const formatText = (text: string) => {
  return (text[0] + text.slice(1).toLowerCase()).replace('_', ' ')
}