import React, { useEffect, useState } from 'react';
import { Select } from "antd";
import { CampaingType, PromoCodeRequest, PromoCodeType, UpdatedContent } from './campaingTypes';
import { SimpleCampaingContent } from "./SimpleCampaingContent";
import { InviteFriendCampaing } from "./InviteFriendCampaing";
import { UniquePromoContent } from "./UniquePromoContent";
import { getProviderTypes } from '../../api/servicePrices';
import { ModalTitle } from './Campaings';

const { Option } = Select;


interface NewCampaingContentProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    setCampaingType: (campaingType: CampaingType) => void;
    campaingType: CampaingType;
    modalTitle: ModalTitle;
}

export enum ProviderTypes {
    careSpecialist = 'NURSE',
    houseHoldHelper = 'HELPER',
    careHelper = 'CAREGIVER',
}

export const NewCampaingContent: React.FC<NewCampaingContentProps> = ({ modalContent, setModalContent, setCampaingType, campaingType, modalTitle }) => {
    const disabled = modalTitle === ModalTitle.viewCampaign;
    const [providerTypes, setProviderTypes] =useState([])
    useEffect(() => {
        getProviderTypes()
            .then((data: any) => setProviderTypes(data.map((i: any) => {
                if (i.type === ProviderTypes.careSpecialist) {
                    return { ...i, name: 'Care professional' }
                }
                return i;
            })));
    },[])

    const handleModalTypeChange = (campaingType: CampaingType) => {
        setCampaingType(campaingType);
        if (campaingType === CampaingType.SimpleCampaing) {
            setModalContent({
                type: PromoCodeType.ORDER_FREE_TIME,
                individual: false,
                providerTypeIds:[],
            })
        } else if (campaingType === CampaingType.UniquePromo){
            setModalContent({
                type: PromoCodeType.ORDER_FREE_TIME,
                individual: true,
            })
        } else {
            setModalContent({
                type: PromoCodeType.INVITE_CLIENT_TO_CLIENT_N2,
                [UpdatedContent.inviterDetails]: {},
                [UpdatedContent.receiverDetails]: {},
                providerTypeIds:[],
            })
        }
    }

    const getCampaingByType = (campaingType: CampaingType) => {
        switch(campaingType) {
            case CampaingType.SimpleCampaing: 
                return <SimpleCampaingContent modalContent={modalContent} setModalContent={setModalContent} providerTypes={providerTypes} disabled={disabled} />
            case CampaingType.InviteFriend:
                return <InviteFriendCampaing modalContent={modalContent} setModalContent={setModalContent} providerTypes={providerTypes} disabled={disabled} />
            case CampaingType.UniquePromo:
                return <UniquePromoContent modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            default: return null;
        }
    }

    return (
        <div>
            <Select onChange={handleModalTypeChange} value={campaingType || ''} style={{ width: '100%'}} disabled={disabled}>
                <Option value={CampaingType.SimpleCampaing}>Campaign</Option>
                <Option value={CampaingType.InviteFriend}>Invite Friend</Option>
                <Option value={CampaingType.UniquePromo}>Unique promo</Option>
            </Select>
            {getCampaingByType(campaingType)}
        </div>
    )
}