import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Layout,
  Avatar,
  Space,
  Typography,
  Rate,
  Radio,
  Tooltip,
  Button,
  notification,
} from 'antd';
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowUpOutlined, LockOutlined, UnlockOutlined, WarningFilled } from '@ant-design/icons'
import { useParams, Link } from 'react-router-dom';
import './client.css';
import { InnerLayout } from '../../components/InnerLayout';
import { Pager } from '../../components/pager/Pager';
import { CustomerDetails } from '../../components/customerDetails/CustomerDetails';
import { InviteFriends } from '../../components/inviteFriends/InviteFriends';
import { RespiteCare } from '../../components/respiteCare/RespiteCare';
import { isUserRoleMarketing } from '../../utils/helpers';
import { formatDate, getHours } from '../../utils/helpers';
import { TransactionHistory } from '../../components/orderDetails/TransactionHistory';
import { authorisedRequest } from '../../api/authorisedRequest';
import { useCustomerDetails } from '../../components/customerDetails/useCustomerDetails';
import { Deposit, updateCustomerData } from '../../api/customers';
import { ordersUrl, defaultImage } from '../providers/Provider';
import { MainContext } from '../../App';

const { Text } = Typography;
export const campaignsUrl = '/admin/customers';
export const inviteFriendsUrl = '/admin/customers';
export const campaignColumns = [
  {
    title: 'Campaign name',
    dataIndex: 'name',
  },
  {
    title: 'Campaign type',
    dataIndex: 'type',
  },
  {
    title: 'Amount / Discount / Free time',
    render(record: any) {
      const { discountAmount, discountPercent, freeMinutes } = record;
      return discountPercent ? `${discountPercent} %` : discountAmount ? `${discountAmount} €` : freeMinutes ? `${freeMinutes} mins` : ''
    },
  },
  {
    title: 'Start date / End date',
    render(record: any) {
      return `${formatDate(record.startDate, 'DD.MM.YYYY')}-${formatDate(record.endDate, 'DD.MM.YYYY')}`
    },
  },
  {
    title: 'Max usage',
    dataIndex: 'maxUsagePerUser',
  },
  {
    title: 'Usage quantity',
    dataIndex: 'usageCount',
  },
];

export const Client: React.FC = () => {
  const { userAuthData } = useContext(MainContext);
  const params: {id?: string, currentPage?:string} = useParams();
  const [visibleModal, setVisible] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const [activeTab, setActiveTab] = useState('info');
  const [forceReload, setForceReload] = useState(0);
  const [isBlocked, setIsBlocked] = useState<boolean | undefined>();
  const [updateLoading, setUpdateLoading] = useState('');
  const [isTransactionsVisible, setTransactionsVisible] = useState(false);
  let loading = false;
  let data: { customer: any, paymentMethods: any } = { customer: {}, paymentMethods: {} }
  const details = useCustomerDetails(params.id || '', forceReload);
  loading = details.loading;
  data = details.data;
  
  useEffect(() => {
    setIsBlocked(data.customer.blocked);
  }, [data.customer.blocked]);

  useEffect(() => {
    setTransactionsVisible(data.customer.depositDetails?.depositPaymentsEnabled);
  }, [data.customer.depositDetails])

  const transactionsConfigs = useMemo(() => [
    {
      label: 'Payments',
      params: {
        url: `/admin/customers/${data.customer.id}/deposits`,
        removeIndex: [1,2]
      }

    },
    {
      label: 'Charges',
      params: {
        url: `/admin/customers/${data.customer.id}/deposit/charges`
      }
    },
  ], [data.customer.id])

  const ordersConfigs = useMemo(() => [
    {
      label: 'All',
      params: {
        statuses: 'PENDING,IN_TRANSIT,ARRIVED,IN_PROGRESS,FINISHED,PROVIDER_NOT_FOUND,CANCELLED_BY_CLIENT,CANCELLED_BY_PROVIDER,CANCELLED_BY_ADMIN,PAYMENT_METHOD_NOT_VERIFIED,AUTO_CANCELLED',
        customerId: params.id,
        showTransactions: true
      }
    },
], [params.id])
  const inviteFriendsConfigs = React.useMemo(() =>[
    {
      label: 'All',
      params: {
        url: `/admin/customers/${params.id}/invite-friend-usages`
      }
    },
  ], [params.id])
  const campaignsConfigs = React.useMemo(() =>[
    {
      label: 'All',
      params: {
        url: `/admin/customers/${params.id}/campaign-usages`
      }
    },
  ], [params.id])

  const profileUrl = data.customer.profileUrl  || defaultImage;

  const handleTabChange = (event: any) => {
    setActiveTab(event.target.value);
    setForceReload(Math.random());
  }

  const handleResetTab = () => {
    setActiveTab('info');
    setForceReload(Math.random());
  }

  const handleBlockClick = async () => {
    setUpdateLoading('blocking');
    const resp = await updateCustomerData(params.id!, { isBlocked: !isBlocked});
    setUpdateLoading('');
    if (resp.status === 'ERROR') {
      notification.error({
        message: 'Something went wrong!',
        duration: 2
    })
    } else {
      setIsBlocked(!isBlocked)
    }
  }

  const transactionColumns = [
    {
      title: 'Date',
      render(item: any) {
        return formatDate(item.givenDateTime || item.createdDate)
      }
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Amount',
      dataIndex: ['amount'],
      render(amount: number, item: any) {
        return (
          <div>
            {`€ ${amount}`}
            {item.depositType && (item.depositType === Deposit.Credit ? 
              <ArrowUpOutlined style={{ color: '#009933', marginLeft: 10 }} /> :
              <ArrowDownOutlined style={{ color: '#cc0000', marginLeft: 10 }} />)}
          </div>
        )
      }
    },
  ];

  const ordersColumns = [
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
    },
    {
      title: 'Provider Info',
      dataIndex: ['provider'],
      render(provider: any) {
      return <div>{provider && <><Avatar src={provider?.profileUrl || defaultImage} /><span style={{ marginLeft: 8 }}>{provider?.fullName}</span></>}</div>;
      },
    },
    {
        title: 'Address',
        dataIndex: 'address',
    },
    {
      title: 'Start Date',
      dataIndex: ['startDate'],
      render(startDate: string, data: any) {
        return formatDate(startDate || data.earlyStartDate);
      }
    },
    {
      title: 'Duration',
      dataIndex: ['hoursCount'],
      render(hoursCount: number) {
        return getHours(hoursCount);
      }
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
    {
      title: 'Transactions',
      dataIndex: ['id'],
      render(id: any) {
        return (
          <div onClick={() => {
            setVisible(true);
            setTransactions(null);
            authorisedRequest(`/admin/orders/${id}/transactions`)
              .then((transactions: any) => {
                setTransactions(transactions)
              })
          }}>
            <span className='transactions-link'>Transaction History</span>
          </div>
        )
      },
    },
  ];

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64, display: 'flex', flexDirection: 'column' }}>
        <Link className='to-back-container' to={`/clients/${params?.currentPage}`}>
          <ArrowLeftOutlined />
          <span className='to-back-text'>Back to all customers</span>
        </Link>
        <Space style={{ padding: '22px 0'  }}>
          <Avatar style={{ height: 70, width: 70}}  src={profileUrl} />
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 16}}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <Typography style={{ fontSize: 20, color: '#001629'}}>{data.customer.fullName}</Typography>
              {data.customer.user?.deactivated ?
              <Tooltip title='Deactivated'>
                <WarningFilled style={styles.warningIcon} />
              </Tooltip> : null}
            </div>
            <Space>
              {data.customer.rating && <Rate disabled allowHalf value={Math.round(data.customer.rating * 2) / 2} />}
              {data.customer.rating}
              <Button
                style={{ marginLeft: data.customer.rating ? 10 : 0, marginTop: 10 }}
                loading={isBlocked === undefined || updateLoading === 'blocking'}
                onClick={handleBlockClick}
                icon={isBlocked ? <UnlockOutlined /> : <LockOutlined />}
                danger
                type={isBlocked ? 'primary' : 'default'}
                disabled={isUserRoleMarketing(userAuthData)}
              >
                {isBlocked ? 'Unblock' : 'Block'}
              </Button>
            </Space>
          </Space>
        </Space>
        <Radio.Group style={{ paddingBottom: 16}} value={activeTab} onChange={handleTabChange}>
          <Radio.Button value='info'>Customer Info</Radio.Button>
          <Radio.Button value='orders'>Orders</Radio.Button>
          {isTransactionsVisible && <Radio.Button value='sepa-transactions'>Transactions</Radio.Button>}
          <Radio.Button value='inviteFriends'>Invite Friends</Radio.Button>
          <Radio.Button value='campaign'>Campaign</Radio.Button>
          <Radio.Button value='respiteCare'>Respite Care</Radio.Button>
        </Radio.Group>
        {activeTab === 'info' && <CustomerDetails setTransactionsVisible={setTransactionsVisible} onUpdate={setForceReload} data={data} loading={loading} />}
        {activeTab === 'orders' && <Pager configs={ordersConfigs} url={ordersUrl} columns={ordersColumns} rowClick={() => null} nestedTablePage={'0'} />}
        {activeTab === 'sepa-transactions' && <Pager url={''} headerComponentsInLeft={<HeaderComponentInLeft depositInfo={data.customer.depositDetails}/>} notSearchable={true} configs={transactionsConfigs} columns={transactionColumns} rowClick={() => null} nestedTablePage={'0'} />}
        {activeTab === 'inviteFriends' && <InviteFriends inviteFriendsConfigs={inviteFriendsConfigs} data={data} handleResetTab={handleResetTab} />}
        {activeTab === 'campaign' && <Pager configs={campaignsConfigs} url={campaignsUrl} columns={campaignColumns} rowClick={() => null} notSearchable={true} nestedTablePage={'0'} />}
        {activeTab === 'respiteCare' && <RespiteCare data={data} />}
      </Layout.Content>
      <TransactionHistory transactions={transactions} visibleModal={visibleModal} setVisible={setVisible} />
    </InnerLayout>
  );
};

const styles = {
  warningIcon: {
    marginLeft: 10,
    color: '#ee7080',
    fontSize: 20
  },
  inviterInfoItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  selfRegisteredInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}

interface HeaderComponentInLeftProps {
  depositInfo: DepositInfo;
}

interface DepositInfo {
  frozenDepositAmount: number;
  totalDepositAmount: number;
  totalDepositGiven: number;
}

const HeaderComponentInLeft: React.FC<HeaderComponentInLeftProps> = ({ depositInfo }) => {
  return (
    <Space style={{width: '100%', background: 'white', marginTop: 10 }}>
      <SepaLimitInfoItem title='Total Provided Limit' amount={depositInfo?.totalDepositGiven || 0} />
      <SepaLimitInfoItem title='Current Freezed Amount' amount={depositInfo?.frozenDepositAmount || 0}  />
      <SepaLimitInfoItem title='Current Available Limit' amount={depositInfo?.totalDepositAmount || 0}  />
    </Space>
  )
}

interface SepaLimitInfoItemProps {
  title: string;
  amount: number;
}

const SepaLimitInfoItem: React.FC<SepaLimitInfoItemProps> = ({title, amount}) => {
  return (
    <Space direction='vertical' style={{ padding: 10}}> 
      <Text>{title}</Text>
      <Text style={{ fontWeight: 'bold'}}>{`€ ${amount}`}</Text>
    </Space>
  )
}
