import React, { useContext, useState } from 'react';
import { Skeleton, Card, Descriptions, Button, Avatar, Carousel, Modal } from 'antd';

import { formatDate, isUserRoleMarketing } from '../../utils/helpers';
import { useTicketDetails } from './useTicketDetails';
import { useTicketActions } from './useTicketActions';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { MainContext } from '../../App';


const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";

export const TicketDetails = ({ id, onChange }: { id: string, onChange: Function }) => {
  const { userAuthData } = useContext(MainContext);
  const { loading, data } = useTicketDetails(id);
  const { updateStatus } = useTicketActions(id, onChange)
  const [isImageModalOpened, setImageModalOpened] = useState(false)
  const [to, setTo ] = useState(0)
  const isImageAttached = data.images && data.images.length;
  let CarouselRef: any;
    let actions = [
        <Button disabled={data.status === 'ACTIVE' || loading || isUserRoleMarketing(userAuthData)} type="primary" onClick={() => updateStatus('ACTIVE')}>Active</Button>,
        <Button disabled={data.status === 'ARCHIVED' || loading || isUserRoleMarketing(userAuthData)}  type="default" onClick={() => updateStatus('ARCHIVED')} >Archive</Button>,
        ];

    const viewImages = () => () => {
        if (!isImageAttached) return;
        setImageModalOpened(true);
    }
  const beforeChange = (from: any, to: any) => {
      setTo(to);
  }
  return (
    <div>
      <Card>
        <Skeleton loading={loading} active>
          <Descriptions title="Profiles" size="small" column={1} bordered={true}>
            {data?.customer &&<Descriptions.Item>
              <Avatar src={data?.customer?.profileUrl || defaultImage} />
              <span style={{ marginLeft: 8 }}>{data?.customer?.fullName}</span>
            </Descriptions.Item>}
            {data?.provider && <Descriptions.Item>
              <Avatar src={data?.provider?.profileUrl || defaultImage} />
              <span style={{ marginLeft: 8 }}>{data?.provider?.fullName}</span>
            </Descriptions.Item>}
          </Descriptions>
        </Skeleton>
      </Card>
      <br />
      <Card actions={actions}>
        <Skeleton loading={loading} active>
          <Descriptions title="Ticket details" size="small" column={1} bordered={true}>
            <Descriptions.Item label="Created Date">{formatDate(data.createdDate, 'DD-MM-YYYY HH:mm')}</Descriptions.Item>
            <Descriptions.Item label="Message">{data.comment}</Descriptions.Item>
            <Descriptions.Item label='Images'>
                <div style={isImageAttached ? {cursor: 'pointer', textDecoration: 'underline'} : {}} onClick={viewImages()}>{isImageAttached ? 'View' : 'There is no attached image'}</div>
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
      </Card>
      <Modal
        title={
          <div style={styles.Header}>
              <h2>Image Preview</h2>
              <p style={styles.Counter}>{`${to + 1} / ${data?.images?.length}`}</p>
          </div>
      }
        visible={isImageModalOpened}
        footer={[
            <Button key="back" onClick={() => setImageModalOpened(false)} disabled={isUserRoleMarketing(userAuthData)}>
              OK
            </Button>
          ]}
          width="800px"
          closable={false}
      >
          {data?.images?.length > 1 &&
              <div onClick={() => CarouselRef && CarouselRef.prev()} style={styles.LeftArrow as React.CSSProperties}>
                  <ArrowLeftOutlined />
              </div>}
          <Carousel draggable ref={ref => CarouselRef = ref} dots={false} beforeChange={beforeChange}>
              {
                  data.images && data.images.map(( amazonUrl: string) => <img key={amazonUrl} alt="preview" src={amazonUrl}></img>)
              }
          </Carousel>
          {data?.images?.length > 1 &&
              <div onClick={() => CarouselRef && CarouselRef.next()} style={styles.RightArrow as React.CSSProperties}>
                  <ArrowRightOutlined />
              </div>}
      </Modal>
    </div>
  );
};


const styles = {
    Header: {
        display: 'flex'
    },
    Counter: {
       marginLeft: '28%',
        fontSize: 25,
        fontWeight: 700,
    },
    LeftArrow: {
        position: 'absolute',
        left: '3%',
        top: '50%',
        height: 20,
        width: 20,
        borderRadius: 20,
        overflow: 'hidden',
        zIndex: 1,
        cursor: 'pointer',
    },
    RightArrow: {
        position: 'absolute',
        right: '3%',
        top: '50%',
        height: 20,
        width: 20,
        borderRadius: 20,
        overflow: 'hidden',
        zIndex: 1,
        cursor: 'pointer',
    },
}