import React from "react";
import { Select } from "antd";
import { PromoCodeRequest, PromoCodeType, PromoCodeSubType, UpdatedContent } from "./campaingTypes";
import { styles } from "./styles";
import { FreeTimeTypeContent } from './FreeTimeTypeContent';
import { OrderBonusForProviderTypeContent } from './OrderBonusForProviderTypeContent';
import { AmountDiscountTypeContent } from './AmountDiscountTypeContent';
import { DiscountPercentTypeContent } from './DiscountPercentTypeContent';
import { SignUpProviderBonusTypeContent } from './SignUpProviderBonusTypeContent';

const { Option } = Select;

interface SimpleCampaingContentProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    providerTypes: any[];
    disabled: boolean;
}

export const SimpleCampaingContent: React.FC<SimpleCampaingContentProps> = ({ modalContent, setModalContent, providerTypes, disabled }) => {

    const handlePromoCodeTypeChange = (type: PromoCodeType) => {
        setModalContent({
            type,
            individual: false,
        })
    }

    const handlePromoCodeSubTypeChange = (subType: PromoCodeSubType) => {
        setModalContent({
            ...modalContent,
            details: {
                ...modalContent.details,
                subType
            }
        })
    }

    const getCapmaingByPromoCodeType = (promoCodeType?: PromoCodeType | PromoCodeSubType) => {
        switch(promoCodeType) {
            case PromoCodeType.ORDER_FREE_TIME: 
            case PromoCodeSubType.CLIENT_ORDER_FREE_TIME:
                return <FreeTimeTypeContent updatedContent={UpdatedContent.details} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />;
            case PromoCodeType.ORDER_AMOUNT_DISCOUNT:
            case PromoCodeSubType.CLIENT_ORDER_AMOUNT_DISCOUNT:
                return <AmountDiscountTypeContent updatedContent={UpdatedContent.details} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            case PromoCodeType.ORDER_DISCOUNT_PERCENT:
            case PromoCodeSubType.CLIENT_ORDER_DISCOUNT_PERCENT:
                return <DiscountPercentTypeContent updatedContent={UpdatedContent.details} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            case PromoCodeSubType.PROVIDER_BONUS:
                return <SignUpProviderBonusTypeContent updatedContent={UpdatedContent.details} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            case PromoCodeType.ORDER_PROVIDER_BONUS:
                return <OrderBonusForProviderTypeContent updatedContent={UpdatedContent.details} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            default: return null;
        }
    }


    const handleChangeProviderType = (providerTypesArr: any) => {
        const ids : string[] = providerTypesArr.map((i:any) => i.value);
        setModalContent({
            ...modalContent,
            providerTypeIds: ids
        })
    }

    const options = providerTypes.map((providerType: any) => {
        return { value: providerType.id, label: providerType.name }
    })
    const value = options.filter((option: any) =>{
        return modalContent?.providerTypeIds?.find((id) => option.value === id)
    })

    return (
        <div>
            <Select onChange={handlePromoCodeTypeChange} value={modalContent.type} style={styles.modalRowItem} disabled={disabled} >
                <Option value={PromoCodeType.ORDER_FREE_TIME}>Free Time</Option>
                <Option value={PromoCodeType.ORDER_AMOUNT_DISCOUNT}>Amount Discount</Option>
                <Option value={PromoCodeType.ORDER_DISCOUNT_PERCENT}>Discount %</Option>
                <Option value={PromoCodeType.SIGNUP_CLIENT}>Sign Up For Clients</Option>
                <Option value={PromoCodeType.SIGNUP_PROVIDER}>Sign Up For Provider</Option>
                <Option value={PromoCodeType.ORDER_PROVIDER_BONUS}>Bonus For Providers</Option>
            </Select>
            {(modalContent.type === PromoCodeType.SIGNUP_PROVIDER  || modalContent.type === PromoCodeType.ORDER_PROVIDER_BONUS) &&
                <Select
                    labelInValue
                    mode="multiple"
                    showSearch={false}
                    placeholder="Provider type"
                    onChange={handleChangeProviderType}
                    style={styles.modalRowItem}
                    showArrow
                    disabled={disabled}
                    options={options}
                    value={value}
                />
              }
            {(modalContent.type === PromoCodeType.SIGNUP_CLIENT || modalContent.type === PromoCodeType.SIGNUP_PROVIDER) &&
            <Select value={modalContent.details?.subType} onChange={handlePromoCodeSubTypeChange} placeholder='Promo Code SubType' style={styles.modalRowItem} disabled={disabled} >
                {modalContent.type === PromoCodeType.SIGNUP_CLIENT && <Option value={PromoCodeSubType.CLIENT_ORDER_FREE_TIME}>Free Time</Option>}
                {modalContent.type === PromoCodeType.SIGNUP_CLIENT && <Option value={PromoCodeSubType.CLIENT_ORDER_AMOUNT_DISCOUNT}>Amount Discount</Option>}
                {modalContent.type === PromoCodeType.SIGNUP_CLIENT && <Option value={PromoCodeSubType.CLIENT_ORDER_DISCOUNT_PERCENT}>Discount %</Option>}
                {modalContent.type === PromoCodeType.SIGNUP_PROVIDER &&<Option value={PromoCodeSubType.PROVIDER_BONUS}>Bonus For Providers</Option>}
            </Select>}
            {getCapmaingByPromoCodeType(modalContent.type)}
            {getCapmaingByPromoCodeType(modalContent.details?.subType)}
        </div> 
    )
}