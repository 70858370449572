import { authorisedRequest } from './authorisedRequest';

interface Item {
  language?: string;
  serviceType: string;
  text: string;
  type?: string;
}

export const createItem = (payload: Item) => {
    return authorisedRequest(`/help-center/items`, 'POST',  payload)
}

export const editItem = (payload: Item, itemId: string) => {
    return authorisedRequest(`/help-center/items/${itemId}`, 'PUT',  payload)
}

export const deleteItem = (itemId: string) => {
    return authorisedRequest(`/help-center/items/${itemId}`, 'DELETE')
}

