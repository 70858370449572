import React, { useState } from 'react';
import { Layout, Avatar, Space, Badge, Typography } from 'antd';

import { InnerLayout } from '../../components/InnerLayout';
import { Pager } from '../../components/pager/Pager';
import { formatDate } from '../../utils/helpers';
import { TicketDetails } from '../../components/ticketDetails/TicketDetails';

export const HelpCenter = ({ configs }: { configs?: Array<any> }) => {
  const [forceReload, setForceReload] = useState(0);
  const [activeTicketId, setActiveTicketId] = useState('');

  const url = `/admin/help-center/tickets`;
  const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";

  const columns = [
    {
      title: 'Status',
      dataIndex: ['status'],
      render(status: string) {
        const badgeStatus = status === 'ARCHIVED' ? 'default' : 'success';
        const statusText = status === 'ARCHIVED' ? 'Archived' : 'Active';
        return (
          <Space>
            <Badge status={badgeStatus} />
            <Typography>{statusText}</Typography>
          </Space>
        )
      }
    },
    {
      title: 'Order Id',
      dataIndex: ['orderId'],
      render(orderId: any) {
        return <div>{orderId?.slice(0,16)}</div>;
      },
    },
    {
        title: 'Ticket Number',
        dataIndex: 'ticketNumber',
    },
    {
      title: 'Customer Info',
      dataIndex: ['customer'],
      render(customer: any) {
      return <div>{customer ? <><Avatar src={customer?.profileUrl || defaultImage} /><span style={{ marginLeft: 8 }}>{customer?.fullName}</span></> : 'N/A'}</div>;
      },
    },
    {
      title: 'Provider Info',
      dataIndex: ['provider'],
      render(provider: any) {
      return <div>{provider ? <><Avatar src={provider?.profileUrl || defaultImage} /><span style={{ marginLeft: 8 }}>{provider?.fullName}</span></> : 'N/A'}</div>;
      },
    },
    {
      title: 'created Date',
      dataIndex: ['createdDate'],
      render(createdDate: string) {
        return formatDate(createdDate, 'DD-MM-YYYY HH:mm');
      }
    },
  ];

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
        <Pager url={url} columns={columns} configs={configs} rowClick={(id: string) => setActiveTicketId(id)} forceReload={forceReload} />
      </Layout.Content>
      {activeTicketId &&
        <Layout.Sider
          width={400}
          style={{
            padding: '84px 30px 84px 0',
            backgroundColor: 'transparent',
            overflow: 'auto',
            height: '100vh',
          }}
        >
          {activeTicketId && <TicketDetails id={activeTicketId} onChange={() => { setActiveTicketId(''); setForceReload(Math.random()); }} />}
        </Layout.Sider>}
    </InnerLayout>
  )
}
