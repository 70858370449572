import React, { useMemo, useState } from 'react';
import { Pager } from '../../components/pager/Pager';
import { Typography, Space, DatePicker, Avatar } from 'antd';
import clientsImg from '../../icons/clients.png';
import './card.css';
import moment from 'moment';
const { Text } = Typography;
const { RangePicker } = DatePicker;

let configs = [
    {
        params: {}
    },
];
const url = `/dashboard/top-customers`;
const columns = [
    {
        title: 'Full Name',
        render(record: any) {
            return <div><Avatar src={record.profileUrl || defaultImage} /><span style={{ marginLeft: 16 }}>{record.fullName}</span></div>;
        },
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
];
const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";
export const BestClients: React.FC = () => {
    const [period, setPeriod] = useState<any>({
        createdDateFrom: moment().format('YYYY-MM-DD'),
        createdDateTo:moment().format('YYYY-MM-DD'),
    })
    const[bestCustomer, setBest] = useState<any>()

    configs = useMemo(() => {
        return [{params: {...period}}]
    }, [period]);

    const handlePeriodChange = (period: any) => {
        period &&
        setPeriod({
            createdDateFrom: period[0]?.format('YYYY-MM-DD'),
            createdDateTo: period[1]?.format('YYYY-MM-DD'),
        })
    }
    const defaultComponent =
        <div className={'imgWrapper'}>
            <img src={clientsImg} alt={'ClientsImg'} style={{ marginBottom: 27}}/>
            <br />
            <Text className={'infoText'}>
                {'There is no clients for this period'}
            </Text>
        </div>

    return(
        <>
            <Space className={'headerCard'} >
                <Text className={'titleText'}>Best clients</Text>
                <RangePicker
                    value={period?.createdDateFrom ? [moment(period?.createdDateFrom), moment(period?.createdDateTo)] : null}
                    onChange={handlePeriodChange}
                />
            </Space>
            {bestCustomer &&
                <div className={'bestDetails'}>
                    <div>
                        <Avatar src={bestCustomer?.profileUrl || defaultImage} />
                        <div className={'details'}>
                            <span>{bestCustomer?.fullName}</span>
                            <span>{bestCustomer?.email}</span>
                        </div>
                    </div>
                    <div className='flag'>
                        <div></div>
                        <span className={'best'}>Best</span>
                    </div>
                </div>}
            <Pager
                configs={configs}
                url={url}
                columns={columns}
                rowClick={() => null }
                notSearchable={true}
                defaultComponent={defaultComponent}
                showHeader={false}
                setBest={setBest}
            />
        </>
    )
}

