import { useState } from 'react';
import { approveProvider, rejectProvider, verifyProvider, updateProviderInfo, changeComment } from '../../api/providers';
import { notification } from 'antd';

export const useProviderActions = (id: string, onChange: any, setAccountStatus: any, setPaymentErrors: any) => {
  const [loading, setLoading] = useState(false);
  const [ veifyLoading, setVerifyLoading ] = useState(false);
  const [ updateLoading, setUpdateLoading ] = useState('');

  const approve = () => {
    setLoading(true);
    approveProvider(id)
      .then((resp: any) => {
          if (resp.status === 'ERROR' && resp.result.errorCode === 'PROVIDER_ACTIVATE_DOCUMENTS_NOT_APPROVED') {
              notification.error({
                  message: 'You have unaccepted documents',
                  duration: 2
              })
              setLoading(false)
              return;
          }
          onChange()
      })
      .catch(() => {
          setLoading(false)
      });
  };

  const reject = (reason: string) => {
    setLoading(true);
    rejectProvider(id, reason)
      .then(onChange)
      .catch(() => setLoading(false));
  };

  const verify = () => {
    setVerifyLoading(true);
    verifyProvider(id)
      .then( async (resp: any) => {
        if (resp.code === 417) {
          const payemntErrors = resp.result.errors.map((error: any) => error.errorCode);
          return setPaymentErrors(payemntErrors);
        }
        if (resp.code === 400) {
          return setAccountStatus('ACTIVE');
        }
        if (resp.code === 200 || resp.status === 'DETAILS_SUBMITTED') {
          return setAccountStatus('DETAILS_SUBMITTED');
        }
        setPaymentErrors(['Something wrong!']);
      })
      .finally(() => setVerifyLoading(false));
  }

  const changeProviderComment = (userId: string, comment: string) => {
    setUpdateLoading('comment');
    return changeComment(userId, comment)
      .finally(() => { setUpdateLoading('')});
  } 

  const update = (payload: any, loadFor: string) => {
    setUpdateLoading(loadFor);
    return updateProviderInfo(id, payload)
      .then((updatedInfo: any) => {
        if (loadFor === 'approving') {
          onChange();
        }
        return updatedInfo
      })
      .catch(() => setUpdateLoading(''))
      .finally(() => setUpdateLoading(''));
  }
  return { loading, veifyLoading, updateLoading, approve, reject, verify, update, changeProviderComment };

};
