import { authorisedRequest } from './authorisedRequest';

interface FAQ {
    language?: string;
    serviceType: string;
    question: string;
    answer: string;
    type?: string;
}

export const createFAQ = (payload: FAQ) => {
    return authorisedRequest(`/help-center/faqs`, 'POST',  payload)
}

export const editFAQ = (payload: FAQ, faqId: string) => {
    return authorisedRequest(`/help-center/faqs/${faqId}`, 'PUT',  payload)
}

export const deleteFAQ = (faqId: string) => {
    return authorisedRequest(`/help-center/faqs/${faqId}`, 'DELETE')
}

