import React, { useContext, useState } from 'react';
import ISO6391 from 'iso-639-1';
import { Skeleton, Card, Descriptions, Typography, Space, Input, Switch, notification, Button } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, EuroOutlined } from '@ant-design/icons';
import { formatDate, isUserRoleMarketing } from '../../utils/helpers';
import { Deposit, updateCustomerData, updateCustomerDeposit } from '../../api/customers';
import { MainContext } from '../../App';

const { Text, Paragraph } = Typography;

export const CustomerDetails = ({ data, loading, onUpdate, setTransactionsVisible }: { data: any, loading: boolean, onUpdate(num: number): void, setTransactionsVisible(value: boolean): void  }) => {
  const [SEPAPaymentState, setSEPAPaymentState] = useState(false);
  const [SEPAMaxLimit, setSEPAMaxLimit] = useState('0');
  const [limitLoading, setLimitLoading] = useState(false);
  const { userAuthData } = useContext(MainContext);
  React.useEffect(() => {
    setSEPAPaymentState(data.customer.depositDetails?.depositPaymentsEnabled);
    setSEPAMaxLimit(data.customer.depositDetails?.totalDepositGiven.toString());
  }, [data.customer]);

  
  const handleSEPAPaymentSwitch = (checked: boolean) => {
    setSEPAPaymentState(checked);
    updateCustomerData(data.customer.id, { depositPaymentsEnabled: checked }).then((resp: any) => {
      if (resp.status === 'ERROR') {
        setSEPAPaymentState(!checked);
        notification.error({
          message: 'Something went wrong!',
          duration: 2
        })
      } else {
        setTransactionsVisible(checked)
      }
    });
  }

  const handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: maxLimit }= event.target;
    let numbers = /^[0-9]+$/;
    if ((maxLimit.match(numbers) || maxLimit === '') && (maxLimit === '0' || maxLimit.indexOf('0') !== 0)) {
      setSEPAMaxLimit(maxLimit)
    }
  }

  const handleSaveMaxLimit = async () => {
    let amount = Number(SEPAMaxLimit) - data.customer.depositDetails?.totalDepositGiven;
    setLimitLoading(true);
    const resp = await updateCustomerDeposit(data.customer.id, {
      amount: Math.abs(amount),
      depositType: amount > 0 ? Deposit.Credit : Deposit.Debit
    });
    setLimitLoading(false);
    if(resp.status === 'ERROR') {
      notification.error({
        message: 'Something went wrong!',
        duration: 2
      });
    } else {
      onUpdate(Math.random())
    }
  }

  return (
    <Card>
      <Skeleton loading={loading} active>
        <Descriptions title="Personal Info" size="small" column={6} layout='vertical'>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Email">
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <Text style={styles.InfoItemText as React.CSSProperties}>
                  {data.customer.user?.email}
                </Text>
                {data.customer.user?.emailVerified
                ? <CheckOutlined style={{ marginRight: 10, paddingTop: 5, color:'#1890ff' }} />
                : <ExclamationCircleOutlined style={{ marginRight: 10, paddingTop: 5, color:'#ff5959' }}/>}
              </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Date of Birth">
            <Text style={styles.InfoItemText as React.CSSProperties}>
              {data.customer.birthDate ?? 'N/A'}
            </Text>
          </Descriptions.Item> 
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Gender">
            <Text style={styles.InfoItemText as React.CSSProperties}>
              {data.customer.gender === 'OTHER' ? 'Rather not to say' : (data.customer.gender ?? 'N/A')}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Status">
            <Text style={styles.InfoItemText as React.CSSProperties}>
              {data.customer.status}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Address">
            <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
              {data.customer.address ?
                    Object.keys(data.customer.address).map(addressProps => {
                    return (
                      data.customer.address[addressProps] && <Text key={addressProps} style={styles.InfoItemText as React.CSSProperties}>
                        {`${data.customer.address[addressProps]},`}
                      </Text>
                    )
                  }) : 
                  <Text style={styles.InfoItemText as React.CSSProperties}>{'N/A'}</Text>}
            </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Languages">
            <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
            {data.customer.languages?.length > 0 &&
              data.customer.languages.map((language: any) => (
                <Text key={language.name} style={styles.InfoItemText as React.CSSProperties}>
                  {`${ISO6391.getName(language.name.toLowerCase())},`}
                </Text>
              ))
            }
            </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Code of Conduct">
            {data.customer.user?.codeOfConductsAccepted
              ? <Space>
                  <Typography style={{ fontWeight: 600 }}>Accepted</Typography>
                  <CheckOutlined style={{ color:'#1890ff'}} />
                </Space>
              : <Space>
                  <Typography style={{ fontWeight: 600 }}>Non Accepted</Typography>
                  <ExclamationCircleOutlined style={{ color:'#ff5959'}} />
                </Space>}
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Terms and Conditions">
            {data.customer.user?.termsAndConditionsAccepted
              ? <Space>
                  <Typography style={{ fontWeight: 600 }}>Accepted</Typography>
                  <CheckOutlined style={{ color:'#1890ff'}} />
                </Space>
              : <Space>
                  <Typography style={{ fontWeight: 600 }}>Non Accepted</Typography>
                  <ExclamationCircleOutlined style={{ color:'#ff5959'}} />
                </Space>}
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Last login">
            <Text style={styles.InfoItemText as React.CSSProperties}>
              {data.customer.user?.lastLoginDate
                ? formatDate(data.customer.user?.lastLoginDate, 'DD-MM-YYYY HH:mm')
                : 'N/A'}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Last time app opened">
            <Text style={styles.InfoItemText as React.CSSProperties}>
              {data.customer.user?.lastActiveDate
                ? formatDate(data.customer.user?.lastActiveDate, 'DD-MM-YYYY HH:mm')
                : 'N/A'}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Last searched/requested order">
            <Text style={styles.InfoItemText as React.CSSProperties}>
              {data.customer.lastOrderDate ? formatDate(data.customer.lastOrderDate, 'DD-MM-YYYY HH:mm') : 'N/A'}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Last modification in the app">
            <Text style={styles.InfoItemText as React.CSSProperties}>
              {data.customer.user?.lastUpdatedDate
                ? formatDate(data.customer.user?.lastUpdatedDate, 'DD-MM-YYYY HH:mm')
                : 'N/A'}
            </Text>  
          </Descriptions.Item>
        </Descriptions>
        {data.paymentMethods?.length > 0 &&
        <Descriptions title="Payment methods" size="small" column={6} style={{ marginTop: 20 }}>
          {data.paymentMethods.map((method: any) => (
            <Descriptions.Item key={method.id} label={method.brand}>{`**** **** **** ${method.last4}`}</Descriptions.Item>
          ))}
        </Descriptions>
        }
        <Descriptions title="SEPA Payment" size="small" column={1} style={{ marginTop: 20 }}>
          <Descriptions.Item> 
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <Switch onChange={handleSEPAPaymentSwitch} checked={SEPAPaymentState}  disabled={isUserRoleMarketing(userAuthData)} />
              <Paragraph style={{ margin: 0, marginLeft: 15}}>{SEPAPaymentState ? 'On' : 'Off'}</Paragraph> 
            </div>
          </Descriptions.Item>
          <Descriptions.Item>
            <div>
              <Paragraph style={{ margin: 0 }}>Max Limit</Paragraph>
              <Input
                  onChange={handleInputValueChange}
                  value={SEPAMaxLimit}
                  prefix={<EuroOutlined />}
                  disabled={isUserRoleMarketing(userAuthData)}
              />
              {data.customer.depositDetails?.totalDepositGiven.toString() !== SEPAMaxLimit && SEPAMaxLimit !== '' && 
                <Button
                    loading={limitLoading}
                    onClick={handleSaveMaxLimit}
                    type='primary' style={{ marginTop: 10 }}
                    disabled={isUserRoleMarketing(userAuthData)}
                >
                  Save
                </Button>
              }
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Skeleton>
      {data?.customer?.referralCode &&
          <Typography.Text style={{ position: 'absolute', top: -110, right: 0, width: 300, fontWeight: 600, cursor: 'pointer', textAlign: 'end' }}>
            {`Referral Code: ${data?.customer?.referralCode}`}
          </Typography.Text>
       }
    </Card>
  );
};

const styles = {
  InfoItemText: {
    fontWeight: 600,
    wordBreak: 'break-all',
    margin: 0,
    marginRight: 10,
    display: 'table-row',
  }
}
