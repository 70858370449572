import { useState } from 'react';

import { updateTicketStatus } from '../../api/tickets';

export const useTicketActions = (id: string, onChange: any) => {
  const [loading, setLoading] = useState(false);

  const updateStatus = (status: string) => {
    setLoading(true);
    updateTicketStatus(id, status)
      .then(onChange)
      .catch(() => setLoading(false));
  };

  return { loading, updateStatus };

};
