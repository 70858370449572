import React, { useEffect, useState } from 'react';

import { Layout, Avatar, Tooltip, Popover, Spin, Typography, Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { InnerLayout } from '../../components/InnerLayout';
import { Pager } from '../../components/pager/Pager';

import { formatDate } from '../../utils/helpers';
import { CheckCircleTwoTone, ClockCircleTwoTone, ExclamationCircleTwoTone, WarningFilled } from '@ant-design/icons';
import { configs } from './providersConfigs';
import { fetchDuplicatedProviders } from '../../api/providers';
import { getProviderTypes } from '../../api/servicePrices';

const { Text } = Typography;
const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";

export const Providers = () => {
  const history = useHistory();
  const [label, setLabel] = useState<string>(configs[0].label);
  const [providerTypes, setProviderTypes] = useState<any[]>([]);
  useEffect(() => {
    getProviderTypes()
        .then((data: any[]) => {
          setProviderTypes(data)
        })
  }, [])

  const handleRowClick = (id: string, configIndex: number, _: any, currentPage: number) => {
    return history.push(`/providers/${id}/${configIndex}/${currentPage}`);
  }

  const url = `/admin/providers`;

  const columns =
      [
        {
          title: 'Full Name',
          key: 'fullName',
          sorter: label !== "Not Registered"?{
            multiple: 1
          }: null,
          render(_: any, provider: any) {
            return provider.info?
                <div style={styles.fullNameContainer}>
                  <Avatar src={JSON.parse(provider.info.profileUrl || "\"\"") || defaultImage} />
                  <span style={{ marginLeft: 8 }}>{JSON.parse(provider.info?.fullName || "\"\"")}</span>
                </div>:
                <FullNameColumnItem fullName={provider.fullName} provider={provider} />
          },
        },
        {
          title: 'BirthDate',
          dataIndex: ['info', 'birthDate'],
          render(birthDate: string){
            return (JSON.parse(birthDate || "\"\"").slice(0,10))
          }
        },
        {
          title: 'Gender',
          dataIndex: ['info', 'gender'],
          render(gender: string){
            return (JSON.parse(gender || "\"\""))
          }
        },
        {
          title: 'Address',
          dataIndex: ['info', 'address'],
          render(address: string){
            let addressName = JSON.parse(address || "\"\"");
            if (addressName){
              addressName = `${addressName.country} ${addressName.region} ${addressName.city} 
               ${addressName.street} ${addressName.buildingNumber} ${addressName.postalCode}`;
            }
            return (addressName)
          }
        },
        {
          title: 'Email',
          render(_: any, provider: any) {
            const email = provider.info? JSON.parse(provider.info.email || "\"\""):provider.user.email;
            return <a href={`mailto: ${email}`}>{email}</a>
          },
        },
        {
          title: 'Phone',
          render(_: any, provider: any) {
            const mobile = provider.info?
                `${JSON.parse(provider.info.countryCode || "\"\"")} ${JSON.parse(provider.info.phoneNumber || "\"\"")}`:
                provider.user.mobile;
            return <>{mobile}</>
          }
        },
        {
          title: 'Provider types',
          render(_: any[], provider: any) {
            const providerType = providerTypes.find((item: any) => item.id === provider.providerTypeId)
            return (<>{providerType?.name}</>)
          },
        },
        {
          title: 'Registration Date',
          key: 'createdDate',
          sorter: {
            multiple: 1
          },
          render(_: any[], provider: any) {
            return label !== "Not Registered" ?
                formatDate(provider?.user?.createdDate, 'DD-MM-YYYY HH:mm') :
                formatDate(provider?.createdDate, 'DD-MM-YYYY HH:mm')
          }
        },
        {
          title: 'Last Active',
          key: 'lastActivity',
          dataIndex: ['user', 'lastActiveDate'],
          sorter: {
            multiple: 1
          },
          render(lastActiveDate: string) {
            return formatDate(lastActiveDate, 'DD-MM-YYYY HH:mm');
          }
        },
        {
          title: 'Last Order Date',
          key: 'lastOrderDate',
          dataIndex: ['user','lastOrderDate'],
          sorter: {
            multiple: 1
          },
          render(lastOrderDate: string) {
            return  lastOrderDate ? formatDate(lastOrderDate, 'DD-MM-YYYY HH:mm') : 'N/A';
          }
        },
        {
          title: 'Provider Status',
          key: 'duty.inDuty',
          dataIndex: ['inDuty'],
          sorter: {
            multiple: 1
          },
          render(inDuty: boolean) {
            if (inDuty) {
              return 'Active'
            } else {
              return 'Inactive' ?? 'N/A'
            }
          }
        },
        {
          title: 'Documents Status',
          dataIndex: ['hasDocToApprove'],
          render(hasDocToApprove: boolean) {
            return hasDocToApprove ? <ClockCircleTwoTone style={{ fontSize: 20, marginLeft: '25%'}} twoToneColor="#f7cb73" /> : <CheckCircleTwoTone style={{ fontSize: 20, marginLeft: '25%'}} twoToneColor="#52c41a" /> ;
          }
        },
        {
          title: 'Rating',
          dataIndex: 'rating',
          key: 'rating',
        },
      ];

  const handleLabelChange = (labelIndex: number) => {
    setLabel(configs[labelIndex].label);
  }

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
        <Pager
          url={url}
          columns={columns}
          configs={configs}
          rowClick={label === 'Not Registered'? () => null: handleRowClick}
          onLabelChange={handleLabelChange}
          providerTypes={providerTypes}
        />
      </Layout.Content>
    </InnerLayout>
  );
};


interface FullNameColumnItemProps {
  fullName: string;
  provider: any;
}

const FullNameColumnItem: React.FC<FullNameColumnItemProps> = ({ fullName, provider }) => {
  const [isDuplicatedProvidersPopupVisible, setisDuplicatedProvidersPopupVisible] = useState(false); 


  const handleDuplicateIconClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    setisDuplicatedProvidersPopupVisible(true);
  }

  return (
    <div style={styles.fullNameContainer}>
      <Avatar src={provider.profileUrl || defaultImage} /><span style={{ marginLeft: 8 }}>{fullName}</span>
      {provider.user?.deactivated &&
      <Tooltip title='Deactivated'>
        <WarningFilled style={styles.warningIcon} />
      </Tooltip>}
      {provider.hasDuplicate && 
        <Popover
          trigger='click'
          title='Duplicated Providers'
          content={<DuplicatedProviders providerId={provider.id}  isVisible={isDuplicatedProvidersPopupVisible}/>}
        >
          <ExclamationCircleTwoTone
            onClick={handleDuplicateIconClick}
            style={{ marginLeft: 8 }}
            twoToneColor='#f9b800'
          />
        </Popover>
      }
    </div>
  )
}

const stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
  event.stopPropagation()
}

interface DuplicatedProvidersProps {
    providerId: string;
    isVisible: boolean;
}

interface DuplicatedProvider {
  fullName: string;
  id: string;
}

interface ProvidersParams {
  configIndex: string;
  currentPage: string;
}

const DuplicatedProviders: React.FC<DuplicatedProvidersProps> = ({ providerId, isVisible }) => {
  const history = useHistory();
  const params = useParams<ProvidersParams>();
  const { configIndex, currentPage} = params;
  const [duplicatedProviders, setDuplicatedProviders] = useState<DuplicatedProvider[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDuplicatedProviders = async () => {
      const duplicatedProviders = await fetchDuplicatedProviders(providerId);
      if (duplicatedProviders.status !== 'ERROR') {
        setDuplicatedProviders(duplicatedProviders);
      }
      setLoading(false);
    }
    getDuplicatedProviders();
    // eslint-disable-next-line
  }, [])

  const handleDuplicateProviderClick = (duplicatedProviderId: string) => (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    history.push(`/providers/${duplicatedProviderId}/${configIndex}/${currentPage}`);
  }

  if (!isVisible) {
      return null;
  }

  return (
      <div style={styles.popupConainer as React.CSSProperties}>
        {loading && <Spin />}
        {!loading && !duplicatedProviders.length && <Text>Something wrong!</Text>}
        {!loading && duplicatedProviders.length && duplicatedProviders.map((duplicatedProvider, index) => {
          if (providerId === duplicatedProvider.id) {
            return null;
          }
          return (
            <Button
              onClick={handleDuplicateProviderClick(duplicatedProvider.id)}
              key={duplicatedProvider.id}
              type="link"
            >
              {`${duplicatedProvider.fullName}(${index})`}
            </Button>
          )
        })}
      </div>
  )
}

const styles = {
  warningIcon: {
    marginLeft: 10,
    color: '#ee7080',
  },
  fullNameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  popupConainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }
}
