import React from "react";
import { Select, Input} from "antd";
import { PromoCodeRequest, UpdatedContent } from "./campaingTypes";
import { RangePickerCustom } from "./RangePickerCustom";
import { styles } from "./styles";
import { containOnlyLettersAndNumbers } from '../../utils/helpers';

const { Option } = Select;


interface AmountDiscountTypeContentProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    updatedContent: UpdatedContent;
    disabled: boolean;
}

export const AmountDiscountTypeContent: React.FC<AmountDiscountTypeContentProps> = ({ updatedContent, modalContent, setModalContent, disabled }) => {

    const handleMaxUsageChange = (maxUsagePerUser: number) => {
        setModalContent({
            ...modalContent,
            [updatedContent]: {
                ...modalContent[updatedContent],
                maxUsagePerUser,
            }
        })
    }

    const handlePromoCodeNameChange = (event: any) => {
        if(!containOnlyLettersAndNumbers(event.nativeEvent.target.value)){
            return
        }
        setModalContent({
            ...modalContent,
            name: event.nativeEvent.target.value
        })
    }

    const handleDiscountAmountChange = (event: any) => {
        const { value } = event.nativeEvent.target;
        const numbers = /^[0-9]+$/;
        if (value.match(numbers) || value === '') {
            setModalContent({
                ...modalContent,
                [updatedContent]: {
                    ...modalContent[updatedContent],
                    discountAmount: parseInt(event.nativeEvent.target.value) || undefined
                }
            })
        }
    }

    const handleOrderAmountChange = (event: any) => {
        const { value } = event.nativeEvent.target;
        const numbers = /^[0-9]+$/;
        if (value.match(numbers) || value === '') {
            setModalContent({
                ...modalContent,
                [updatedContent]: {
                    ...modalContent[updatedContent],
                    minOrderAmountToApply: parseInt(event.nativeEvent.target.value) || undefined
                }
            })
        }
    }

    return (
        <div>
            {updatedContent === UpdatedContent.details && <Input value={modalContent.name || ''} onChange={handlePromoCodeNameChange} style={styles.modalRowItem} placeholder='Promo Code Name' maxLength={32} disabled={disabled} />}
            <Input value={modalContent[updatedContent]?.discountAmount || ''} onChange={handleDiscountAmountChange} style={styles.modalRowItem} placeholder='Discount Amount' suffix="€" disabled={disabled} />
            <Input value={modalContent[updatedContent]?.minOrderAmountToApply || ''} onChange={handleOrderAmountChange} style={styles.modalRowItem} placeholder='Order Amount' suffix="€" disabled={disabled} />
            {updatedContent === UpdatedContent.details && <RangePickerCustom updatedContent={updatedContent} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />}
            <Select placeholder='Max Usage' onChange={handleMaxUsageChange} style={styles.modalRowItem} disabled={disabled}  value={modalContent[updatedContent]?.maxUsagePerUser}>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
            </Select>
        </div>
    )
}