import React, { useRef, useState } from 'react';
import { Table, Form, Input, Select, Tooltip, DatePicker, Checkbox, AutoComplete,  } from 'antd';
import { useParams } from 'react-router-dom';
import { exportCustomersData } from '../../api/customers';
import { exportProvidersData } from '../../api/providers';
import './pager.css';

import { useFetchList } from './useFetchList';
import { DownloadOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download';
import { getSortingConfigs } from '../../utils/helpers';
import moment from 'moment';

const { Option } = Select;

interface PagerProps { 
  headerComponentsInLeft?: any;
  headerComponentsInRight?: any;
  defaultComponent?: any;
  url: string;
  columns: Array<any>;
  rowClick: Function;
  configs?: Array<any>;
  forceReload?: number;
  onLabelChange?(labelIndex: number): void;
  notSearchable?: boolean;
  onCheckedDeletedCampaigns?(checked: boolean): void;
  checkedDeletedCampaigns?: boolean;
  nestedTablePage?: string;
  editingParams?: any;
  showHeader?: boolean;
  setBest?: Function;
  providerTypes?: any[];
}

export const Pager = ({ url, columns, rowClick, configs = [], forceReload = 0, headerComponentsInRight, headerComponentsInLeft, defaultComponent, onLabelChange, notSearchable, onCheckedDeletedCampaigns, checkedDeletedCampaigns, nestedTablePage, editingParams, showHeader, setBest, providerTypes }: PagerProps) => {
  const params: {configIndex?: string, currentPage?: string} = useParams();
  const rangePicker = useRef<any>(null);
  const [ currentConfigIndex, setCurrentConfigIndex ] = useState(parseInt(params.configIndex || '0'));
  const { loading, data, setCurrentPage, setCurrentQuery, currentPage, setSortConfigs, workingLocations, setFilterConfigs, filterConfigs, currentQuery, setSearchingCity } = useFetchList(url, configs.length > 1 ? configs[currentConfigIndex] : configs[0], forceReload, parseInt( nestedTablePage || params.currentPage || '0'));
  const { pageable = {}, totalElements } = data;
  const hideSelect = configs[+currentConfigIndex]?.params?.hideSelect;
  let contentManagmentData: any = [];
  if (data.OTHER ||
        data.MISSING_ITEMS ||
        data.INAPPROPRIATE_BEHAVIOR ||
        data.REVIEW_SERVICE_AND_PAYMENT ||
        data.ABOUT_FLEXXI ||
        data.ABOUT_PROVIDER ||
        data.GENERAL ||
        data.BOOKING ||
        data.PAYMENT ||
        data.QUALITY_CONTROL ||
        data.FLEXXI_TEAM_APP ||
        data.SELF_EMPLOYMENT
  ) {
    const dataKeys = Object.keys(data);
    dataKeys.forEach(key => {
      contentManagmentData.push(...data[key])
    })
  } else if (data.length) {
    contentManagmentData.push(...data)
  }
  let editedTable = null
  if(editingParams?.index || editingParams?.index === 0) {
    editedTable = data.content.map((row: any,index: number) => {
      if(index !== editingParams.index){
        return row;
      }
      return editingParams.rowRecord
    })
  }
  if((url.includes('top-customers') || url.includes('top-providers')) && setBest){
    if (contentManagmentData?.length){
      setBest(contentManagmentData[0]);
      contentManagmentData?.splice(0,1);
    } else {
      setBest(null)
    }
  }

  const tableData = editedTable || data.content || contentManagmentData;

  let newColumns = columns;
  if(configs[currentConfigIndex] && configs[currentConfigIndex].params.removeIndex) {
    newColumns = columns.filter((_,index) => {
      return !configs[currentConfigIndex].params.removeIndex.includes(index);
    })
  }

  const handleLabelChange = (labelIndex: number) => {
    onLabelChange && onLabelChange(labelIndex);
    setCurrentPage(0);
    setCurrentConfigIndex(labelIndex);
    if(url.includes('providers') && configs[labelIndex].label !== 'Approved'){
      let newFilterConfigs = filterConfigs;
      delete newFilterConfigs.providerTypeId;
      setFilterConfigs({
        ...newFilterConfigs
      })
    }
  }

  const onRowClick = (record: any) => ({
    onClick: () => rowClick(record.id, currentConfigIndex, record, currentPage)
  })

  const exportData = async () => {
    if (url.includes('customers')) {
      const fileBlob = await exportCustomersData({...filterConfigs, searchKey: currentQuery});
      fileDownload(fileBlob, 'customers.csv')
    } else {
      const fileBlob = await exportProvidersData(configs[currentConfigIndex].params, {...filterConfigs, searchKey: currentQuery});
      fileDownload(fileBlob, 'providers.csv')
    }
  }

  const rowClassName = (record: any) => {
    if (record.user?.deactivated) {
      return 'deactivated-row'
    }
    const currentDate = moment(new Date()).format("YYYY/MM/DD");
    const endDate = moment(record?.details?.endDate || record?.inviterDetails?.endDate).format("YYYY/MM/DD");
    if (record.deleted && url.includes('promo-codes') ){
      return 'deleted-campaign-row'
    }
    if ((currentDate > endDate) && url.includes('promo-codes') ){
      return 'completed-campaign-row'
    }
    return ''
  }

  const handleTableChange = (_: any, _1: any, sorter: any) => {
    const sortingConfigs = getSortingConfigs(sorter); 
    setSortConfigs(sortingConfigs);
  }

  const handleWorkingLocationChange = (workingPlaceCity: string) => {
    setSearchingCity(workingPlaceCity)
  }

  const handleProviderTypeChange = (providerTypeId: string) => {
    if (!providerTypeId) {
      let newFilterConfigs = filterConfigs;
      delete newFilterConfigs.providerTypeId;
      setFilterConfigs({
        ...newFilterConfigs
      })
    } else {
      setFilterConfigs({
        ...filterConfigs,
        providerTypeId,
      })
    }
  }

  const handleRangePickerChange = (dateRange: any) => {
    rangePicker?.current?.blur();
    if (!dateRange) {
      const newFilterConfigs = filterConfigs;
      delete newFilterConfigs.createdDateFrom;
      delete newFilterConfigs.createdDateTo;
      setFilterConfigs({
        ...newFilterConfigs
      })
    } else {
      setFilterConfigs({
        ...filterConfigs,
        createdDateFrom: dateRange[0].format('YYYY-MM-DD'),
        createdDateTo: dateRange[1].format('YYYY-MM-DD'),
      })
    }
  }

  const handleSearchConfigs = (query:string)=>{
    setCurrentQuery(query);
    setCurrentPage(0);
  }

  const handleCheckBoxChange = (e: any) => {
    const checked = e.target.checked
    onCheckedDeletedCampaigns && onCheckedDeletedCampaigns(checked);
    setCurrentPage(0);
  }

  return (
    <>
      <Form layout="inline" style={{ marginBottom: 20, marginTop: 20 }}>
        {configs.length > 1 && !hideSelect &&
          <Form.Item style={{ display: 'flex', flexDirection: 'row' }}>
            <Select style={{ minWidth: 160 }} value={currentConfigIndex} onChange={handleLabelChange}>
              {configs.map((c, labelIndex) => (<Option key={c.label} value={labelIndex}>{c.label}</Option>))}
            </Select>
          </Form.Item>
        }
        {((url.includes('providers') && configs[currentConfigIndex].label !== 'Not Registered') && !url.includes('dashboard') || url.includes('orders')) && !url.includes('dashboard') &&
          <Form.Item style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', }}>
          <AutoComplete
              style={{ minWidth: 160 }}
              allowClear
              onChange={handleWorkingLocationChange}
              options={workingLocations}
          >
            <Input.Search size="middle" placeholder="Type a city" />
          </AutoComplete>
        </Form.Item>
        }
        {url.includes('providers') && configs[currentConfigIndex].label === 'Approved'  &&
          <Form.Item style={{ display: 'flex', flexDirection: 'row' }}>
            <Select allowClear placeholder='Provider Type' style={{ minWidth: 160 }} onChange={handleProviderTypeChange}>
                {providerTypes?.map((providerType) => (
                  <Option key={providerType.type} value={providerType.id}>{providerType.name}</Option>
                ))}
            </Select>
          </Form.Item>
        }
        {url.includes('orders') && !url.includes('dashboard') &&
          <Form.Item style={{ display: 'flex', flexDirection: 'row' }}>
            <DatePicker.RangePicker ref={rangePicker} onChange={handleRangePickerChange} />
          </Form.Item>
        }
        {configs[currentConfigIndex]?.params.exportable && 
          <Form.Item>
            <Tooltip title='Export Data'>
              <DownloadOutlined style={{ fontSize: 22 }} onClick={exportData} />
            </Tooltip>
          </Form.Item>
        }
        {headerComponentsInLeft}
        {url.includes('promo-codes') && <Checkbox onChange={handleCheckBoxChange} checked={checkedDeletedCampaigns as boolean}>Including Deleted Campaigns</Checkbox>}
        {!notSearchable &&
        <Form.Item style={{ flex: 1 }}>
          <Input.Search
            placeholder="input search text"
            enterButton="Search"
            onSearch={handleSearchConfigs}
          />
        </Form.Item>}
        {headerComponentsInRight}
      </Form>
      {(defaultComponent && !tableData.length) ?
        defaultComponent :
        <Table
            showHeader={showHeader}
            style={{cursor: 'default'}}
            key={currentConfigIndex}
            rowClassName={rowClassName}
            loading={loading}
            onRow={onRowClick}
            columns={newColumns}
            dataSource={tableData}
            onChange={handleTableChange}
            pagination={!url.includes('dashboard')?
           {
              defaultCurrent: (currentPage + 1),
              current: (currentPage + 1),
              pageSize: pageable.pageSize,
              total: totalElements,
              onChange: page => setCurrentPage(page - 1),
            } : false}
            rowKey="id"
            scroll={{ x: "max-content" }}
        />
      }
    </>
  );
};
