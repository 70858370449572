import React, { useEffect, useState } from 'react';
import { Typography, Space, DatePicker, Radio } from 'antd';
import moment from "moment";
import { measureTextWidth, Pie } from '@ant-design/plots';
import './card.css';
import { getTopServices } from '../../api/dashboard';
import servicesDonat from '../../icons/servicesDonat.png';
const { Text } = Typography;
const { RangePicker } = DatePicker;

export const TopServices: React.FC = () => {
    const [period, setPeriod] = useState<any>({
        createdDateFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        createdDateTo: moment().format('YYYY-MM-DD'),
    })
    const [topServices,setTopServices] = useState<any[]>([])
    const [type, setType] = useState<string>('SERVICE_TYPE')
    const [totalCompletedOrders, setTotalCompletedOrders] = useState<string>()

    useEffect(()=>{
        getTopServices({...period, type})
            .then((data: any)=>{
                setTopServices(data?.dashboardTopServices)
                setTotalCompletedOrders(data?.totalCompletedOrders)
            })
    }, [type, period])

    function renderStatistic(containerWidth:any, text:any, style:any) {
        const { width: textWidth, height: textHeight } = measureTextWidth(
            text,
            style
        );
        const R = containerWidth / 2;

        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(
                Math.sqrt(
                    Math.abs(
                        Math.pow(R, 2) /
                        (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
                    )
                ),
                1
            );
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
            scale < 1 ? 1 : "inherit"
        };">${text}</div>`;
    }
    const config = {
        appendPadding: 10,
        data: topServices,
        angleField: "ordersCount",
        colorField: "name",
        color:['#1890FF','#13C2C2', '#2FC25B', '#FACC14', '#F04864', '#FF6600'],
        radius: 1,
        innerRadius: 0.6,
        style: {
            height: 358,
        },
        meta: {
            ordersCount: {
                formatter: (( v: any) => `${v} orders ${ (v * 100 / topServices.reduce((acc: any, item: any) => (item.ordersCount + acc), 0)).toFixed(1)}%`)
            },

        },
        label: {
            type: "inner",
            offset: "-50%",
            style: {
                textAlign: "center",
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 17,
                fill: '#FFFFFF',
            },
            autoRotate: false,
            content: "{value}",
        },
        legend: {
            position: 'bottom' as any,
            offsetX:8,
            title: {
                text: '',
                spacing: 8,
            },
        },
        statistic: {
            content: {
                offsetY: 4,
                style: {
                    fontSize: '32px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    color: '#448EF7',
                },
                customHtml: (container:any, view:any, datum:any, data:any) => {
                    const { width } = container.getBoundingClientRect();
                    const text = `${totalCompletedOrders?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                    return renderStatistic(width, text, {});
                }
            },
            title: {
                offsetY: -4,
                style: {
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    whiteSpace: 'wrap',
                    color: '#A29DB0',

                },
                customHtml: (container: any) => {
                    const { width, height } = container.getBoundingClientRect();
                    const d = Math.sqrt(Math.pow(width, 2) + Math.pow(height / 2, 2));
                    const text = "Total completed orders";
                    return renderStatistic(d as any, text as any, {} as any);
                }
            },

        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };

    const handlePeriodChange = (period: any) => {
        period &&
        setPeriod({
            createdDateFrom: period[0]?.format('YYYY-MM-DD'),
            createdDateTo: period[1]?.format('YYYY-MM-DD'),
        })
    }
    const handleTypeChange = (event: any) => {
        setType(event.target.value)
    }

    return(
        <>
            <Space className={'headerCard'} style={{ marginBottom: 32 }}>
                <Text className={'titleText'}>Top services</Text>
                <Radio.Group defaultValue="SERVICE_TYPE" value={type} onChange={handleTypeChange} buttonStyle="solid">
                    <Radio.Button value='SERVICE_TYPE'  key='SERVICE_TYPE' className={type === 'SERVICE_TYPE' ? 'selectedButton' : 'passiveButton'}>Services</Radio.Button>
                    <Radio.Button value='PROVIDER_TYPE' key='PROVIDER_TYPE' className={type === 'PROVIDER_TYPE' ? 'selectedButton' : 'passiveButton'}>Provider type</Radio.Button>
                </Radio.Group>
                <RangePicker
                    value={period?.createdDateFrom ? [moment(period?.createdDateFrom), moment(period?.createdDateTo)] : null}
                    onChange={handlePeriodChange}
                />
            </Space>
            {
                !!topServices?.length?
                    <Pie
                       {...config}
                        onReady={(plot) => {
                          plot.chart.removeInteraction('legend-filter');
                        }}
                    />
                    : <div className={'chartImgWrapper'}><img src={servicesDonat} alt={'ServicesDonat'}/></div>
            }
        </>
    )
}