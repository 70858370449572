import { useState, useEffect } from 'react';

import {
  fetchCustomerDetails,
  fetchCustomerPaymentMethods,
} from '../../api/customers';

export const useCustomerDetails = (id: string, forceLoad: number) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({ customer: {}, paymentMethods: {} });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchCustomerDetails(id),
      fetchCustomerPaymentMethods(id),
    ])
      .then(([customer = {}, paymentMethods = {}]) => {
          setData({
            customer,
            paymentMethods,
          });
        setLoading(false);
      });
  }, [id, forceLoad]);

  return { loading, data };
};
