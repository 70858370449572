
import { useState, useEffect } from 'react';

import {
    fetchTicketDetails,
} from '../../api/tickets';

export const useTicketDetails = (id: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    setLoading(true);
    fetchTicketDetails(id)
      .then((ticket: any) => {
        setData(ticket);
        setLoading(false);
      });
  }, [id]);

  return { loading, data };
};
