import { Avatar, Card, Descriptions } from 'antd';
import React, { CSSProperties } from 'react';
import { defaultImage, inviteFriendsColumns } from '../../pages/providers/Provider';
import { Link } from 'react-router-dom';
import selfRegisteredUser from '../../icons/selfRegisteredUser.svg';
import { Pager } from '../pager/Pager';
import { inviteFriendsUrl } from '../../pages/clients/Client';

export const InviteFriends = ({ inviteFriendsConfigs, data, handleResetTab }: { inviteFriendsConfigs: any, data: any, handleResetTab: any }) => {
    return (
        <>
            <Card className='inviterInfoCard'>
                <Descriptions title="Invited by" size="small" column={3} className='inviterInfo'>
                    {data?.customer?.referral ?
                        <>
                            <Descriptions.Item contentStyle={styles.inviterInfoItem as CSSProperties} >
                                <Avatar src={data?.customer?.referral?.profileUrl || defaultImage} className='inviterImage' />
                            </Descriptions.Item>
                            <Descriptions.Item contentStyle={styles.inviterInfoItem as CSSProperties}>
                                <span className='title'>Full name</span>
                                <Link className='link'
                                      to= {data?.customer?.referral?.userType === 'CLIENT' ?
                                          `/clients/${data?.customer?.referral?.id}/0` :
                                          `/providers/${data?.customer?.referral?.id}/0/0`}
                                      onClick={handleResetTab}
                                >
                                    {data?.customer?.referral?.fullName}
                                </Link>
                            </Descriptions.Item>
                            <Descriptions.Item contentStyle={styles.inviterInfoItem as CSSProperties}>
                                <span className='title'>User Type</span>
                                <span style={{ borderBottom: '1px solid' }}>{`${data?.customer?.referral?.userType?.slice(0, 1)}${data?.customer?.referral?.userType?.slice(1).toLowerCase()}`}</span>
                            </Descriptions.Item>
                        </> :
                        <>
                            <Descriptions.Item contentStyle={styles.selfRegisteredInfoItem as CSSProperties} >
                                <Avatar src={selfRegisteredUser} shape='square'  />
                                <span className='selfRegisteredUser'>Self registered user</span>
                            </Descriptions.Item>
                        </>}
                </Descriptions>
            </Card>
            <Pager configs={inviteFriendsConfigs} url={inviteFriendsUrl} columns={inviteFriendsColumns} rowClick={() => null} notSearchable={true} nestedTablePage={'0'} />
        </>

    )
}

const styles = {
    warningIcon: {
        marginLeft: 10,
        color: '#ee7080',
        fontSize: 20
    },
    inviterInfoItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    selfRegisteredInfoItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}