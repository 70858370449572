import React, { useContext, useState } from 'react';
import { Layout, Select, Form, Button, Modal, Space, Input, notification } from 'antd';

import { InnerLayout } from '../../../components/InnerLayout';
import { Pager } from '../../../components/pager/Pager';
import { ActiveFAQ, FAQType } from './ActiveFAQ';
import { editFAQ, createFAQ } from '../../../api/faqs';
import { isUserRoleMarketing } from '../../../utils/helpers';
import { MainContext } from '../../../App';

const { Option } = Select;
const { TextArea} = Input;

export const ContentManagerFAQ = ({ configs }: { configs?: any }) => {
    const modifiedConfigs = configs.map((config: any) => {
        return {
            ...config,
            params: {
                ...config.params,
                language: 'EN'
            }
        }
    });
    const [forceReload, setForceReload] = useState(0);
    const [activeFAQ, setActiveFAQ] = useState<FAQType | null>();
    const [language, setLanguage] = useState('EN');
    const [configsForCMFAQ, setConfigs] = useState(modifiedConfigs);
    const [isFAQModalOpened, toggleFAQModal] = useState(false);
    const [appType, setAppType] = useState('CLIENT')
    const { userAuthData } = useContext(MainContext);
    const url = `/help-center/faqs`;

    const columns = [
        {
            title: 'Type',
            dataIndex: 'serviceType',
            render(serviceType: string) {
                return getFAQTypeText(serviceType, language)
            }
        },
        {
            title: 'Question',
            dataIndex: 'question',
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
        },
    ];

    const handleLanguageChange = (language: string) => {
        const configs = configsForCMFAQ.map((config: any) => {
            return {
                ...config,
                params: {
                    ...config.params,
                    language
                }
            }
        });
        setLanguage(language);
        setConfigs(configs);
    }

    const rowClick = (id: string, _: any, record: any) => {
        setActiveFAQ(record);
    };

    const onUpdateItem = () => {
        setActiveFAQ(null);
        setForceReload(Math.random());
    }
    
    const handleNewFAQButtonClick = () => {
        toggleFAQModal(true);
    }

  const headerComponentsInLeft = [
    <Form.Item key='language'>
        <Select value={language} onChange={handleLanguageChange}>
            <Option value='EN'>English</Option>
            <Option value='DE'>German</Option>
        </Select>
    </Form.Item>
  ]

  const headerComponentsInRight = [
      <Button type='primary' onClick={handleNewFAQButtonClick} key='newFAQ' disabled={isUserRoleMarketing(userAuthData)}>New FAQ</Button>
  ]

  const handleLabelChange = (labelIndex: number) => {
        setAppType(configsForCMFAQ[labelIndex]?.params.type)
  }

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={styles.Layout}>
        <Pager
            headerComponentsInLeft={headerComponentsInLeft}
            headerComponentsInRight={headerComponentsInRight}
            url={url}
            columns={columns}
            configs={configsForCMFAQ}
            onLabelChange={handleLabelChange}
            rowClick={rowClick}
            forceReload={forceReload}
        />
      </Layout.Content>
      {activeFAQ &&
        <Layout.Sider
          width={400}
          style={{
            padding: '84px 30px 84px 0',
            backgroundColor: 'transparent',
            overflow: 'auto',
            height: '100vh',
          }}
        >
            {activeFAQ && <ActiveFAQ language={language} faq={activeFAQ} onUpdate={onUpdateItem} appType={appType} /> }
        </Layout.Sider>}
        {
            isFAQModalOpened && <Modal
                visible={isFAQModalOpened}
                title='New FAQ'
                footer={null}
                onCancel={() => toggleFAQModal(false)}
            >
                <FAQModalContent
                    onModalClose={() => toggleFAQModal(false)}
                    onUpdatePage={() => setForceReload(Math.random())}
                    appType={appType}
                />
            </Modal>
        }
    </InnerLayout>
  )
}

interface FAQModalContentProps {
    FAQData?: any;
    appType?: string;
    onModalClose: () => void;
    onUpdatePage: () => void;
}

export const FAQModalContent: React.FC<FAQModalContentProps> = ({ onModalClose, onUpdatePage, FAQData, appType }) => {
    const [language, setLanguage] = useState('EN');
    const [userType, setUserType] = useState(appType);
    const [faqType, setFaqType] = useState(
        FAQData?.serviceType ? FAQData?.serviceType : userType === 'PROVIDER' ? 'FLEXXI_TEAM_APP' : 'GENERAL');
    const [question, setQuestion] = useState(FAQData?.question ?? '');
    const [answer, setAnswer] = useState(FAQData?.answer ?? '');
    const [loading, setLoading] = useState(false);
    const handleQuestionChange = (event: any) => {
        setQuestion(event.currentTarget.value)
    }

    const handleAnswerChange = (event: any) => {
        setAnswer(event.currentTarget.value)
    }

    const showFieldsNotFilledError = () => {
        notification.error({
            message: 'All fields should be filled!',
            duration: 2
        })
    }
    const handleSubmitClick = async () => {
        setLoading(true);
        const faq = FAQData ? {
            serviceType: faqType,
            question,
            answer,
        } : {
            language,
            type: userType,
            serviceType: faqType,
            question,
            answer
        };
        if (!question || !answer) {
            setLoading(false);
            return showFieldsNotFilledError();
        }
        const submitResp = FAQData ? await editFAQ(faq, FAQData.id) : await createFAQ(faq);
        setLoading(false);
        if (submitResp.status !== 'ERROR') {
            onModalClose();
            setQuestion('');
            setAnswer('');
            onUpdatePage();
        }
    }
    const onChangeProvider = (type: string) => {
        setUserType(type);
        setFaqType(type === 'PROVIDER' ? 'FLEXXI_TEAM_APP' : 'GENERAL' )
    }

    const handleModalClose = () => {
        onModalClose();
        setQuestion('');
        setAnswer('');
    }

    return (
        <React.Fragment>
            {!FAQData &&<Space>
                <Select onChange={onChangeProvider} value={userType} style={styles.NewQestionModalOptionsSelect}>
                    <Option value='PROVIDER'>Provider</Option>
                    <Option value='CLIENT'>Client</Option>
                </Select>
                <Select onChange={setLanguage} value={language} style={styles.NewQestionModalOptionsSelect}>
                    <Option value='EN'>English</Option>
                    <Option value='DE'>German</Option>
                </Select>
            </Space>}
            {
                userType === 'PROVIDER' ?
                    <Select onChange={setFaqType} value={faqType} style={styles.NewQestionModalTypesSelect}>
                        <Option value={'FLEXXI_TEAM_APP'}>{getFAQTypeText('FLEXXI_TEAM_APP', language)}</Option>
                        <Option value={'SELF_EMPLOYMENT'}>{getFAQTypeText('SELF_EMPLOYMENT', language)}</Option>
                    </Select> :
                    <Select onChange={setFaqType} value={faqType} style={styles.NewQestionModalTypesSelect}>
                        <Option value={'GENERAL'}>{getFAQTypeText('GENERAL', language)}</Option>
                        <Option value={'BOOKING'}>{getFAQTypeText('BOOKING', language)}</Option>
                        <Option value={'PAYMENT'}>{getFAQTypeText('PAYMENT', language)}</Option>
                        <Option value={'QUALITY_CONTROL'}>{getFAQTypeText('QUALITY_CONTROL', language)}</Option>
                    </Select>
            }
            <TextArea style={styles.QuestionArea} value={question} onChange={handleQuestionChange} placeholder='Question' />
            <TextArea value={answer} onChange={handleAnswerChange} placeholder='Answer' />
            <Space style={styles.ButtonsContainer as React.CSSProperties}>
                <Button onClick={handleModalClose} style={styles.Button}>Cancel</Button>
                <Button loading={loading} onClick={handleSubmitClick} style={styles.Button} type='primary'>
                    {FAQData ? 'Save' : 'Create'}
                </Button>
            </Space>
        </React.Fragment>
    );
}

export const getFAQTypeText = (serviceType: string, language: string) => {
    switch(serviceType) {
        case 'GENERAL': {
            return language === 'EN' ? 'General' : 'Allgemein';
        }
        case 'BOOKING': {
            return language === 'EN' ? 'Booking' : 'Buchungen';
        }
        case 'PAYMENT': {
            return language === 'EN' ? 'Payment' : 'Zahlung';
        }
        case 'QUALITY_CONTROL': {
            return language === 'EN' ? 'Quality Control ' : 'Qualitätskontrolle';
        }
        case 'FLEXXI_TEAM_APP': {
            return language === 'EN' ? 'FLEXXI Team App' : 'FLEXXI Team App';
        }
        case 'SELF_EMPLOYMENT': {
            return language === 'EN' ? 'Self-Employment' : 'Selbstständigkeit';
        }
        default:
            return 'N/A'
    }
}


const styles = {
    Layout: {
        padding: '20px 30px',
        marginTop: 64,
    },
    ButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        margin: '16px 8px 0'
    },
    Button: {
        width: 90,
    },
    NewQestionModalTypesSelect: {
        width: '100%',
        margin: '16px 0'
    },
    NewQestionModalOptionsSelect : {
        minWidth: 145,
    },
    QuestionArea: {
        marginBottom: 16
    }
}
