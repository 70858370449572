import React from 'react';
import { InnerLayout } from '../../components/InnerLayout';
import { Card, Layout, } from 'antd';
import './dashboard.css';
import { TopOrders } from './TopOrders';
import { BestProviders } from './BestProviders';
import { BestClients } from './BestClients';
import { NewProviders } from './NewProviders';
import { NewClients } from './NewClients';
import { Payments } from './Payments';
import { TopServices } from './TopServices';

export const Dashboard: React.FC = () => {

    return(
        <InnerLayout>
            <Layout.Content className="site-layout" style={{ padding: '16px 30px', marginTop: 64 }}>
                <div className={'chartWrapper'}>
                    <Card>
                        <TopServices />
                    </Card>
                    <Card>
                        <Payments />
                    </Card>
                </div>
                <Card style={{ marginTop: 16 }}>
                    <TopOrders />
                </Card>
                <div className={'pagerWrapper'}>
                    <Card>
                        <BestProviders />
                    </Card>
                    <Card>
                        <BestClients />
                    </Card>
                </div>
                <div className={'pagerWrapper'}>
                    <Card>
                        <NewProviders />
                    </Card>
                    <Card>
                        <NewClients />
                    </Card>
                </div>
            </Layout.Content>
        </InnerLayout>
        )
}
