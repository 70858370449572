import React, { useContext, useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Card,
    Descriptions, notification, Switch, Input
} from 'antd';
import './respiteCare.css';
import view from '../../icons/view.svg';
import download from '../../icons/download.svg';
import { fetchRespiteCareCustomerDetail, updateRespiteCareCustomerData } from '../../api/customers';
import { isUserRoleMarketing } from '../../utils/helpers';
import { EuroOutlined } from '@ant-design/icons';
import { MainContext } from '../../App';
import { generatePdf } from '../../api/respiteCare';
const { Paragraph } = Typography;

export const RespiteCare = ({data}: {data: any}) => {
    const id = data.customer.id
    const { userAuthData } = useContext(MainContext);
    const [switchState, setSwitchState] = useState(false);
    const [respiteCareCustomer, setRespiteCareCustomer] = useState<null | any>(null);
    const [respiteCareMaxLimit, setRespiteCareMaxLimit] = useState('');
    const [limit, setLimit] = useState('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRespiteCareCustomerDetail(id)
            .then((resp: any) => {
                if(resp.status === 'ERROR'){
                    notification.error({
                        message: 'Respite Care not found!',
                        duration: 2
                    })
                } else {
                    setRespiteCareCustomer(resp)
                    setSwitchState(resp.enabled)
                    setRespiteCareMaxLimit(String(resp.totalAvailableAmount))
                    setLimit(String(resp.totalAvailableAmount))
                }
            })
    }, [])

    const handleSwitchChange = async (checked: boolean) => {
        setSwitchState(checked)
        const resp = await updateRespiteCareCustomerData(id, { enable: checked })
        if (resp.status === 'ERROR') {
            setSwitchState(!checked)
            notification.error({
                message: 'Respite Care not found!',
                duration: 2
            })
        }
    }

    const handleInputValueChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: maxLimit }= event.target;
        let numbers = /^[0-9]+$/;
        if ((maxLimit.match(numbers) || maxLimit === '') && (maxLimit === '0' || maxLimit.indexOf('0') !== 0)) {
            setLimit(maxLimit)
        }
    }

    const handleSaveMaxLimit = async () => {
        setLoading(true);
        const resp = await updateRespiteCareCustomerData(id, { amount: +limit })
        setLoading(false);
        if (resp.status === 'ERROR') {
            notification.error({
                message: 'Something went wrong!',
                duration: 2
            })
        } else {
            setRespiteCareMaxLimit(limit)
        }
    }

    const handleViewPdf =  async (url: string, ref: string) => {
        if (url) {
            window.open(url, '_blank')
        } else {
            const body = { ref }
            const resp = await generatePdf(body)
            if (resp.status === 'ERROR'){
                notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            } else {
                window.open(resp.pdfUrl, '_blank')
            }
        }
    }

    const getPdf = (url: string) => {
        fetch(url)
            .then((response) => (response.blob()))
            .then((blob: any) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `invoice.pdf`,
                );
                document.body.appendChild(link);
                link.click();
                link?.parentNode?.removeChild(link);
            })
            .catch(() => {
                notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            })
    }

    const handleDownloadPdf =  async (url: string, ref: string) => {
        if(!url){
            const body = { ref }
            const resp = await generatePdf(body)
            if (resp.status === 'ERROR'){
                notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            } else {
                getPdf(resp.pdfUrl)
            }
        } else {
            getPdf(url)
        }
    }

    return (
        <Card>
            <Descriptions size="small" column={1} style={{ marginTop: 20 }}>
                <Descriptions.Item>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <Switch onChange={handleSwitchChange}
                                checked={switchState}
                                disabled={isUserRoleMarketing(userAuthData)}
                        />
                        <Paragraph style={{ margin: 0, marginLeft: 15}}>{'Respite Care Limit'}</Paragraph>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item>
                    <div>
                        <Paragraph style={{ margin: 0 }}>Max Limit</Paragraph>
                        <Input
                            onChange={handleInputValueChange}
                            value={limit}
                            prefix={<EuroOutlined />}
                            disabled={isUserRoleMarketing(userAuthData) || !switchState}
                        />

                        <Button
                            loading={loading}
                             onClick={handleSaveMaxLimit}
                            type='primary' style={{ marginTop: 10 }}
                            disabled={
                            isUserRoleMarketing(userAuthData) || (limit === respiteCareMaxLimit) || (!+limit && !+respiteCareMaxLimit)
                            }
                        >
                            Save
                        </Button>
                    </div>
                </Descriptions.Item>
            </Descriptions>
            {respiteCareCustomer &&
                <Descriptions size="small" column={11} layout='vertical' className='description'>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Name">
                        {respiteCareCustomer.reimbursement?.firstName}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Last Name">
                        {respiteCareCustomer.reimbursement?.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Birthday">
                        {respiteCareCustomer.reimbursement?.birthDate}
                    </Descriptions.Item>
                    <Descriptions.Item  labelStyle={{ fontWeight: 'bold' }} label="Email">
                        {respiteCareCustomer.reimbursement?.email}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Phone number">
                        {respiteCareCustomer.reimbursement?.phoneNumber}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Address">
                        {respiteCareCustomer.reimbursement?.address}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="House number">
                        {respiteCareCustomer.reimbursement?.houseNumber}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Zip Code">
                        {respiteCareCustomer.reimbursement?.zipCode}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="City">
                        {respiteCareCustomer.reimbursement?.city}
                    </Descriptions.Item>
                    <Descriptions.Item style={{ width: '10%' }}>
                        <Button
                            className={'button'}
                            onClick={() => handleViewPdf(respiteCareCustomer.reimbursement?.pdfUrl, respiteCareCustomer.reimbursement?.reimbursementRef)}
                        >
                            <img src={view} style={{ marginRight: '8px' }} alt={'ViewIcon'} />
                            View pdf
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item style={{ width: '13%' }}>
                        <Button
                            className={'button'}
                            onClick={() => handleDownloadPdf(respiteCareCustomer.reimbursement?.pdfUrl, respiteCareCustomer.reimbursement?.reimbursementRef)}
                        >
                            <img src={download} style={{ marginRight: '8px' }} alt={'DownloadIcon'} />
                            Download pdf
                        </Button>
                    </Descriptions.Item>
                </Descriptions>
            }
        </Card>
    )
}
