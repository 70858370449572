import React, { createContext, useState } from 'react';
import './App.css';
import { AppRouter } from './AppRouter';
import { MainContextState, mainDefaultContext, UserAuthData } from './context/mainContext';

export const MainContext = createContext<MainContextState>(mainDefaultContext);


const App = () => {
  const [userAuthData, setData] = useState<UserAuthData | null>(mainDefaultContext.userAuthData);

  const setUserAuthData = (data: UserAuthData | null) => {
    setData(data);
  }

  return (
    <MainContext.Provider
      value={{
        userAuthData,
        setUserAuthData,
      }}
    >
      <AppRouter />
    </MainContext.Provider>
  )
}

export default App;
