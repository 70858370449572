import { useState, useEffect } from 'react';
import {
  fetchProviderDetails,
  fetchProviderPaymentMethods,
  fetchAvailableLanguages,
  fetchAvailableWorkPlaces,
  // fetchOldDocuments,
} from '../../api/providers';
import { getProviderTypes } from '../../api/servicePrices';

export const useProviderDetails = (id: string, forceReload: number) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({ provider: {}, paymentMethods: {}, allLanguages: [], availableWorkingPlaces: [], oldDocuments: [], providerTypes: [] });

  useEffect(() => {
    setLoading(true);

    Promise.all([
      fetchProviderDetails(id),
      fetchProviderPaymentMethods(id),
      fetchAvailableLanguages(),
      fetchAvailableWorkPlaces(),
      // fetchOldDocuments(id),
      getProviderTypes(),
    ])
      .then(([provider = {}, paymentMethods = {}, allLanguages = [], availableWorkingPlacesPayload = {content: []}, providerTypes=[]]) => {
        setData({
          provider,
          paymentMethods,
          allLanguages,
          availableWorkingPlaces: availableWorkingPlacesPayload.content,
          providerTypes,
        });
        setLoading(false);
      });
  }, [id, forceReload]);
  
  return { loading, data };
};
