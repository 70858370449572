import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { InnerLayout } from '../../components/InnerLayout';
import { Collapse, Layout, notification, Typography, Card, Descriptions, Button } from 'antd';
import { fetchReimbursement, generatePdf } from '../../api/respiteCare';
import { CaretRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import view from '../../icons/view.svg';
import download from '../../icons/download.svg';
import './option.css'

const { Text } = Typography;
const { Panel } = Collapse;

interface Params {
    configIndex: string;
    currentPage: string;
    id: string
}

export const Option = () => {
    const params = useParams<Params>();
    const [reimbursementData, setReimbursementData] = useState<null | any>(null)

    useEffect(() => {
        fetchReimbursement(params.id)
            .then((resp: any) => {
                setReimbursementData(resp)
                if(resp.status === 'ERROR'){
                    notification.error({
                        message: 'REIMBURSEMENT_ACCOUNT_NOT_FOUND!',
                        duration: 2
                    })
                }
            })
            .catch(() => {
                notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            })

    }, [])

    const handleViewPdf = async (url: string, ref: string) => {
        if (url) {
            window.open(url, '_blank')
        } else {
            const body = { ref }
            const resp = await generatePdf(body)
            if (resp.status === 'ERROR'){
                notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            } else {
                window.open(resp.pdfUrl, '_blank')
            }
        }
    }

    const getPdf = (url: string) => {
        fetch(url)
            .then((response) => (response.blob()))
            .then((blob: any) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `invoice.pdf`,
                );
                document.body.appendChild(link);
                link.click();
                link?.parentNode?.removeChild(link);
            })
            .catch(() => {
                notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            })
    }

    const handleDownloadPdf = async (url: string, ref: string) => {
        if(!url){
            const body = { ref }
            const resp = await generatePdf(body)
            if (resp.status === 'ERROR'){
                notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            } else {
                getPdf(resp.pdfUrl)
            }
        } else {
            getPdf(url)
        }
    }

    return (
        <InnerLayout>
            {reimbursementData?.data?.length &&
                <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 , display: 'flex', flexDirection: 'column'}}>
                    <Link className="to-back-container" to={`/respiteCare/${params.configIndex}/${params.currentPage}`}>
                        <ArrowLeftOutlined />
                        <span className="to-back-text">Back to all users</span>
                    </Link>
                    <div className="infoWrapper">
                        <Text className={'name'}>{reimbursementData?.accountHolderName}</Text>
                        <Text className={'email'}>{reimbursementData?.accountHolderEmail}</Text>
                    </div>
                    <Card>
                        {reimbursementData?.data?.map((item: any, index: number) => {
                                return (
                                    <Collapse
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
                                        className={'collapse'}
                                        expandIconPosition={'right'}
                                        key={index}
                                    >
                                        <Panel header={item.recipientName} key="1">
                                            {item.forms.map((form: any) => {
                                            return(
                                                <Descriptions size="small" column={11} layout="vertical" className={'descriptionWrapper'}>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Name">
                                                        {form.firstName}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Last Name">
                                                        {form.lastName}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Birthday">
                                                        {form.birthDate}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Email">
                                                        {form.email}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Phone number">
                                                        {form.phoneNumber}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Address">
                                                        {form.address}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="House number">
                                                        {form.houseNumber}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Zip Code">
                                                        {form.zipCode}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="City">
                                                        {form.city}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item style={{ width: '10%' }}>
                                                        <Button className={'button'} onClick={() => handleViewPdf(form.pdfUrl, form.reimbursementRef)}>
                                                            <img src={view} style={{ marginRight: '8px' }} alt={'ViewIcon'} />
                                                            View pdf
                                                        </Button>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item style={{ width: '13%' }}>
                                                        <Button className={'button'}  onClick={() => handleDownloadPdf(form.pdfUrl, form.reimbursementRef )}>
                                                            <img src={download} style={{ marginRight: '8px' }} alt={'DownloadIcon'} />
                                                            Download pdf
                                                        </Button>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            )
                                            })}
                                        </Panel>
                                    </Collapse>
                                )
                            })
                        }
                    </Card>
                </Layout.Content>
            }
        </InnerLayout>
    )
}