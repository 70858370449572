import { useState } from 'react';

import { deleteOrder } from '../../api/orders';

export const useOrderActions = (id: string, onChange: any) => {
  const [loading, setLoading] = useState(false);

  const remove = () => {
    setLoading(true);
    deleteOrder(id)
      .then(onChange)
      .catch(() => setLoading(false));
  };

  return { loading, remove };
};
