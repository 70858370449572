import React from 'react';
import {
  Layout,
} from 'antd';

import { MainMenu } from './MainMenu';

export const InnerLayout: React.FC<{}> = ({ children }) => (
  <Layout>
    <Layout.Header style={{ position: 'fixed', zIndex: 2, width: '100%' }}>
      <MainMenu />
    </Layout.Header>
    <Layout>
      {children}
    </Layout>
  </Layout>
);
