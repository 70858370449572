
import { useState, useEffect } from 'react';

import {
  fetchOrderDetails,
} from '../../api/orders';

export const useOrderDetails = (id: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    setLoading(true);
    fetchOrderDetails(id)
      .then((order: any) => {
        setData(order);
        setLoading(false);
      });
  }, [id]);

  return { loading, data };
};
