import { authorisedRequest } from './authorisedRequest';

export const fetchOrderDetails = (id: string) => {
  return authorisedRequest(`/admin/orders/${id}`);
}

export const deleteOrder = (id: string) => {
  return authorisedRequest(`/admin/orders/${id}`, 'DELETE', { comment: "comment" });
}

export const fetchOrderInvoice = (orderId: string, language: string) => {
  return authorisedRequest(`/order-invoices/${orderId}/generate?type=ADMIN&language=${language}` );
}