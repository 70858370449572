import React, { useState } from 'react';
import { Select } from "antd"; 
import { CampaignSubType, PromoCodeRequest, PromoCodeType, UpdatedContent } from './campaingTypes';
import { styles } from "./styles";
import { InviteFriendCampaingContent } from "./InviteFriendCampaingContent";
import { RangePickerCustom } from './RangePickerCustom';

const { Option } = Select;

interface InviteFriendCampaingProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    providerTypes: any[];
    disabled: boolean;
}

export const InviteFriendCampaing: React.FC<InviteFriendCampaingProps> = ({ modalContent, setModalContent, providerTypes, disabled }) => {
    const defaultCampaignSubType = modalContent?.type?.includes('N2')? CampaignSubType.REGISTRATION_REFERRAL : CampaignSubType.Completed_Order_Referral
    // const [campaignSubType, setCampaignSubType] = useState(CampaignSubType.REGISTRATION_REFERRAL);
    const [campaignSubType, setCampaignSubType] = useState(defaultCampaignSubType);

    // useEffect(() => {
    //     setCampaignSubType(CampaignSubType.REGISTRATION_REFERRAL)
    // }, []);

    const handlePromoCodeTypeChange = (type: PromoCodeType) => {
        setModalContent({
            type,
            [UpdatedContent.inviterDetails]: {},
            [UpdatedContent.receiverDetails]: {},
            providerTypeIds:[],
        })
    }

    const handleCampaignSubTypeChange = (campaignSubtype: CampaignSubType) =>{
        setCampaignSubType(campaignSubtype)
        setModalContent({
            type: campaignSubtype === CampaignSubType.REGISTRATION_REFERRAL ? PromoCodeType.INVITE_CLIENT_TO_CLIENT_N2 : PromoCodeType.INVITE_CLIENT_TO_CLIENT,
            [UpdatedContent.inviterDetails]: {},
            [UpdatedContent.receiverDetails]: {},
            providerTypeIds:[],
        })
    }

    const getCapmaingByPromoCodeType = (campaignSubType: CampaignSubType) => {
        return (
            <>
                { campaignSubType === CampaignSubType.REGISTRATION_REFERRAL ?
                    <Select onChange={handlePromoCodeTypeChange} value={modalContent.type} style={styles.modalRowItem} disabled={disabled}>
                        <Option value={PromoCodeType.INVITE_CLIENT_TO_CLIENT_N2}>Client to Client</Option>
                        <Option value={PromoCodeType.INVITE_CLIENT_TO_PROVIDER_N2}>Client to Provider</Option>
                        <Option value={PromoCodeType.INVITE_PROVIDER_TO_CLIENT_N2}>Provider to Client</Option>
                        <Option value={PromoCodeType.INVITE_PROVIDER_TO_PROVIDER_N2}>Provider to Provider</Option>
                    </Select> :
                    <Select onChange={handlePromoCodeTypeChange} value={modalContent.type} style={styles.modalRowItem} disabled={disabled}>
                        <Option value={PromoCodeType.INVITE_CLIENT_TO_CLIENT}>Client to Client</Option>
                        <Option value={PromoCodeType.INVITE_CLIENT_TO_PROVIDER}>Client to Provider</Option>
                        <Option value={PromoCodeType.INVITE_PROVIDER_TO_CLIENT}>Provider to Client</Option>
                        <Option value={PromoCodeType.INVITE_PROVIDER_TO_PROVIDER}>Provider to Provider</Option>
                    </Select>

                }
            </>
        )
    }

    return (
        <div>
            <Select onChange={handleCampaignSubTypeChange} value={campaignSubType} style={styles.modalRowItem}  disabled={disabled} >
                <Option value={CampaignSubType.REGISTRATION_REFERRAL}>Registration Referral</Option>
                <Option value={CampaignSubType.Completed_Order_Referral}>Completed Order Referral</Option>
            </Select>
            <RangePickerCustom updatedContent={[UpdatedContent.inviterDetails, UpdatedContent.receiverDetails]} modalContent={modalContent} setModalContent={setModalContent}  disabled={disabled}/>
            {getCapmaingByPromoCodeType(campaignSubType)}
            <InviteFriendCampaingContent modalContent={modalContent} setModalContent={setModalContent} providerTypes={providerTypes} disabled={disabled} />
        </div> 
    )
}



