import { authorisedRequest } from './authorisedRequest';
import { EditPayload, ModalContent } from '../pages/settings/Releases/Releases';

export const editRelease = (payload: EditPayload, versionId: string) => {
    return authorisedRequest(`/app-versions/${versionId}`, 'PUT', payload);
}

export const addRelease = (payload: ModalContent) => {
    return authorisedRequest(`/app-versions`, 'POST', payload);
}


