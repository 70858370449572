import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Layout, Modal, notification } from "antd";
import React, { useState, useMemo, useContext } from "react";
import { createNewCampaing, createNewInviteFriendCampaing, deleteCampaing } from "../../api/campaings";
import { InnerLayout } from "../../components/InnerLayout";
import { Pager } from "../../components/pager/Pager";
import { formatDate, isUserRoleMarketing } from '../../utils/helpers';
import { CampaingnModalContent } from "./CampaingModalContent";
import {
    PromoCodeDetails,
    CampaingType,
    PromoCodeType,
    PromoCodeRequest,
    PromoCodeSubType,
    UpdatedContent
} from './campaingTypes';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { MainContext } from '../../App';


const url = '/promo-codes';

export enum ModalTitle {
    newCampaing = 'New Campaign',
    deleteCampaing = 'Delete Campaign',
    viewCampaign = 'Campaign',
}

export const Campaings = () => {
    const columns = [
        {
            title: 'Promo code name',
            dataIndex: 'name',
        },
        {
            title: 'Active dates',
            dataIndex: 'details',
            render: (details: PromoCodeDetails) => {
                return `${formatDate(details?.startDate, 'DD.MM.YYYY')}-${formatDate(details?.endDate, 'DD.MM.YYYY')}`
            }
        },
        {
            title: 'Active dates',
            dataIndex: 'inviterDetails',
            render: (inviterDetails: PromoCodeDetails) => {
                return `${formatDate(inviterDetails?.startDate, 'DD.MM.YYYY')}-${formatDate(inviterDetails?.endDate, 'DD.MM.YYYY')}`
            }
        },
        {
            title: 'Campaign type',
            dataIndex: 'type',
        },
        {
            title: 'Campaign subtype',
            render: (record: any) => {
                return record.type.includes('N2') ? 'Registration Referral' : 'Completed Order referral'
            }
        },
        {
            title: 'Inviter Campaign Type',
            dataIndex: 'inviterDetails',
            render: (inviterDetails: PromoCodeDetails) => {
                return inviterDetails?.subType;
            }
        },
        {
            title: 'Inviter Bonus Value',
            dataIndex: 'inviterDetails',
            render: (inviterDetails: PromoCodeDetails) => {
                const { amount, unit }= getAmounAndUnit(inviterDetails?.bonusAmount, inviterDetails?.freeMinutes, inviterDetails?.discountAmount, inviterDetails?.discountPercent);
                return amount ? `${amount} ${unit}` : 'N/A';
            }
        },
        {
            title: 'Inviter Max Usage',
            dataIndex: 'inviterDetails',
            render: (inviterDetails: PromoCodeDetails) => {
                return inviterDetails?.maxUsagePerUser || 'N/A'
            }
        },
        {
            title: 'Receiver Campaign Type',
            dataIndex: 'receiverDetails',
            render: (receiverDetails: PromoCodeDetails) => {
                return receiverDetails?.subType;
            }
        },
        {
            title: 'Receiver Bonus Value',
            dataIndex: 'receiverDetails',
            render: (receiverDetails: PromoCodeDetails) => {
                const { amount, unit }= getAmounAndUnit(receiverDetails?.bonusAmount, receiverDetails?.freeMinutes, receiverDetails?.discountAmount, receiverDetails?.discountPercent);
                return amount ? `${amount} ${unit}` : 'N/A';
            }
        },
        {
            title: 'Receiver Max Usage',
            dataIndex: 'receiverDetails',
            render: (receiverDetails: PromoCodeDetails) => {
                return receiverDetails?.maxUsagePerUser || 'N/A'
            }
        },
        {
            title: 'Free minutes/amount/bonus',
            dataIndex: 'details',
            render: (details: PromoCodeDetails) => {
                const { amount, unit }= getAmounAndUnit(details?.bonusAmount,details?.freeMinutes, details?.discountAmount, details?.discountPercent);
                return `${amount} ${unit}`;
            }
        },
        {
            title: 'Max usage',
            dataIndex: 'details',
            render: (details: PromoCodeDetails) => {
                return details?.maxUsagePerUser || 'N/A'
            }
        },
        {
            title: 'Status',
            dataIndex: 'details',
            render: (details: PromoCodeDetails, record: any) => {
                const currentDate = moment(new Date()).format("YYYY/MM/DD");
                const endDate = moment(details?.endDate).format("YYYY/MM/DD");
                if (record.deleted){
                    return 'Deleted'
                }
                if (currentDate > endDate){
                    return 'Completed'
                }
                return ''
            }
        },
        {
            title: 'Status',
            dataIndex: 'inviterDetails',
            render: (inviterDetails: PromoCodeDetails, record: any) => {
                const currentDate = moment(new Date()).format("YYYY/MM/DD");
                const endDate = moment(inviterDetails?.endDate).format("YYYY/MM/DD");
                if (record.deleted){
                    return 'Deleted'
                }
                if (currentDate > endDate){
                    return 'Completed'
                }
                return ''
            }
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id: string, record: any) => (
                <>
                    {!record.deleted && <Button
                        onClick={(e:any) => handleDeleteClick(e, id)}
                        className='delete-button'
                        type='link'
                        icon={<DeleteOutlined />}
                        disabled={isUserRoleMarketing(userAuthData)}
                    />}
                    <Button
                        onClick={(e:any) => handleViewClick(e, record)}
                        type='link'
                        icon={<EyeOutlined />}
                    />
                </>
            )
        }
    ]
    const history = useHistory();
    const params: {configIndex?: string, currentPage?: string} = useParams();
    const { userAuthData } = useContext(MainContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState<ModalTitle>(ModalTitle.deleteCampaing);
    const [modalContent, setModalContent] = useState<PromoCodeRequest>({});
    const [loading, setLoading] = useState(false);
    const [campaingType, setCampaingType] = useState(CampaingType.SimpleCampaing);
    const [checkedDeletedCampaigns, setCheckedDeletedCampaigns] = useState<boolean>(false);
    const [forceLoad, setForceLoad] = useState(0);

    const configs = useMemo(() => [
            {
                label: 'Campaign',
                params: {
                    removeIndex: [2,4,5,6,7,8,9,10,14],
                    includeDeleted: checkedDeletedCampaigns
                },
            },
           {
            label: 'Invite Friend',
                params: {
                    url: '/promo-codes/invite-friend',
                    removeIndex: [1,11,12,13],
                    includeDeleted: checkedDeletedCampaigns
                },
           },
           {
            label: 'Unique Promo',
                params: {
                   removeIndex: [2,4,5,6,7,8,9,10,14],
                   includeDeleted: checkedDeletedCampaigns,
                   individual: true,
               },
           },
   ], [checkedDeletedCampaigns])
    const [labelType, setLabelType] = useState( configs[params.configIndex as any].label as any || CampaingType.SimpleCampaing);


    const handleDeleteClick = (e: any, id: string) => {
        e.stopPropagation();
        setModalTitle(ModalTitle.deleteCampaing);
        setModalContent({ id });
        setModalVisible(true);
    }

    const handleCancel = () => {
        setModalVisible(false);
        setLoading(false);
    }

    const handleCampaingDelete = async () => {
        setLoading(true);
        const resp =  await deleteCampaing(modalContent.id!);
        if (resp.status === 'ERROR') {
            notification.error({
                message: 'Something went wrong!',
                duration: 2,
            })
        }
        setForceLoad(Math.random());
        setLoading(false);
        setModalVisible(false);
    }

    const showFieldsNotFilledError = () => {
        notification.error({
            message: 'All fields should be filled!',
            duration: 2
        })
    }

    const handleCampaingCreate = async () => {
        setLoading(true);
        if (!modalContent.name) {
            setLoading(false);
            return showFieldsNotFilledError();
        }
        if (campaingType === CampaingType.SimpleCampaing || campaingType === CampaingType.UniquePromo) {
            if (modalContent.type === PromoCodeType.ORDER_FREE_TIME || modalContent.details?.subType === PromoCodeSubType.CLIENT_ORDER_FREE_TIME) {
                if (!modalContent.details?.freeMinutes 
                    || !modalContent.details?.minOrderMinutesToApply
                    || !modalContent.details?.startDate
                    || !modalContent.details?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();
                }
            } else if (modalContent.type === PromoCodeType.ORDER_AMOUNT_DISCOUNT || modalContent.details?.subType === PromoCodeSubType.CLIENT_ORDER_AMOUNT_DISCOUNT) {
                if (!modalContent.details?.discountAmount 
                    || !modalContent.details?.minOrderAmountToApply
                    || !modalContent.details?.startDate
                    || !modalContent.details?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();

                }
            } else if (modalContent.type === PromoCodeType.ORDER_DISCOUNT_PERCENT || modalContent.details?.subType === PromoCodeSubType.CLIENT_ORDER_DISCOUNT_PERCENT) {
                if (!modalContent.details?.discountPercent 
                    || !modalContent.details?.minOrderAmountToApply
                    || !modalContent.details?.startDate
                    || !modalContent.details?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();
                }
            } else if (modalContent.type === PromoCodeType.SIGNUP_PROVIDER) {
                if (!modalContent.details?.bonusAmount || !modalContent.details?.startDate || !modalContent?.providerTypeIds?.length) {
                        setLoading(false);
                        return showFieldsNotFilledError();
                }
            } else if (modalContent.type === PromoCodeType.ORDER_PROVIDER_BONUS) {
                if (!modalContent.details?.startDate || !modalContent.details?.bonusAmount || !modalContent.details?.orderQuantity || !modalContent?.providerTypeIds?.length) {
                    setLoading(false);
                    return showFieldsNotFilledError();
                }
            }
            const resp = await createNewCampaing(modalContent);
            if (resp.status === 'ERROR') {
                setLoading(false);
                if (resp.result?.errorCode === "PROMO_CODE_ALREADY_EXISTS") {
                   return notification.error({
                        message: 'Promo code already exists!',
                        duration: 2
                    })
                }
                return notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            } else {
                setForceLoad(Math.random());
            }
        } else {
            if (modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_PROVIDER || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_PROVIDER_N2 ) {
                if(!modalContent.inviterDetails?.subType  || !modalContent.receiverDetails?.subType || !modalContent?.providerTypeIds?.length) {
                    setLoading(false);
                    return showFieldsNotFilledError();
                }
            } else if (modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_CLIENT || modalContent.type === PromoCodeType.INVITE_PROVIDER_TO_CLIENT_N2) {
                if (!modalContent.inviterDetails?.subType || !modalContent.receiverDetails?.subType) {
                    setLoading(false);
                    return showFieldsNotFilledError();
                }
            } else if (modalContent.type === PromoCodeType.INVITE_CLIENT_TO_PROVIDER || modalContent.type === PromoCodeType.INVITE_CLIENT_TO_PROVIDER_N2) {
                if (!modalContent.receiverDetails?.subType || !modalContent.inviterDetails?.subType || !modalContent?.providerTypeIds?.length) {
                    setLoading(false);
                    return showFieldsNotFilledError();
                }
            } else if (modalContent.type === PromoCodeType.INVITE_CLIENT_TO_CLIENT || modalContent.type === PromoCodeType.INVITE_CLIENT_TO_CLIENT_N2) {
                if (!modalContent.inviterDetails?.subType || !modalContent.receiverDetails?.subType) {
                    setLoading(false);
                    return showFieldsNotFilledError();
                }
            }
            if (modalContent.inviterDetails?.subType === PromoCodeSubType.CLIENT_ORDER_FREE_TIME) {
                if (!modalContent.inviterDetails?.freeMinutes 
                    || !modalContent.inviterDetails?.minOrderMinutesToApply
                    || !modalContent.inviterDetails?.startDate
                    || !modalContent.inviterDetails?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();
                }
            } else if (modalContent.inviterDetails?.subType === PromoCodeSubType.CLIENT_ORDER_AMOUNT_DISCOUNT) {
                if (!modalContent.inviterDetails?.discountAmount 
                    || !modalContent.inviterDetails?.minOrderAmountToApply
                    || !modalContent.inviterDetails?.startDate
                    || !modalContent.inviterDetails?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();

                }
            } else if (modalContent.inviterDetails?.subType === PromoCodeSubType.CLIENT_ORDER_DISCOUNT_PERCENT) {
                if (!modalContent.inviterDetails?.discountPercent 
                    || !modalContent.inviterDetails?.minOrderAmountToApply
                    || !modalContent.inviterDetails?.startDate
                    || !modalContent.inviterDetails?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();
                }
            }

            if (modalContent.receiverDetails?.subType === PromoCodeSubType.CLIENT_ORDER_FREE_TIME) {
                if (!modalContent.receiverDetails?.freeMinutes 
                    || !modalContent.receiverDetails?.minOrderMinutesToApply
                    || !modalContent.receiverDetails?.startDate
                    || !modalContent.receiverDetails?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();
                }
            } else if (modalContent.receiverDetails?.subType === PromoCodeSubType.CLIENT_ORDER_AMOUNT_DISCOUNT) {
                if (!modalContent.receiverDetails?.discountAmount 
                    || !modalContent.receiverDetails?.minOrderAmountToApply
                    || !modalContent.receiverDetails?.startDate
                    || !modalContent.receiverDetails?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();

                }
            } else if (modalContent.receiverDetails?.subType === PromoCodeSubType.CLIENT_ORDER_DISCOUNT_PERCENT) {
                if (!modalContent.receiverDetails?.discountPercent 
                    || !modalContent.receiverDetails?.minOrderAmountToApply
                    || !modalContent.receiverDetails?.startDate
                    || !modalContent.receiverDetails?.maxUsagePerUser) {
                        setLoading(false);
                        return showFieldsNotFilledError();
                }
            } else if (modalContent.receiverDetails?.subType === PromoCodeSubType.PROVIDER_BONUS) {
                if (!modalContent.receiverDetails?.bonusAmount
                    || !modalContent.receiverDetails?.startDate) {
                    setLoading(false);
                    return showFieldsNotFilledError();
                }
            } else if (modalContent.inviterDetails?.subType === PromoCodeSubType.PROVIDER_BONUS) {
                if (!modalContent.inviterDetails?.bonusAmount
                    || !modalContent.inviterDetails?.startDate) {
                    setLoading(false);
                    return showFieldsNotFilledError();
                }
            }
            if (modalContent.receiverDetails?.subType === PromoCodeSubType.NO_CAMPAIGN  && modalContent.inviterDetails?.subType === PromoCodeSubType.NO_CAMPAIGN) {
                    setLoading(false);
                    return showFieldsNotFilledError();
            }

            const resp = await createNewInviteFriendCampaing(
                {
                    ...modalContent,
                    inviterDetails: modalContent?.inviterDetails?.subType !== PromoCodeSubType.NO_CAMPAIGN  ? modalContent?.inviterDetails : null,
                    receiverDetails: modalContent?.receiverDetails?.subType !== PromoCodeSubType.NO_CAMPAIGN ? modalContent?.receiverDetails : null,
                });
            if (resp.status === 'ERROR') {
                setLoading(false);
                if (resp.result?.errorCode === "PROMO_CODE_ALREADY_EXISTS") {
                   return notification.error({
                        message: 'Promo code already exists!',
                        duration: 2
                    })
                }
                return notification.error({
                    message: 'Something went wrong!',
                    duration: 2
                })
            } else {
                setForceLoad(Math.random());
            }
        }
        setModalVisible(false);
        setLoading(false);
    }

    const getFooterButtons = (modalTitle: ModalTitle) => {
        switch(modalTitle) {
            case ModalTitle.deleteCampaing: 
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button onClick={handleCampaingDelete} loading={loading} type='primary' danger key='delete'>Delete</Button>
                ]
            case ModalTitle.newCampaing: 
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button onClick={handleCampaingCreate} loading={loading} type='primary' key='create'>Create</Button>
                ]
            default: 
                return []
        }
    }

    const handleViewClick = (e:any, record: any)  => {
        setModalTitle(ModalTitle.viewCampaign);
        setCampaingType(labelType);
        e.stopPropagation();
        setModalContent({ ...record });
        setModalVisible(true);
    }

    const handleOnAddCampaingClick = () => {
        setModalTitle(ModalTitle.newCampaing);
        setCampaingType(labelType);
        if (labelType === CampaingType.SimpleCampaing) {
            setModalContent({
                type: PromoCodeType.ORDER_FREE_TIME,
                individual: false,
                name: '',
            })
        } else if (labelType === CampaingType.UniquePromo){
            setModalContent({
                type: PromoCodeType.ORDER_FREE_TIME,
                individual: true,
                name: '',
            })
        } else {
            setModalContent({
                type: PromoCodeType.INVITE_CLIENT_TO_CLIENT_N2,
                [UpdatedContent.inviterDetails]: {},
                [UpdatedContent.receiverDetails]: {},
                name: '',
            })
        }
        setModalVisible(true);
    }

    const handleRowClick = (id: string, configIndex: number,) => {
        return history.push(`/campaigns/${id}/${configIndex}`);
    }

    const onCheckedDeletedCampaigns = (checked: boolean) => {
        setCheckedDeletedCampaigns(checked);
    }

    const handleLabelChange = (labelIndex: number) => {
        setLabelType(configs[labelIndex]?.label as any);

    }

    return (
        <InnerLayout>
            <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
            <Pager
                url={url}
                headerComponentsInRight={<AddCampaing handleOnAddCampaingClick={handleOnAddCampaingClick} />}
                onCheckedDeletedCampaigns={onCheckedDeletedCampaigns}
                checkedDeletedCampaigns={checkedDeletedCampaigns}
                columns={columns}
                configs={configs}
                onLabelChange={handleLabelChange}
                rowClick={handleRowClick}
                forceReload={forceLoad}
            />
            </Layout.Content>
            {modalVisible ?
            <Modal
                visible={modalVisible}
                title={modalTitle}
                onCancel={handleCancel}
                footer={getFooterButtons(modalTitle)}
            >
                <CampaingnModalContent
                    modalTitle={modalTitle}
                    modalContent={modalContent}
                    setModalContent={setModalContent}
                    campaingType={campaingType}
                    setCampaingType={setCampaingType}
                />
            </Modal>: null}
      </InnerLayout>
    )
}

interface AddCampaingProps {
    handleOnAddCampaingClick: () => void;
}



export const AddCampaing: React.FC<AddCampaingProps> = ({ handleOnAddCampaingClick }) => {
    const { userAuthData } = useContext(MainContext);
    return <Button onClick={handleOnAddCampaingClick} type="primary" disabled={isUserRoleMarketing(userAuthData)}>Add campaign</Button>
}

const getAmounAndUnit = (bonusAmount: number | undefined, freeMinutes: number | undefined, discountAmount: number | undefined, discountPercent: number | undefined) => {
  const amount = bonusAmount || freeMinutes || discountAmount || discountPercent;
  let unit;
  if (bonusAmount || discountAmount){
      unit = '€';
  } else if (freeMinutes){
      unit = 'mins'
  } else {
      unit = '%'
  }

  return {
      amount,
      unit
  }

}