import { Select, Input, Typography } from "antd";
import React from "react";
import { styles } from "../../campaings/styles";
import { ModalContent } from "./Translations";

const { Option } = Select;
const { Text } = Typography;


interface TranslationModalContentProps {
    modalContent: ModalContent;
    setModalContent: (modalContent: ModalContent) => void
}

export const TranslationModalContent: React.FC<TranslationModalContentProps> = ({ modalContent, setModalContent}) => {

    const handleAppTypeChange = (appType: string) => {
        setModalContent({
            ...modalContent,
            appType
        })
    }

    const handleTextChange = (textType: string) => (event: any) => {
        if (textType === 'key') {
            setModalContent({
                ...modalContent,
                key: event.nativeEvent.target.value
            })
        } else {
            setModalContent({
                ...modalContent,
                values: modalContent.values.map(value => {
                    if (value.language === textType) {
                        return {
                            language: textType,
                            value: event.nativeEvent.target.value,
                        }
                    }
                    return value;
                })
            })
        }
    }

    return (
        <div>
            {!modalContent.id && <Select value={modalContent.appType} style={styles.modalRowItem} onChange={handleAppTypeChange}>
                <Option value='CLIENT'>Client</Option>
                <Option value='PROVIDER'>Provider</Option>
            </Select>}
            {modalContent.id ? <Text>key: {modalContent.key}</Text> : <Input value={modalContent.key} onChange={handleTextChange('key')} style={styles.modalRowItem} placeholder='key'/>}
            <Input value={modalContent.values[0].value} onChange={handleTextChange('en')} style={styles.modalRowItem} placeholder='English translation'/>
            <Input value={modalContent.values[1].value} onChange={handleTextChange('de')} style={styles.modalRowItem} placeholder='German Translation'/>
        </div>
    )
}