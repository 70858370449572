import { LOCAL_STORAGE } from "../utils/global-enums";

declare var API_ENDPOINT: string;

export const authorisedRequest = (url: string, method = 'GET', data: any = '', headers: any = {}): any => {
  const authData = localStorage.getItem(LOCAL_STORAGE.AUTH_DATA);
  const parsed = authData ? JSON.parse(authData) : '';

  let auth = {};
  if (!!parsed && Object.keys(parsed).length > 0) {
    auth = { Authorization: `Bearer ${parsed.accessToken}` };
  }

  const info: any = {
    method,
    headers: {
      ...auth,
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  const refreshInfo: any = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refreshToken: parsed.refreshToken }),
  }

  if (data) {
    if (headers['Content-Type'] !== 'multipart/form-data') {
      info.body = JSON.stringify(data);
    } else {
      info.body = data;
      delete info.headers['Content-Type'];
    }
  }

  const refreshToken = async () => {
    const respForToken = await fetch(`${API_ENDPOINT}/users/refresh-token`, refreshInfo);
    const resultForToken = await respForToken.json();
    const newAccessToken = resultForToken.content?.accessToken;
    if (!newAccessToken) {
      throw new Error('can not refresh token');
    } 
    parsed.accessToken = newAccessToken;
    localStorage.setItem(LOCAL_STORAGE.AUTH_DATA, JSON.stringify(parsed));
    return newAccessToken;
  }


  let finalResult:any;
  return fetch(`${API_ENDPOINT}${url}`, info)
    .then(async result => {
      if (result.status === 200) {
        finalResult = result.clone();
        const contentType = result.headers.get('Content-Type');
        if(contentType === 'application/octet-stream'){
          return result.text();
        }
        return result.json();
      } else if (result.status === 204) {
        return result
      }
      else if (result.status === 401) {
        await refreshToken();
        return authorisedRequest(url, method, data, headers)
      } else {
        const errorResp = await result.json()
        return { status: 'ERROR', code: result.status, result: errorResp };
      }
    })
    .catch((e) => {
      if (e.message.includes('Unexpected token , in JSON') || e.message.includes('The string did not match the expected pattern')) {
        return finalResult.blob()
      }
      window.location.href = '/login';
    });
}
