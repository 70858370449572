import React, { useContext, useState } from 'react';
import { Skeleton, Card, Descriptions, Button, Avatar, Popconfirm, Rate, Tag, notification } from 'antd';
import { getHours, formatDate, isUserRoleMarketing } from '../../utils/helpers';
import { useOrderDetails } from './useOrderDetails';
import { useOrderActions } from './useOrderActions';
import './carousel.css';
import {  TransactionType } from './TransactionItem';
import { TransactionHistory } from './TransactionHistory';
import { MainContext } from '../../App';
import { fetchOrderInvoice } from '../../api/orders';
import viewIcon from '../../icons/vIewIcon.svg';
import downloadIcon from '../../icons/downloadIcon.svg';
const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";

const CANCEL_REASONS: any = {
  CLIENT_CANCEL_REASON_1: 'I requested a caregiver by mistake.',
  CLIENT_CANCEL_REASON_2: 'I changed my mind.',
  CLIENT_CANCEL_REASON_3: 'The waiting time was too long.',
  CLIENT_CANCEL_REASON_4: 'The caregiver didn\'t show up.',
  CLIENT_CANCEL_REASON_5: 'The caregiver is not replying to my messages.',
  CLIENT_CANCEL_REASON_6: 'The caregiver asked me to cancel.',
  PROVIDER_CANCEL_REASON_1: 'I accepted the job offer by mistake.',
  PROVIDER_CANCEL_REASON_2: 'I can\'t find the client.',
  PROVIDER_CANCEL_REASON_3: 'The client is not replying to my messages.',
  PROVIDER_CANCEL_REASON_4: 'The client asked me to cancel.',
  null: 'N/A',
};

export const OrderDetails = ({ id, onChange }: { id: string, onChange: Function }) => {
  const { userAuthData } = useContext(MainContext);
  const { loading, data } = useOrderDetails(id);
  const { loading: actionInProgress, remove } = useOrderActions(id, onChange);
  const [visibleModal, setVisible] = useState(false);
  let title;
  if (data?.status) {
    title = data.status === 'PENDING' ?
      'Order is in review' :
      data.status === 'ACTIVE' ?
        'Active order' :
        'Complete order';
  }

  const handleViewInvoice = async (language: string) => {
      const resp = await fetchOrderInvoice(data.id, language)
      if (resp.status === 'ERROR') {
        notification.error({
          message: 'Something went wrong!',
          duration: 2
        })
      } else {
        window.open(resp.url, '_blank')
      }
  }

  const handleDownloadInvoice = async (language: string) => {
    const resp = await fetchOrderInvoice(data.id, language)
    if (resp.status === 'ERROR') {
      notification.error({
        message: 'Something went wrong!',
        duration: 2
      })
    } else {
      fetch(resp.url)
          .then((response) => (response.blob()))
          .then((blob: any) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `invoice.pdf`,
            );
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
          })
          .catch(() => {
            notification.error({
              message: 'Something went wrong!',
              duration: 2
            })
          })
    }
  }

  return (
    <div>
      <Card
        hoverable
        className={'orderDetails'}
        actions={(data.status === 'IN_TRANSIT' || data.status === 'IN_PROGRESS') ? [
          <Popconfirm
            title={`Are you sure you want to delete order ${data.orderNumber}?`}
            onConfirm={remove}
            okText="Yes"
            cancelText="No"
            disabled={isUserRoleMarketing(userAuthData)}
          >
            <Button
                type="primary"
                loading={actionInProgress}
                danger
                disabled={isUserRoleMarketing(userAuthData)}
            >
              Delete Order
            </Button>
          </Popconfirm>,
        ] : []}>
        <Skeleton loading={loading} active>
          <h2 className={'cardTitle'}>{title}</h2>
          <h3 className={'orderNumber'}>{data.orderNumber}</h3>
        </Skeleton>
      </Card>
      <Card>
        <Skeleton loading={loading} active>
          <div>
            <p className={'descriptionsTitle'}>Profiles</p>
            <div className={'profile'}>
              <Avatar src={data?.customer?.profileUrl || defaultImage}/>
              <p className={'name'}>{data?.customer?.fullName}</p>
            </div>
            {data?.provider &&
            <div className={'profile'}>
              <Avatar src={data?.provider?.profileUrl || defaultImage}/>
              <p className={'name'}>{data?.provider?.fullName}</p>
            </div>}
          </div>
          <Descriptions title="Order details" size="small" column={1} bordered>
            <Descriptions.Item label="Start date" >{formatDate(data.startDate || data.earlyStartDate, 'DD-MM-YYYY HH:mm')}</Descriptions.Item>
            <Descriptions.Item label="Duration">{getHours(data.hoursCount)}</Descriptions.Item>
            {data.status !== 'FINISHED' && <Descriptions.Item label={data.rateFrom !== null ? "Amount range" : "Hourly Rate"}>
              {data.rateFrom !== null ? <span>€{data.rateFrom}/hr - €{data.rateTo}/hr</span> : <span>€{data.recommendedRate}/hr</span>}
            </Descriptions.Item>}
            {(data.status === 'FINISHED' || data.status === 'CANCELLED_BY_PROVIDER' || data.status === 'CANCELLED_BY_CLIENT') && data.transactions && data.transactions.length &&
            <Descriptions.Item label="Payment details">
              <PaymentDetails transactions={data.transactions} />
              {data && data.transactions && data.transactions.length &&
                <div className='transactionHistory'>
                  <p onClick={() => setVisible(true)} >Transaction History</p>
                </div>
              }
            </Descriptions.Item>}
            <Descriptions.Item  label="Address">{data.address}</Descriptions.Item>
            {data.status === 'FINISHED' && data.ratedByClient &&
            <Descriptions.Item label="Rate by client">
              <div><Rate disabled allowHalf value={data.ratedByClient} /> {data.ratedByClient}</div>
              <div>{data.providerTags.map((tag: any) => <span key={tag.name} className="rateTags">{tag.name}</span>)}</div>
              <Tag className="tagReview">{data.reviewedByClient ? data.reviewedByClient : 'N/A'}</Tag>
            </Descriptions.Item>}
            {data.status === 'FINISHED' && data.ratedByProvider &&
            <Descriptions.Item label="Rate by provider" >
              <div><Rate disabled allowHalf value={data.ratedByProvider} /> {data.ratedByProvider}</div>
              <div>{data.customerTags.map((tag: any) => <span key={tag.name} className="rateTags">{tag.name}</span>)}</div>
              <Tag className="tagReview">{data.reviewedByProvider ? data.reviewedByProvider : 'N/A'}</Tag>
            </Descriptions.Item>}
            {data.orderCancellation &&
            <Descriptions.Item label={`Cancelled by ${data.orderCancellation.cancelledBy === data.customer.user.id ? 'client:' : 'provider:'}`}>
              {CANCEL_REASONS[data.orderCancellation.cancelledReason]}
              <div><b>Comment</b></div>
              <Tag className="tagReview">{data.orderCancellation.comment ? data.orderCancellation.comment : 'N/A'}</Tag>
            </Descriptions.Item>}
            {/*{data.status === 'FINISHED' &&  data.canDownloadInvoice && <Descriptions.Item label={'EN Invoice'}>*/}
            {/*  <img src={viewIcon} className={'actionButton'} onClick={() => handleViewInvoice('en')} alt={'ViewIcon'} />*/}
            {/*  <img src={downloadIcon} className={'actionButtonDownload'} onClick={() => handleDownloadInvoice('en')} alt={'DownloadIcon'}/>*/}
            {/*</Descriptions.Item>}*/}
            {/*{data.status === 'FINISHED' && data.canDownloadInvoice && <Descriptions.Item label={'DE Invoice'}>*/}
            {/*  <img src={viewIcon} className={'actionButton'} onClick={() => handleViewInvoice('de')} alt={'ViewIcon'} />*/}
            {/*  <img src={downloadIcon} className={'actionButtonDownload'} onClick={() => handleDownloadInvoice('de')} alt={'DownloadIcon'} />*/}
            {/*</Descriptions.Item>}*/}
            {data.status === 'FINISHED' &&  <Descriptions.Item label={'Invoice'}>
               <img src={viewIcon} className={'actionButton'} onClick={() => handleViewInvoice('de')} alt={'ViewIcon'} />
               <img src={downloadIcon} className={'actionButtonDownload'} onClick={() => handleDownloadInvoice('de')} alt={'DownloadIcon'} />
            </Descriptions.Item>}
            {((data.status === 'CANCELLED_BY_PROVIDER' || data.status === 'CANCELLED_BY_CLIENT') && data.canDownloadInvoice) && <Descriptions.Item label={'Invoice'}>
                <img src={viewIcon} className={'actionButton'} onClick={() => handleViewInvoice('de')} alt={'ViewIcon'} />
                <img src={downloadIcon} className={'actionButtonDownload'} onClick={() => handleDownloadInvoice('de')} alt={'DownloadIcon'} />
            </Descriptions.Item>}
          </Descriptions>
        </Skeleton>
      </Card>
      <TransactionHistory setVisible={setVisible} visibleModal={visibleModal} transactions={data.transactions} />
    </div>
  );
};

interface PaymentDetailsProps {
  transactions: Transaction[];
}

interface Transaction {
  amount: string;
  createdDate: string;
  type: TransactionType;
}

const PaymentDetails : React.FC<PaymentDetailsProps> = ({ transactions }) => {
  let paidByClient: number | undefined;
  let stripeCommission: number | undefined;
  let flexxiCommission: number | undefined;
  let paidToProvider: number | undefined;

  transactions.forEach(transaction => {
    if (transaction.type === TransactionType.customerPayment || transaction.type === TransactionType.customerMinCharged || transaction.type === TransactionType.customerFullCharged || transaction.type === TransactionType.customerTip) {
      return paidByClient = (paidByClient || 0) + parseFloat(transaction.amount);
    }
    if (transaction.type === TransactionType.paymentTipToProvider || transaction.type === TransactionType.paymentToProvider) {
      return paidToProvider = (paidToProvider || 0) + parseFloat(transaction.amount);
    }
    if (transaction.type === TransactionType.paymentGatewayFee) {
      return stripeCommission = (stripeCommission || 0) + parseFloat(transaction.amount);
    }
    if (transaction.type === TransactionType.flexxiFee) {
      return flexxiCommission = (flexxiCommission || 0) + parseFloat(transaction.amount);
    }
  })
  paidByClient = paidByClient ? paidByClient : 0; 
  paidToProvider = paidToProvider ? paidToProvider : 0;
  return (
    <>
      <span>Paid by client: <strong>{`${paidByClient.toFixed(2)}€`}</strong></span><br />
      {!!stripeCommission && <><span>Stripe commission: <strong>{`${stripeCommission.toFixed(2)}€`}</strong></span><br /></>}
      {!!flexxiCommission && <><span>Our commission: <strong>{`${flexxiCommission.toFixed(2)}€`}</strong></span><br /></>}
      <span>Paid to provider: <strong>{`${paidToProvider.toFixed(2)}€`}</strong></span>
    </>
  )
}
