import { ArrowLeftOutlined } from '@ant-design/icons';
import { Avatar, Layout} from 'antd';
import React from "react";
import { InnerLayout } from "../../components/InnerLayout";
import { Pager } from "../../components/pager/Pager";
import { Link, useParams } from 'react-router-dom';

const url = '';

interface ProvidersParams {
    configIndex: string;
    id: string;
}
const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";
export const CampaignDetails = () => {
    const params = useParams<ProvidersParams>();
    const { configIndex, id } = params;
    const columns = [
        {
            title: 'User type',
            dataIndex: 'userRole',
        },
        {
            title: 'User name',
            dataIndex: 'userFullName',
        },
        {
            title: 'User avatar',
            dataIndex: 'profileUrl',
            render: (profileUrl: string) => {
                return <div>
                         <Avatar src={profileUrl || defaultImage} />
                       </div>
            }
        },
        {
            title: 'Inviter user name',
            dataIndex: ['inviterDetails', 'userFullName'],
            render: (userFullName: string,) => {
                return userFullName
            }
        },
        {
            title: 'Inviter user avatar',
            dataIndex: ['inviterDetails', 'profileUrl'],
            render: (profileUrl: string,) => {
                return <div>
                    <Avatar src={profileUrl || defaultImage} />
                </div>
            }
        },
        {
            title: 'Invited user name',
            dataIndex: ['receiverDetails', 'userFullName'],
            render: (userFullName: string,) => {
                return userFullName
            }
        },
        {
            title: 'Invited user avatar',
            dataIndex: ['receiverDetails', 'profileUrl'],
            render: (profileUrl: string,) => {
                return <div>
                    <Avatar src={profileUrl || defaultImage} />
                </div>
            }
        },
        {
            title: 'Status',
            dataIndex: 'receiverDetails',
            render(receiverDetails: any) {
                if (receiverDetails.userRole === 'PROVIDER'){
                    if (receiverDetails.usageCount > 0){
                        return 'Qualified'
                    }
                    return 'Registered'
                }
                if (receiverDetails.userRole === 'CLIENT'){
                    if (receiverDetails.totalCount > 0){
                        return 'Qualified'
                    }
                    return 'Registered'
                }
            }
        },
        {
            title: 'Max usage',
            dataIndex: 'maxUsagePerUser',
        },
        {
            title: 'Usage quantity',
            dataIndex: 'usageCount',
        },
    ]
    const configs=[
            {
                label: 'Campaign',
                params: {
                    url: `/admin/campaigns/${id}/usages`,
                    removeIndex: [3,4,5,6,7],
                    hideSelect: true,
                },
            },
            {
                label: 'Invite Friend',
                params: {
                    url: `/admin/invite-friends/${id}/usages`,
                    removeIndex: [0,1,2,8,9],
                    hideSelect: true,
               },
            },
           {
                label: 'Unique promo',
                params: {
                    url: `/admin/campaigns/${id}/usages`,
                    removeIndex: [3,4,5,6,7],
                    hideSelect: true,
               },
          },
    ]

    return (
        <InnerLayout>
            <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
                <Link className='to-back-container' to={`/campaigns/${configIndex}`}>
                    <ArrowLeftOutlined />
                    <span className='to-back-text'>Back to all Campaigns</span>
                </Link>
                <Pager
                    url={url}
                    columns={columns}
                    configs={configs}
                    rowClick={()=>null}
                    notSearchable={true}
                />
            </Layout.Content>
        </InnerLayout>
    )
}
