import React, { useEffect, useState } from 'react';
import { Typography, Space, DatePicker } from 'antd';
import './card.css';
import { measureTextWidth, Pie } from '@ant-design/plots';
import moment from 'moment';
import { getPayments } from '../../api/dashboard';
import paymentDonat from '../../icons/paymentDonat.png';
const { Text } = Typography;
const { RangePicker } = DatePicker;

const getFormatedText = (totalAmount: number) => {
    let text = '';
    let dividedAmount = (totalAmount/1000).toString();
    dividedAmount = dividedAmount.slice(0, (dividedAmount?.indexOf("."))+3)
    if(totalAmount < 1000){
        text = `${totalAmount}`
    } else if(totalAmount >= 1000){
        text = dividedAmount.endsWith('00') ?
            `${dividedAmount.slice(0, dividedAmount?.indexOf("."))} K`
            : (dividedAmount.endsWith('0'))?
                `${dividedAmount.slice(0, dividedAmount?.indexOf(".") + 2)} K`
                :`${dividedAmount} K`

    }
    return text;
}
export const Payments: React.FC = () => {
    const [period, setPeriod] = useState<any>({
        createdDateFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        createdDateTo: moment().format('YYYY-MM-DD'),
    })
    const [payments,setPayments] = useState<any[]>([])

    useEffect(()=>{
        getPayments(period)
            .then((data: any)=>{
                const newData = data?.map((item:any)=>{
                    const transactionType = item.transactionType.split('_').join(' ');
                    return {...item, transactionType}

                })
                setPayments(newData)
            })
    }, [period])

    function renderStatistic(containerWidth:any, text:any, style:any) {
        const { width: textWidth, height: textHeight } = measureTextWidth(
            text,
            style
        );
        const R = containerWidth / 2;

        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(
                Math.sqrt(
                    Math.abs(
                        Math.pow(R, 2) /
                        (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
                    )
                ),
                1
            );
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
            scale < 1 ? 1 : "inherit"
        };">${text}</div>`;
    }
    const config = {
        appendPadding: 10,
        data: payments,
        angleField: "totalAmount",
        colorField: "transactionType",
        color:['#1890FF','#13C2C2', '#2FC25B', '#FACC14', '#F04864', '#FF6600'],
        radius: 1,
        innerRadius: 0.6,
        style: {
            height: 358,
        },
        meta: {
            totalAmount: {
                formatter: (( v: any) => {
                    const amount = getFormatedText(v)
                    return `€ ${amount}  ${ (v * 100 / payments.reduce((acc: any, item: any) => (item.totalAmount + acc), 0)).toFixed(1)}%`})
            },

        },
        label: {
            type: "inner",
            offset: "-50%",
            style: {
                textAlign: "center",
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 17,
                fill: '#FFFFFF',
            },
            autoRotate: false,
            content: function content(data: any) {
                if(data.percentage < 2){
                    return ''
                }
                return getFormatedText(data.totalAmount)
            },
        },
        legend: {
            position: 'bottom' as any,
            offsetX: 8,
            title: {
                text: '',
                spacing: 8,
            },
        },
        statistic: {
            content: {
                offsetY: 4,
                style: {
                    fontSize: '32px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    color: '#448EF7',
                },
                customHtml: (container:any, view:any, datum:any, data:any) => {
                    const { width } = container.getBoundingClientRect();
                    const totalAmount = data.filter((item: any)=>item.transactionType == 'CUSTOMER PAYMENT').reduce((r: any, d: any) => r + d.totalAmount, 0)
                    const text =`€ ${getFormatedText(totalAmount)}`
                    return renderStatistic(width, text, {});
                }
            },
            title: {
                offsetY: -4,
                style: {
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    whiteSpace: 'wrap',
                    color: '#A29DB0',

                },
                customHtml: (container: any) => {
                    const { width, height } = container.getBoundingClientRect();
                    const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                    const text = "Total amount";
                    return renderStatistic(d as any, text as any, {} as any);
                }
            },

        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };

    const handlePeriodChange = (period: any) => {
        period &&
        setPeriod({
            createdDateFrom: period[0]?.format('YYYY-MM-DD'),
            createdDateTo: period[1]?.format('YYYY-MM-DD'),
        })
    }

    return(
        <>
            <Space className={'headerCard'} style={{ marginBottom: 32 }}>
                <Text className={'titleText'}>Payments</Text>
                <RangePicker
                    value={period?.createdDateFrom ? [moment(period?.createdDateFrom), moment(period?.createdDateTo)] : null}
                    onChange={handlePeriodChange}
                />
            </Space>
            {
                !!payments?.length?
                    <Pie
                    {...config}
                    onReady={(plot) => {
                        plot.chart.removeInteraction('legend-filter');
                    }} />
                    : <div className={'chartImgWrapper'}><img src={paymentDonat} alt={'PaymentDonat'}/></div>
            }
        </>
    )
}