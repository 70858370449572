import React, { useEffect, useState, useMemo } from 'react';
import { Input, Space, Typography, Checkbox, notification } from 'antd';
import { DeleteModalContent, WorkingPlaces } from './ProviderWorkingAreas';
import { ModalTitle } from './workingPlace-enums';
import { SearchOutlined } from '@ant-design/icons';
import './carousel.css';
import { getProvideWorkingPlacesByPostalCode } from '../../api/providers';
const { Text } = Typography;

interface ServiceAreasModalContentProps {
    modalTitle: ModalTitle;
    setModalContent: (content: any) => void;
    modalContent: DeleteModalContent | WorkingPlaces[] ;
    attachArr: any[];
    setAttachArr: (content: any) => void;
    detachArr: string[];
    setDetachArr: (content: any) => void;
}

export const ServiceAreasCitiesModalContent: React.FC<ServiceAreasModalContentProps> = ({ modalTitle, setModalContent, modalContent, attachArr, setAttachArr, detachArr, setDetachArr}) => {
        switch(modalTitle) {
            case ModalTitle.NEW_WORKPLACE:
            case ModalTitle.NEW_CITY:
                return <AddProviderCity
                            setModalContent={setModalContent}
                            modalContent={modalContent as WorkingPlaces[]}
                            modalTitle={modalTitle}
                            attachArr={attachArr}
                            detachArr={detachArr}
                            setDetachArr={setDetachArr}
                            setAttachArr={setAttachArr}
                        />
            case ModalTitle.DELETE_WORKPLACE:
                return <DeleteProviderWorkPlace  modalContent={modalContent as DeleteModalContent}/>
            default:
                return <></>
        }
}


interface AddProviderWorkPlaceProps {
    setModalContent(content: WorkingPlaces[]): void;
    modalContent: WorkingPlaces[];
    modalTitle: string
    attachArr: any[];
    setAttachArr: (content: any) => void;
    detachArr: string[];
    setDetachArr: (content: any) => void;
}

const useDebouncedValue = (inputValue: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(inputValue);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, delay]);

    return debouncedValue;
};

const AddProviderCity: React.FC<AddProviderWorkPlaceProps> = ({ modalContent, modalTitle,  attachArr, setAttachArr, detachArr, setDetachArr }) => {

    const defaultCount = modalContent.reduce((acc: number , item:any) => {
        return acc + item.postalCodes.length
    }, 0)
    const [searchedModalContent, setSearchedModalContent] = useState<any[]>(modalContent);
    const [searchValue, setSearchValue] = useState('');
    const [totalZipCodesCount, setTotalZipCodesCount] = useState(defaultCount);
    const [order, setOrder] = useState(0);
    const debouncedSearchTerm = useDebouncedValue(searchValue, 500);


    useEffect(() => {
        if(searchValue){
            getProvideWorkingPlacesByPostalCode(searchValue)
                .then((data: any) => {
                    const newData = data?.map((item:any) => {
                        const checkedCityExist =  searchedModalContent.find((el) => item.city === el.city)
                        if(checkedCityExist){
                            return {...item,postalCodes: checkedCityExist.postalCodes}
                        }
                        return item
                    })
                    setSearchedModalContent(newData)
                })
        } else {
            setSearchedModalContent([...modalContent] as WorkingPlaces[])
            setAttachArr([])
            setDetachArr([])
            setOrder(0)
            setTotalZipCodesCount(defaultCount)
        }

    }, [debouncedSearchTerm])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    return (
        <Space style={styles.WorkingPlaceModalContent as React.CSSProperties}>
            <Input
                type='text'
                value={searchValue}
                placeholder='Search City or Zip code'
                onChange={handleChange}
                prefix={<SearchOutlined style={{ fontSize: '20px' }}/>}
            />
            {
                searchedModalContent?.map((workingPlace: any) => {
                    return(
                        <>{modalTitle === ModalTitle.NEW_CITY ?
                            <CityZipCode
                               key={workingPlace.city}
                               workingPlace={workingPlace}
                               modalContent={modalContent}
                               searchValue={searchValue}
                               totalZipCodesCount={totalZipCodesCount}
                               setTotalZipCodesCount={setTotalZipCodesCount}
                               detachArr={detachArr}
                               setDetachArr={setDetachArr}
                               attachArr={attachArr}
                               setAttachArr={setAttachArr}
                               searchedModalContent={searchedModalContent}
                               setSearchedModalContent={setSearchedModalContent}
                               order={order}
                               setOrder={setOrder}
                            />:

                            <ServiceArea
                                key={workingPlace.city}
                                workingPlace={workingPlace}
                                searchValue={searchValue}
                                totalZipCodesCount={totalZipCodesCount}
                                setTotalZipCodesCount={setTotalZipCodesCount}
                                detachArr={detachArr}
                                setDetachArr={setDetachArr}
                                attachArr={attachArr}
                                setAttachArr={setAttachArr}
                                searchedModalContent={searchedModalContent}
                                setSearchedModalContent={setSearchedModalContent}
                                order={order}
                                setOrder={setOrder}
                            />}
                        </>
                    )
                })
            }
        </Space>
    )
}

interface CityZipCodeProps {
    key: string;
    modalContent: WorkingPlaces[];
    searchValue: string;
    totalZipCodesCount: any;
    setTotalZipCodesCount: (content: any) => void;
    workingPlace: any;
    attachArr: any;
    setAttachArr: any;
    detachArr: any;
    setDetachArr: any;
    searchedModalContent: any[];
    setSearchedModalContent: (content: any[]) => void;
    order: number
    setOrder: (content: number) => void;
}
interface ServiceAreaProps {
    searchValue: string;
    totalZipCodesCount: any;
    setTotalZipCodesCount: (content: any) => void;
    workingPlace: any;
    attachArr: any;
    setAttachArr: any;
    detachArr: any;
    setDetachArr: any;
    searchedModalContent: any[];
    setSearchedModalContent: (content: any[]) => void;
    setOrder: (content: number) => void;
    order: number
}



const ServiceArea: React.FC<ServiceAreaProps> = ({ workingPlace,  searchValue, totalZipCodesCount, setTotalZipCodesCount, attachArr, setAttachArr, detachArr, setDetachArr,  order, setOrder }) => {

    const { city, indexes }  = workingPlace
    const zipCodes = workingPlace.postalCodes ? workingPlace.postalCodes.map((item: any) => item.zipCode) : []
    const ids = workingPlace.postalCodes ?  workingPlace.postalCodes.map((item: any) => item.id): []

    const onCheckboxChange = (e: any, zipCode: string) => {
    const cityExist = attachArr.find((item: any) => item.city === city)
    let newAttachArr = attachArr
    let newDetachArr = detachArr
    let count = totalZipCodesCount
        if(e.target.checked ){
            if(!cityExist){
                if(zipCodes.includes(zipCode)){
                    newAttachArr = [...attachArr]
                    setAttachArr([...newAttachArr])
                    count = count + 1
                } else {
                    newAttachArr = [...attachArr,
                        {
                            city: workingPlace.city,
                            countryCode: workingPlace.countryCode,
                            zipCodes: [zipCode],
                            order: order + 1,
                        }]
                    count = count + 1
                    setAttachArr([...newAttachArr])
                    setOrder(order + 1)
                }
            } else {
                if(!zipCodes.includes(zipCode)) {
                    newAttachArr = attachArr.map((item: any) => {
                        if (item.city === city) {
                            return { ...item, zipCodes: [...item.zipCodes, zipCode] }
                        }
                        return item
                    })
                    setAttachArr([...newAttachArr])
                    count = count + 1
                } else {
                    count = count + 1
                    setAttachArr([...newAttachArr])
                }
            }
            const id = workingPlace?.postalCodes?.find((item: any) => item.zipCode === zipCode)?.id
                if(detachArr.includes(id)){
                    newDetachArr = detachArr.filter((el:string)=>el !== id)
                }
                setDetachArr([...newDetachArr])
        } else {
             newAttachArr = attachArr.map((item: any) => {
                if (item.city === city) {
                    return {
                        ...item, zipCodes: item.zipCodes.filter((item: any) => item !== zipCode)
                    }
                }
                    return item
            }).filter((item: any) => item.zipCodes.length)
            const id = workingPlace?.postalCodes?.find((item: any) => item.zipCode === zipCode)?.id
            count = count -1
            newDetachArr = [...newDetachArr, id]
            id && setDetachArr([...newDetachArr])
        }
        let deletedZipCodesCount = 0
        ids.forEach((id: string) => {
            if (newDetachArr.includes(id)){
                deletedZipCodesCount = deletedZipCodesCount + 1
            }
        })
        newAttachArr = newAttachArr.map((item: any) => {
            const citySelected = ((zipCodes.length + item.zipCodes.length - deletedZipCodesCount) === indexes.length)
            if(city === item.city && citySelected){
                return {...item, citySelected: true}
            } else if (city === item.city && !citySelected){
                delete item['citySelected']
            }
            return item
        })
        setAttachArr(newAttachArr)
        setTotalZipCodesCount(count)
    }

            return (
                <div style={{marginTop: 15 }}>
                    <p>{city}</p>
                    {!searchValue ? workingPlace?.postalCodes?.map((item: any) => {
                        return (
                            <div>
                                <ServiceAreaZipCode
                                    zipCode={item.zipCode}
                                    id={item.id}
                                    zipCodes={zipCodes}
                                    key={item.zipCode}
                                    onCheckboxChange={onCheckboxChange}
                                    totalZipCodesCount={totalZipCodesCount}
                                    attachArr={attachArr}
                                    city={city}
                                    detachArr={detachArr}

                                />
                            </div>)}):
                    workingPlace?.indexes?.map((zipCode: any)=>{
                        return (
                          <div>
                              <ServiceAreaZipCode
                                  zipCode={zipCode}
                                  id={workingPlace?.postalCodes?.find((item:any) => item.zipCode === zipCode)?.id}
                                  zipCodes={zipCodes}
                                  key={zipCode}
                                  onCheckboxChange={onCheckboxChange}
                                  totalZipCodesCount={totalZipCodesCount}
                                  attachArr={attachArr}
                                  detachArr={detachArr}
                                  city={city}
                              />
                          </div>
                           )})
                        }
                </div>
            )
 }



interface ServiceAreaZipCodeProps {
    zipCode: string;
    zipCodes: string;
    key: string;
    onCheckboxChange: (e: any, content: string) => void;
    totalZipCodesCount: number
    attachArr: any[]
    detachArr: any[]
    city: string
    id?: string
}

const ServiceAreaZipCode = ({zipCode, onCheckboxChange, zipCodes, totalZipCodesCount, attachArr, city, detachArr, id}:  ServiceAreaZipCodeProps) =>{
    const existInDetachArr = detachArr?.includes(id)
    const existInAttachArr = attachArr.find((el: any) => el.city === city)?.zipCodes?.includes(zipCode)
    const [checked, setChecked] =  useState((zipCodes.includes(zipCode) || existInAttachArr) && !existInDetachArr);

    const handleCheckboxChange = (e: any, zipCode: string) =>{
        if(totalZipCodesCount >= 300 && e.target.checked === true){
            notification.error({
                message: 'Max 300 reached!',
                duration: 2
            })
            return
        }
        setChecked(!checked)
        onCheckboxChange(e, zipCode)
    }

    return (
            <Checkbox
                onChange={(e)=>handleCheckboxChange(e, zipCode)}
                checked={checked}
            >
                {zipCode}
            </Checkbox>
    )
}


const CityZipCode: React.FC<CityZipCodeProps> = ({ workingPlace, searchValue,  totalZipCodesCount, setTotalZipCodesCount, attachArr, setAttachArr, detachArr, setDetachArr, searchedModalContent, order, setOrder }) => {

    const { city, indexes }  = workingPlace
    const zipCodes = workingPlace.postalCodes ? workingPlace.postalCodes.map((item: any) => item.zipCode) : []
    const ids = workingPlace.postalCodes ?  workingPlace.postalCodes.map((item: any) => item.id): []
    const deletedZipCodesCount = detachArr?.filter((id: string) => ids.includes(id)).length || 0
    const attachedZipCodesCount = attachArr?.find((item: any) => item.city === city)?.zipCodes.length || 0
    const selectedZipCodesCount = useMemo(() => {
        return workingPlace?.postalCodes?.length + attachedZipCodesCount - deletedZipCodesCount
    }, [searchValue])
    const [checked, setChecked] =  useState((zipCodes.length || attachedZipCodesCount) && !deletedZipCodesCount);

    const handleCheckboxChange = (e: any, city: string) => {
        if(totalZipCodesCount >= 300 && e.target.checked === true){
            notification.error({
                message: 'Max 300 reached!',
                duration: 2
            })
            return
        }
        let count = totalZipCodesCount
        let newAttachArr = attachArr
        let newDetachArr = detachArr
        setChecked(!checked)
        searchedModalContent.forEach((cityItem: any) => {
            if (e.target.checked && cityItem.city === city){
                if(!cityItem.postalCodes){
                    newAttachArr = [...attachArr, {city: cityItem.city,countryCode: cityItem.countryCode, zipCodes: indexes, order: order +1 }]
                    setOrder(order + 1)
                    count = count + indexes.length
                } else {
                    if(zipCodes.length !== indexes?.length) {
                        count = count +  indexes.length
                        newAttachArr = [...attachArr, {city: cityItem.city,countryCode: cityItem.countryCode, zipCodes: indexes?.filter((index: string)=>!zipCodes.includes(index)), order: order + 1}]
                        setOrder(order + 1)
                    } else {
                        newAttachArr = [...attachArr]
                        count = count  + indexes?.length
                    }
                    ids.forEach((id: string) => {
                        if(detachArr.includes(id)){
                            newDetachArr = newDetachArr.filter((el:string)=>el !== id)
                        }
                    })
                    setDetachArr([...newDetachArr])

                }
            } else if(!e.target.checked && cityItem.city === city){
                if(!cityItem.postalCodes){
                    newDetachArr= [...detachArr, ...ids]
                    setDetachArr([...newDetachArr])
                    newAttachArr = attachArr.filter((item: any) => item.city !== city)
                    count = count-indexes.length
                } else {
                    newAttachArr = attachArr.filter((item: any) =>item.city !== city )
                    newDetachArr = [...detachArr]
                    ids.forEach((id: string) => {
                        if(!detachArr.includes(id)){
                            newDetachArr.push(id)
                            count = count -1
                        }
                    })
                    setDetachArr([...newDetachArr])
                }
            }
        })
        let deletedZipCodesCount = 0
        ids.forEach((id: string) => {
            if (newDetachArr.includes(id)){
                deletedZipCodesCount = deletedZipCodesCount + 1
            }
        })
        newAttachArr = newAttachArr.map((item: any) => {
            const citySelected = ((zipCodes.length + item.zipCodes.length - deletedZipCodesCount) === indexes.length)
            if(city === item.city && citySelected ){
                return {...item, citySelected: true}
            } else if (city === item.city && !citySelected){
                delete item['citySelected']
            }
            return item
        })
        setDetachArr([...newDetachArr])
        setAttachArr([...newAttachArr])
        setTotalZipCodesCount(count)
    }

    return (
        <div style={{marginTop: 15 }}>
            <Checkbox
                onChange={(e)=>handleCheckboxChange(e, city)}
                checked={checked}
            >
                {`${city}${searchValue || isNaN(selectedZipCodesCount)? '' : `(${selectedZipCodesCount})` }`}
            </Checkbox>
        </div>
    )
}

interface DeleteProviderWorkPlaceProps {
    modalContent: DeleteModalContent;
}

export const DeleteProviderWorkPlace: React.FC<DeleteProviderWorkPlaceProps> = ({ modalContent }) => {
    return (
        <Text>
            Are you sure, you want to delete <span style={{fontWeight: 'bold'}}>{`${modalContent.zipCode}`}</span> Zip code?
        </Text>
    )
}

const styles = {
    WorkingPlaceModalContent: {
        display: 'flex',
        flexDirection: 'column',
        height: 300,
        alignItems: 'stretch',
        overflowY: 'auto'
    },

}
