export enum ModalTitle {
    NEW_WORKPLACE = 'Add Workplace',
    DELETE_WORKPLACE = 'Delete Zip Code',
    NEW_CITY = 'Add City',
}

export enum ErrorCodes {
    USER_MOBILE_ALREADY_IN_USE = 'Mobile Number Already In Use',
    USER_EMAIL_ALREADY_IN_USE = 'Email Already In Use',
    DEFAULT_ERROR = 'Something went wrong!',
}
