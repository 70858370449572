import React, { useState } from 'react';
import { Button, Layout, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { AdminModalContent } from './AdminModalContent';
import { InnerLayout } from '../../components/InnerLayout';
import { Pager } from '../../components/pager/Pager';
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { createAdmin, deleteAdmin, editAdmin, EditAdminPayload } from '../../api/admins';
import { ErrorCodes, ModalTitle, User } from './admins-enums';
import './admins.css';

export interface ModalContent {
    fullName: string;
    email: string;
    phoneNumber: string;
    callCode: string;
    countryCode: string;
    id: string;
    role: string;
}

interface AdminRecord {
    id: string;
    fullName: string;
    email: string;
    mobile: string;
    countryCode: string;
    role: string;
}

const defaultModalContent: ModalContent = {
    id: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    callCode: '49',
    countryCode: 'DE',
    role: User.Admin,
}

const configs = [
    {
        label: 'Admins',
        params: {}
    },
]
const url = `/admins`;

export const Admins: React.FC = () => {
    const [forceReload, setForceReload] = useState(0);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [modalTitle, setModalTitle] = useState<ModalTitle>(ModalTitle.NEW_ADMIN);
    const [modalContent, setModalContent] = useState<ModalContent>(defaultModalContent);
    const [editedAdminRecord, setEditedAdminRecord] = useState<AdminRecord>({
        id: '',
        fullName: '',
        email: '',
        mobile: '',
        countryCode: '',
        role: '',
    });
    const [loading, setLoading] = useState(false);

    const actionButtonDisabled = !modalContent.callCode ||
        !modalContent.phoneNumber ||
        !modalContent.email ||
        !modalContent.fullName;

    const columns = [
        {
          title: 'Full Name',
          dataIndex: 'fullName',
        },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: 'Phone',
          dataIndex: 'mobile',
        },
        {
            title: 'User type',
            dataIndex: 'role',
            render(role: string) {
                return <>{role.toLowerCase()}</>
            },
        },
        {
            title: '',
            dataIndex: 'id',
            render(id: string, record: AdminRecord) {
                return (
                    <>
                        <Button
                            onClick={handleEditClick(record)}
                            type='link'
                            icon={<EditTwoTone />}
                        />
                        <Button
                            onClick={handleDeleteClick(record.fullName, id)}
                            className='delete-button'
                            type='link'
                            icon={<DeleteOutlined />}
                        />
                    </>
                )
            }
        },
      ];

    const handleDeleteClick = (fullName: string, id: string) => () => {
        setModalTitle(ModalTitle.DELETE_ADMIN);
        setModalVisibility(true);
        setModalContent({...modalContent, fullName, id })
    }

    const handleEditClick = (adminRecord: AdminRecord) => () => {
        setModalTitle(ModalTitle.EDIT_ADMIN);
        setModalVisibility(true);
        setEditedAdminRecord(adminRecord);

        const phoneNumber = adminRecord.mobile.substr(adminRecord.mobile.indexOf(' ') + 1); 
        const callCode = adminRecord.mobile.substr(0,adminRecord.mobile.indexOf(' '));

        setModalContent({
            fullName: adminRecord.fullName,
            email: adminRecord.email,
            countryCode: adminRecord.countryCode,
            id: adminRecord.id,
            phoneNumber,
            callCode,
            role: adminRecord.role,
        })
    }

    const handleAddAdminClick = () => {
        setModalTitle(ModalTitle.NEW_ADMIN);
        setModalVisibility(true);
    }

    const headerComponentsInRight = [
        <Button type='primary' onClick={handleAddAdminClick} key='add Admin'>Add Admin</Button>
    ]

    const handleCancel = () => {
        setModalVisibility(false);
        setModalContent(defaultModalContent);
    }

    const handleAdminCreate = async () => {
        const { callCode, phoneNumber, email, fullName, countryCode, role } = modalContent;
        setLoading(true);
        const createResp = await createAdmin({
            mobile: `${callCode} ${phoneNumber}`,
            countryCode,
            email,
            fullName,
            role,
        });
        setLoading(false);
        if (createResp.status !== 'ERROR') {
            setModalContent(defaultModalContent);
            setModalVisibility(false);
            setForceReload(Math.random());
        } else {
            notification.error({
                message: getErrorText(createResp.result.errorCode),
                duration: 2
            })
        }
    }


    const handleAdminSave = async () => {
        const { callCode, phoneNumber, email, fullName, countryCode, id, role } = modalContent;
        setLoading(true);
        const editedPayload: EditAdminPayload = {};

        if (editedAdminRecord.mobile !== `${callCode} ${phoneNumber}`) {
            editedPayload.mobile = `${callCode} ${phoneNumber}`;
            editedPayload.countryCode = countryCode;
        }

        if (editedAdminRecord.countryCode !== countryCode) {
            editedPayload.countryCode = countryCode;
        }

        if (editedAdminRecord.email !== email) {
            editedPayload.email = email;
        }

        if (editedAdminRecord.fullName !== fullName) {
            editedPayload.fullName = fullName;
        }
        if (editedAdminRecord.role !== role) {
            editedPayload.role = role;
        }
        const editResp = await editAdmin(id, editedPayload);
        setLoading(false);
        if (editResp.status !== 'ERROR') {
            setModalContent(defaultModalContent);
            setModalVisibility(false);
            setForceReload(Math.random());
        } else {
            notification.error({
                message: getErrorText(editResp.result.errorCode),
                duration: 2
            })
        }
    }

    const handleAdminDelete = async () => {
        setLoading(true);
        const deleteResp = await deleteAdmin(modalContent.id);
        setLoading(false);
        if (deleteResp.status !== 'ERROR') {
            setModalContent(defaultModalContent);
            setModalVisibility(false);
            setForceReload(Math.random());
        } else {
            notification.error({
                message: getErrorText(deleteResp.result.errorCode),
                duration: 2
            })
        }
    }

    const getFooterButtons = (modalTitle: ModalTitle) => {
        switch(modalTitle) {
            case ModalTitle.NEW_ADMIN: 
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button disabled={actionButtonDisabled} onClick={handleAdminCreate} loading={loading} type='primary' key='create'>Create</Button>
                ]
            case ModalTitle.EDIT_ADMIN: 
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button disabled={actionButtonDisabled} onClick={handleAdminSave} loading={loading} type='primary' key='create'>Save</Button>
                ]

            case ModalTitle.DELETE_ADMIN: 
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button onClick={handleAdminDelete} loading={loading} type='primary' danger key='create'>Delete</Button>
                ]
            default: 
                return []
        }
    }

    return (
        <InnerLayout>
            <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
            <Pager
                headerComponentsInRight={headerComponentsInRight}
                configs={configs}
                url={url}
                columns={columns}
                rowClick={() => null }
                forceReload={forceReload}
            />
            </Layout.Content>
            <Modal
                visible={isModalVisible}
                title={modalTitle}
                footer={getFooterButtons(modalTitle)}
                onCancel={handleCancel}
            >
                <AdminModalContent
                    modalTitle={modalTitle}
                    setModalContent={setModalContent}
                    modalContent={modalContent}
                />
            </Modal>
        </InnerLayout>
    )
}

const getErrorText = (errorCode: string) => {
    switch(errorCode) {
        case 'USER_EMAIL_ALREADY_IN_USE': 
            return ErrorCodes.USER_EMAIL_ALREADY_IN_USE
        case 'USER_MOBILE_ALREADY_IN_USE':
            return ErrorCodes.USER_MOBILE_ALREADY_IN_USE
        default:
            return ErrorCodes.DEFAULT_ERROR
    }
}