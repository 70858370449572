import React from 'react';

export enum TransactionType {
  customerFundsFreeze = 'CUSTOMER_FUNDS_FREEZE',
  customerPromoCode = 'CUSTOMER_PROMO_CODE',
  customerFundsFreezeExtend = 'CUSTOMER_FUNDS_FREEZE_EXTEND',
  customerPayment = 'CUSTOMER_PAYMENT',
  customerMinCharged =  'CUSTOMER_MIN_CHARGED',
  customerFullCharged = 'CUSTOMER_FULL_CHARGED',
  customerTip = 'CUSTOMER_TIP',
  customerFreeService = 'CUSTOMER_FREE_SERVICE',
  platformFee = 'PLATFORM_FEE',
  paymentGatewayFee = 'PAYMENT_GATEWAY_FEE',
  paymentToProvider = 'PAYMENT_TO_PROVIDER',
  paymentTipToProvider = 'PAYMENT_TIP_TO_PROVIDER',
  flexxiFee = 'FLEXXI_FEE',
  refundToCustomer = 'REFUND_TO_CUSTOMER',
  customerBonus = 'CUSTOMER_BONUS',
}

export interface TransactionItemData {
    type: TransactionType;
    amount: number;
    createdDate: string;
    promoCodeName: string | null;
}

interface TransactionItemProps {
  itemData: TransactionItemData
}

export const TransactionItem: React.FC<TransactionItemProps> = ({ itemData }) => {
    return (
      <div>
        <h3>{`${getFormatedDate(itemData.createdDate)}`}</h3>
        <p>{`${getTransactionType(itemData)} - ${itemData.amount}€`}</p>
      </div>  
    )
}

const getTransactionType = ({type, promoCodeName}: TransactionItemData) => {
  switch (type) {
    case TransactionType.customerFreeService:
      return 'Customer Free Service';
    case TransactionType.customerPromoCode:
      return promoCodeName ? promoCodeName : 'Promotion';
    case TransactionType.customerFullCharged:
      return 'Customer Full Charged';
    case TransactionType.customerFundsFreeze:
      return 'Customer Funds Freeze';
    case TransactionType.customerFundsFreezeExtend:
      return 'Customer Extended Funds Freeze';
    case TransactionType.customerMinCharged:
      return 'Customer Min Charge';
    case TransactionType.customerPayment:
      return 'Customer Payment';
    case TransactionType.customerTip:
      return 'Customer Tip';
    case TransactionType.paymentGatewayFee:
      return 'Gateway Fee';
    case TransactionType.paymentTipToProvider:
      return 'Provider Tip';
    case TransactionType.paymentToProvider:
      return 'Provider Payment';
    case TransactionType.platformFee:
      return 'Platform Fee';
    case TransactionType.flexxiFee:
      return 'FLEXXI Fee';
    case TransactionType.refundToCustomer:
      return 'Refund to Customer';
    case TransactionType.customerBonus:
      return 'Customer Bonus';
    default:
      return '';
  }
}

const getFormatedDate = (dateText: string) => {
  const date = new Date(dateText);
  const year = date.getFullYear()
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const minutesForText = minutes < 10 ? `0${minutes}` : minutes;
  return `${day}/${month + 1}/${year} ${hours}:${minutesForText}`
}
