import React, { useMemo, useState } from 'react';
import { Pager } from '../../components/pager/Pager';
import { Typography, Space, Radio, Avatar } from 'antd';
import './dashboard.css';
import ordersImg from '../../icons/orders.svg';
import './card.css';
import moment from 'moment';
import { formatDate } from '../../utils/helpers';
const { Text } = Typography;

let configs = [
    {
        params: {}
    },
];
const url = `/dashboard/top-orders`;
const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";
const columns = [
    {
        title: 'Order ID',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
    },
    {
        title: 'Customer info',
        render(record: any) {
            return <div><Avatar src={record.customerProfile || defaultImage} /><span style={{ marginLeft: 16 }}>{record.customerName}</span></div>;
        },
        key: 'customerInfo',
    },
    {
        title: 'Provider info',
        render(record: any) {
            return <div><Avatar src={record.providerProfile || defaultImage} /><span style={{ marginLeft: 16 }}>{record.providerName}</span></div>;
        },
        key: 'providerInfo',
    },
    {
        title: 'Working Area',
        dataIndex: 'workingArea',
        key: 'workingArea',
    },
    {
        title: 'Start date',
        dataIndex: 'startDate',
        render(startDate: string) {
            return formatDate(startDate, 'DD.MM.YYYY')
        },
        key: 'startDate',
    },
    {
        title: 'Duration',
        dataIndex: 'hoursCount',
        render(hoursCount: number) {
            return `${hoursCount}h`
        },
        key: 'hoursCount',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render(amount: number) {
            return `${amount}€`
        },
        key: 'amount',
    },
];

export const TopOrders: React.FC = () => {
    const [periodType, setPeriodType] = useState<string>('day')
    const [period, setPeriod] = useState<any>({
        createdDateFrom: moment().format('YYYY-MM-DD'),
        createdDateTo: moment().format('YYYY-MM-DD'),
    })

    configs = useMemo(() => {
        return [{params: {...period}}]
    }, [periodType]);

    const getPeriod = (periodType: string) => {
        switch(periodType) {
            case 'day':
                return {
                    createdDateFrom: moment().format('YYYY-MM-DD'),
                    createdDateTo: moment().format('YYYY-MM-DD'),
                }
            case 'week':
                return {
                    createdDateFrom: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                    createdDateTo: moment().endOf('isoWeek').format('YYYY-MM-DD')
                }
            case 'month':
                return {
                    createdDateFrom: moment().startOf('month').format('YYYY-MM-DD'),
                    createdDateTo: moment().endOf('month').format('YYYY-MM-DD'),
                }
        }
    }

    const handlePeriodChange = (event: any) => {
        const newPeriodType = event.target.value;
        setPeriodType(newPeriodType);
        const newPeriod =  getPeriod(newPeriodType);
        setPeriod(newPeriod)
    }

    const defaultComponent =
        <div className={'imgWrapper'}>
            <img src={ordersImg} alt={'OrdersImg'} />
            <br />
            <Text className={'infoText'}>
                {'There is no orders for this period'}
            </Text>
        </div>

    return(
        <>
            <Space className={'headerCard'} >
                <Text className={'titleText'}>Top orders</Text>
                <Radio.Group  defaultValue="day" value={periodType} onChange={handlePeriodChange} buttonStyle="solid" >
                    <Radio.Button value="day" key='day' className={periodType === 'day' ? 'selectedButton' : 'passiveButton'}>Day</Radio.Button>
                    <Radio.Button value="week" key='week' className={periodType === 'week' ? 'selectedButton' : 'passiveButton'}>Week</Radio.Button>
                    <Radio.Button value="month" key='month' className={periodType === 'month' ? 'selectedButton' : 'passiveButton'}>Month</Radio.Button>
                </Radio.Group>
            </Space>
            <Pager
                configs={configs}
                url={url}
                columns={columns}
                rowClick={() => null }
                notSearchable={true}
                defaultComponent={defaultComponent}
            />
        </>
    )
}