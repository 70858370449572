import React from "react";
import { Select } from "antd";
import { PromoCodeRequest, PromoCodeType, PromoCodeSubType, UpdatedContent } from "./campaingTypes";
import { styles } from "./styles";
import { FreeTimeTypeContent } from './FreeTimeTypeContent';
import { AmountDiscountTypeContent } from './AmountDiscountTypeContent';
import { DiscountPercentTypeContent } from './DiscountPercentTypeContent';

const { Option } = Select;

interface UniquePromoContentProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    disabled: boolean;
}

export const UniquePromoContent: React.FC<UniquePromoContentProps> = ({ modalContent, setModalContent, disabled }) => {

    const handlePromoCodeTypeChange = (type: PromoCodeType) => {
        setModalContent({
            type,
            individual: true,
        })
    }

    const getCapmaingByPromoCodeType = (promoCodeType?: PromoCodeType | PromoCodeSubType) => {
        switch(promoCodeType) {
            case PromoCodeType.ORDER_FREE_TIME: 
                return <FreeTimeTypeContent updatedContent={UpdatedContent.details} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />;
            case PromoCodeType.ORDER_AMOUNT_DISCOUNT:
                return <AmountDiscountTypeContent updatedContent={UpdatedContent.details} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            case PromoCodeType.ORDER_DISCOUNT_PERCENT:
                return <DiscountPercentTypeContent updatedContent={UpdatedContent.details} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
            default: return null;
        }
    }

    return (
        <div>
            <Select onChange={handlePromoCodeTypeChange} value={modalContent.type} style={styles.modalRowItem} disabled={disabled}>
                <Option value={PromoCodeType.ORDER_FREE_TIME}>Free Time</Option>
                <Option value={PromoCodeType.ORDER_AMOUNT_DISCOUNT}>Amount Discount</Option>
                <Option value={PromoCodeType.ORDER_DISCOUNT_PERCENT}>Discount %</Option>
            </Select>
            {getCapmaingByPromoCodeType(modalContent.type)}
        </div>
    )
}