import { ModalContent } from '../pages/contentManager/translations/Translations';
import { authorisedRequest } from './authorisedRequest';

export const newTranslation = (payload: ModalContent) => {
  return authorisedRequest(`/translations`, 'POST', payload);
}

export const editTranslation = (translationId:string, payload: ModalContent) => {
    return authorisedRequest(`/translations/${translationId}`, 'PUT', payload);
}



