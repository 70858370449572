import React, { useContext } from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { MainContext } from '../App';

interface IProps {
  path: string;
  exact: boolean;
}

export const AuthenticatedRoute: React.FC<IProps> = ({ children, ...rest }) => {
  const { userAuthData } = useContext(MainContext);
  return (
    <>
      {userAuthData
        ? <Route {...rest}>{ children }</Route>
        : <Redirect to="/login" />}
    </>
  )
}
