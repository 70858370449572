import { formatText } from '../../../utils/helpers';

export enum PriceList {
    Service = 'Service',
    Provider = 'Provider',
}
export enum SectionType {
    FlexxiCare = 'FLEXXI_CARE',
    RespiteCare = 'RESPITE_CARE',
}

export const configsForServices = [
    {
        label: formatText(SectionType.FlexxiCare),
        params: {
            url: '/service-types/admin',
            flexxiProviderType:SectionType.FlexxiCare,
            type: PriceList.Service,
            removeIndex: [1, 2, 5, 7]
        },
    },
    {
        label: formatText(SectionType.RespiteCare),
        params: {
            url: '/service-types/admin',
            flexxiProviderType:SectionType.RespiteCare,
            type: PriceList.Service,
            removeIndex: [1, 2, 5, 7]
        }
    }
]

export enum ModalTitle {
    NEW_SERVICE_TYPE = 'Add New Service',
    DELETE_SERVICE_TYPE = 'Delete Service',
    EDIT_SERVICE_TYPE = 'Edit Service',
    EDIT_PROVIDER_TYPE = 'Edit Provider TYPE',
    NEW_PROVIDER_TYPE = 'Add New Provider type',
    DELETE_PROVIDER_TYPE = 'Delete Provider type',
}

export enum ErrorCodes {
    SERVICE_TYPE_ALREADY_EXISTS= 'Service Type Already Exists',
    SERVICE_TYPE_NOT_FOUND = 'Service Type Not Found',
    SERVICE_TYPE_MIN_PRICE_VIOLATION = 'Service Type Min Price Violation',
    PROVIDER_TYPE_NOT_FOUND = 'Provider Type Not Found',
    PROVIDER_TYPE_MIN_PRICE_VIOLATION = 'Provider Type Min Price Violation',
    FILE_INVALID_ERROR = 'Invalid File',
    FILE_UPLOAD_ERROR = 'File Upload Fails',
    DEFAULT_ERROR = 'Something went wrong!',
}