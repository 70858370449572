import React from "react";
import { Input, Select } from "antd";
import { PromoCodeRequest, UpdatedContent } from './campaingTypes';
import { styles } from './styles';
import { RangePickerCustom } from './RangePickerCustom';
import { containOnlyLettersAndNumbers } from '../../utils/helpers';
const { Option } = Select;

interface FreeTimeTypeContentProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    updatedContent: UpdatedContent;
    disabled: boolean;
}

export const FreeTimeTypeContent: React.FC<FreeTimeTypeContentProps> = ({ modalContent, setModalContent, updatedContent, disabled }) => {

    const handleMaxUsageChange = (maxUsagePerUser: number) => {
        setModalContent({
            ...modalContent,
            [updatedContent]: {
                ...modalContent[updatedContent],
                maxUsagePerUser,
            }
        })
    }

    const handlePromoCodeNameChange = (event: any) => {
        if(!containOnlyLettersAndNumbers(event.nativeEvent.target.value)){
            return
        }
        setModalContent({
            ...modalContent,
            name: event.nativeEvent.target.value
        })
    }

    const handleFreeMinChange = (event: any) => {
        const { value } = event.nativeEvent.target;
        const numbers = /^[0-9]+$/;
        if (value.match(numbers) || value === '') {
            setModalContent({
                ...modalContent,
                [updatedContent]: {
                    ...modalContent[updatedContent],
                    freeMinutes: parseInt(event.nativeEvent.target.value) || undefined
                }
            })
        }
    }

    const handleOrderMinChange = (event: any) => {
        const { value } = event.nativeEvent.target;
        const numbers = /^[0-9]+$/;
        if (value.match(numbers) || value === '') {
            setModalContent({
                ...modalContent,
                [updatedContent]: {
                    ...modalContent[updatedContent],
                    minOrderMinutesToApply: parseInt(event.nativeEvent.target.value) || undefined
                }
            })
        }
    }

    return (
        <div>
            {updatedContent === UpdatedContent.details && <Input
                value={modalContent.name || ''}
                onChange={handlePromoCodeNameChange}
                style={styles.modalRowItem}
                placeholder='Promo Code Name'
                maxLength={32}
                disabled={disabled}
            />}
            <Input 
                value={modalContent[updatedContent]?.freeMinutes || ''}
                onChange={handleFreeMinChange}
                style={styles.modalRowItem}
                placeholder='Free Minutes'
                suffix="mins"
                disabled={disabled}
            />
            <Input
                value={modalContent[updatedContent]?.minOrderMinutesToApply || ''}
                onChange={handleOrderMinChange}
                style={styles.modalRowItem}
                placeholder='Order Minutes'
                suffix="mins"
                disabled={disabled}
            />
            {updatedContent === UpdatedContent.details && <RangePickerCustom updatedContent={updatedContent} modalContent={modalContent} setModalContent={setModalContent}  disabled={disabled}/>}
            <Select
                value={modalContent[updatedContent]?.maxUsagePerUser}
                placeholder='Max Usage' onChange={handleMaxUsageChange}
                style={styles.modalRowItem}
                disabled={disabled}
            >
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
            </Select>
        </div>
    )
}