import { useState } from 'react';
import { addRelease, editRelease } from '../../../api/releases';
import { EditPayload, ModalContent } from './Releases';

export const useSettingsAction = (updatePage: any, onErrorAction?: any, onSuccessAction?: any, closeModal?: any, setIndex?: any ) => {
  const [editLoading, setEditLoading] = useState(false);
  const [ addLoading, setAddLoading ] = useState(false)

  const addAppRelease = (payload: ModalContent) => {
    setAddLoading(true)
      addRelease(payload)
        .then((resp: any) => {
            if (resp.status === 'ERROR') {
                onErrorAction();
            } else {
                onSuccessAction('New Release');
                updatePage();
                closeModal();
            }
        })
        .catch(() => {
            onErrorAction();
        })
        .finally(() => {
            setAddLoading(false);
        });
  }


  const editAppRelease = (payload: EditPayload, versionId: string) => {
    setEditLoading(true);
    editRelease(payload, versionId)
    .then((resp: any) => {
      if (resp.status === 'ERROR') {
          onErrorAction();
      } else {
          updatePage();
          setIndex(null);
      }
    })
    .catch(() => {
        onErrorAction();
    })
    .finally(() => {
        setEditLoading(false);
    });
  }
  return { addAppRelease, addLoading, editAppRelease, editLoading };
};
