import { useHistory } from 'react-router-dom';
import { InnerLayout } from '../../components/InnerLayout';
import { Layout } from 'antd';
import { Pager } from '../../components/pager/Pager';
import React from 'react';

const url = `/track-areas`;

const configs = [
    {
        label: 'Tracking',
        params: {}
    },
]
const columns = [
    {
        title: 'Email',
        dataIndex: 'email',
        render(email: string) {
            return <a href={`mailto: ${email}`}>{email}</a>
        },
    },
    {
        title: 'Zip code',
        dataIndex: 'zipCode',
    },
];
export const Tracking: React.FC = () => {
    const history = useHistory();

    const onRowClick = (id: string, _:any, record: any) => {
        history.push(`/clients/${record.customerId}/0`)
    }

    return (
        <InnerLayout>
            <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
                <Pager
                    configs={configs}
                    url={url}
                    columns={columns}
                    rowClick={onRowClick}
                />
            </Layout.Content>
        </InnerLayout>
    )

}