export interface PromoCode {
    details: PromoCodeDetails;
    id: string;
    name: string;
    type: PromoCodeType
}


export interface PromoCodeDetails {
    bonusAmount?: number;
    discountAmount?: number;
    discountPercent?: number;
    endDate?: string;
    freeMinutes?: number;
    maxUsagePerUser?: number;
    minOrderAmountToApply?: number;
    minOrderMinutesToApply?: number;
    startDate?: string;
    orderQuantity?: number
    subType?: PromoCodeSubType
}

export enum PromoCodeType {
    ORDER_FREE_TIME = 'ORDER_FREE_TIME',
    ORDER_AMOUNT_DISCOUNT = 'ORDER_AMOUNT_DISCOUNT',
    ORDER_DISCOUNT_PERCENT = 'ORDER_DISCOUNT_PERCENT',
    INVITE_CLIENT_TO_CLIENT = 'INVITE_CLIENT_TO_CLIENT',
    INVITE_CLIENT_TO_PROVIDER = 'INVITE_CLIENT_TO_PROVIDER',
    INVITE_PROVIDER_TO_PROVIDER = 'INVITE_PROVIDER_TO_PROVIDER',
    INVITE_PROVIDER_TO_CLIENT = 'INVITE_PROVIDER_TO_CLIENT',
    CANCELLATION_FREE_MINUTES = 'CANCELLATION_FREE_MINUTES',
    SIGNUP_CLIENT = 'SIGNUP_CLIENT',
    SIGNUP_PROVIDER = 'SIGNUP_PROVIDER',
    ORDER_PROVIDER_BONUS = 'ORDER_PROVIDER_BONUS',
    INVITE_CLIENT_TO_CLIENT_N2 = 'INVITE_CLIENT_TO_CLIENT_N2',
    INVITE_CLIENT_TO_PROVIDER_N2 = 'INVITE_CLIENT_TO_PROVIDER_N2',
    INVITE_PROVIDER_TO_PROVIDER_N2 = 'INVITE_PROVIDER_TO_PROVIDER_N2',
    INVITE_PROVIDER_TO_CLIENT_N2 = 'INVITE_PROVIDER_TO_CLIENT_N2',
}

export enum PromoCodeSubType {
    CLIENT_ORDER_FREE_TIME = 'CLIENT_ORDER_FREE_TIME',
    CLIENT_ORDER_AMOUNT_DISCOUNT = 'CLIENT_ORDER_AMOUNT_DISCOUNT',
    CLIENT_ORDER_DISCOUNT_PERCENT = 'CLIENT_ORDER_DISCOUNT_PERCENT',
    PROVIDER_BONUS = 'PROVIDER_BONUS',
    NO_CAMPAIGN = 'NO_CAMPAIGN',
}

export enum CampaingType {
    SimpleCampaing = 'Campaign',
    InviteFriend = 'Invite Friend',
    UniquePromo = 'Unique Promo',
}

export enum CampaignSubType {
    REGISTRATION_REFERRAL='REGISTRATION_REFERRAL',
    Completed_Order_Referral='Completed_Order_Referral',
}

export interface PromoCodeRequest {
    name?: string;
    type?: PromoCodeType;
    details?: PromoCodeDetails;
    id?: string;
    inviterDetails?: PromoCodeDetails | null;
    receiverDetails?: PromoCodeDetails | null;
    individual?: boolean;
    providerTypeIds?: string[];
}

export enum UpdatedContent {
    inviterDetails = 'inviterDetails',
    receiverDetails = 'receiverDetails',
    details = 'details',
}