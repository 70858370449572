import React, { useContext } from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import {
  Menu,
  Button,
} from 'antd';
import { isUserRoleMarketing, isUserSuperAdmin } from '../utils/helpers';
import { MainContext } from '../App';
import { LOCAL_STORAGE } from '../utils/global-enums';

const {SubMenu} = Menu;

export const MainMenu: React.FC = () => {
  const { userAuthData, setUserAuthData } = useContext(MainContext);
  const location = useLocation();

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE.AUTH_DATA);
    setUserAuthData(null);
    window.location.href = '/login';
  };

  return (
    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[location.pathname]}>
      <Menu.Item key="/dashboard"><Link to="/dashboard">Dashboard</Link></Menu.Item>
      <Menu.Item key="/clients/0"><Link to="/clients/0">Customers</Link></Menu.Item>
      <Menu.Item key="/providers/0/0"><Link to="/providers/0/0">Providers</Link></Menu.Item>
      <SubMenu key='jobs' title='Jobs'>
        <Menu.Item key="/orders/pending"><Link to="/orders/pending">Pending Jobs</Link></Menu.Item>
        <Menu.Item key="/orders/active"><Link to="/orders/active">Active Jobs</Link></Menu.Item>
        <Menu.Item key="/orders/complete"><Link to="/orders/complete">Complete Jobs</Link></Menu.Item>
        <Menu.Item key="/orders/cancelled"><Link to="/orders/cancelled">Cancelled orders</Link></Menu.Item>
        <Menu.Item key="/orders/all"><Link to="/orders/all">All orders</Link></Menu.Item>
      </SubMenu>
      <Menu.Item key="/respiteCare/0/0"><Link to="/respiteCare/0/0">Respite Care</Link></Menu.Item>
      <Menu.Item key="/helpCenter"><Link to="/helpCenter">Help Center</Link></Menu.Item>
       {!isUserRoleMarketing(userAuthData) &&
           <SubMenu key='settings' title='Settings'>
               <Menu.Item key="/settings/serviceCities"><Link to="/settings/serviceCities">Service Cities</Link></Menu.Item>
               {isUserSuperAdmin(userAuthData) && <Menu.Item key="/settings/releases"><Link to="/settings/releases">Releases</Link></Menu.Item>}
           </SubMenu>
       }
      <SubMenu key='contentManager' title='Content Manager'>
        <Menu.Item key="/contentManager/helpCenter"><Link to="/contentManager/helpCenter">Help Center</Link></Menu.Item>
        <Menu.Item key="/contentManager/faq"><Link to="/contentManager/faq">FAQ</Link></Menu.Item>
        <Menu.Item key="/contentManager/services"><Link to="/contentManager/services">Services</Link></Menu.Item>
        {!isUserRoleMarketing(userAuthData) && <Menu.Item key="/contentManager/translations"><Link to="/contentManager/translations">Translations</Link></Menu.Item>}
        {!isUserRoleMarketing(userAuthData) && <Menu.Item key="/contentManager/billboard"><Link to="/contentManager/billboard">Billboard</Link></Menu.Item>}
      </SubMenu>
      <Menu.Item key="/campaigns/0"><Link to="/campaigns/0">Campaigns</Link></Menu.Item>
      <Menu.Item key="/tracking"><Link to="/tracking">Tracking</Link></Menu.Item>
      {isUserSuperAdmin(userAuthData) && <Menu.Item key="/admins"><Link to="/admins">Admins</Link></Menu.Item>}
      <Menu.Item key="logout"><Button onClick={logout}>Log out</Button></Menu.Item>
    </Menu>
  );
};
