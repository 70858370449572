import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LOCAL_STORAGE } from '../../utils/global-enums';
import { MainContext } from '../../App';

declare var API_ENDPOINT: string;

export enum LoginStatus {
  INITIAL = 'INITIAL',
  VERIFIED = 'VERIFIED',
  PENDING_VERIFIED = 'PENDING_VERIFIED',
  ERROR = 'ERROR',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
}

export interface LoginResult {
  status: LoginStatus
}

export interface LoginResponse {
  mobileNumberMasked: string;
  smsCode?: string;
  twoFactorVerifyToken: string;
}

interface ReturnType {
  loading: boolean;
  logIn(params: { email: string; password: string }):  void;
  loginResult: LoginResult;
  loginResp: LoginResponse;
  verify(verificationCode: string): void; 
}

export function useLogin(): ReturnType {
  const { setUserAuthData } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [loginResult, setLoginResult] = useState<LoginResult>({ status: LoginStatus.INITIAL });
  const [loginResp, setLoginResp] = useState<LoginResponse>({ mobileNumberMasked: '', smsCode: '', twoFactorVerifyToken: ''});



  const logIn = ({ email, password }: any) => {
    setLoading(true);
    let uuid = localStorage.getItem('UUID');
    if (!uuid) {
      uuid = uuidv4();
    }

    const loginData = {
      device: {
        name: "chrome",
        type: "WEB",
        uniqueId: uuid,
        version: "67",
        appType: "WEB_ADMIN"
      },
      email,
      password,
    };

    return fetch(
        `${API_ENDPOINT}/signin/two-factor`,
        { 
          method: 'POST', 
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(loginData),
        }
      )
      .then(async resp => {
        setLoading(false);
        if (resp.status === 200) {
          const { mobileNumberMasked, twoFactorVerifyToken, smsCode } = await resp.json();
          setLoginResp({
            mobileNumberMasked,
            smsCode,
            twoFactorVerifyToken,
          })
          localStorage.setItem('UUID', uuid!);
          setLoginResult({status: LoginStatus.PENDING_VERIFIED});
        } else if (resp.status === 429) {
            setLoginResult({ status: LoginStatus.LIMIT_EXCEEDED })
          } else {
              setLoginResult({ status: LoginStatus.ERROR });
            };
      })
      .catch(() => {
        setLoading(false);
        setLoginResult({ status: LoginStatus.ERROR });
      })
    }

  const verify = (verificationCode: string) => {
    setLoading(true);
    return fetch(
      `${API_ENDPOINT}/signin/two-factor/verify`,
      { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          smsCode: verificationCode,
          twoFactorVerifyToken: loginResp.twoFactorVerifyToken,
        }),
      }
    )
    .then(async resp => {
      const respClone = resp.clone();
      setLoading(false);
      if (resp.status === 200) {
        localStorage.setItem(LOCAL_STORAGE.AUTH_DATA, JSON.stringify(await resp.json()));
        setUserAuthData(await respClone.json());
        setLoginResult({ status: LoginStatus.VERIFIED });
      } else {
        setLoginResult({ status: LoginStatus.VERIFICATION_ERROR });
      }
    })
    .catch(() => {
      setLoading(false);
      setLoginResult({status: LoginStatus.VERIFICATION_ERROR})
    })
  }

  return { loading, logIn, loginResult, loginResp, verify };
}
