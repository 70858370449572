import { LOCAL_STORAGE } from "../utils/global-enums";

export interface MainContextState {
    userAuthData: UserAuthData | null;
    setUserAuthData(data: UserAuthData | null): void; 
}

export interface UserAuthData {
    accessToken: string;
    refreshToken: string;
    tokenType: string;
    expiresIn: number;
}
  

  
export const mainDefaultContext: MainContextState = {
    userAuthData: localStorage.getItem(LOCAL_STORAGE.AUTH_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.AUTH_DATA)!) : null,
    setUserAuthData: (_: UserAuthData | null) => {},
}
  
