import React from "react";
import { Input } from "antd";
import { PromoCodeRequest, UpdatedContent } from './campaingTypes';
import { styles } from './styles';
import { RangePickerCustom } from './RangePickerCustom';
import { containOnlyLettersAndNumbers } from '../../utils/helpers';

interface SignUpProviderBonusTypeContentProps {
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    updatedContent: UpdatedContent;
    disabled: boolean;
}


export const SignUpProviderBonusTypeContent: React.FC<SignUpProviderBonusTypeContentProps> = ({ modalContent, setModalContent, updatedContent, disabled }) => {

    const handlePromoCodeNameChange = (event: any) => {
        if(!containOnlyLettersAndNumbers(event.nativeEvent.target.value)){
            return
        }
        setModalContent({
            ...modalContent,
            name: event.nativeEvent.target.value
        })
    }

    const handleBonusAmountChange = (event: any) => {
        const { value } = event.nativeEvent.target;
        const numbers = /^[0-9]+$/;
        if (value.match(numbers) || value === '') {
            setModalContent({
                ...modalContent,
                details: {
                    ...modalContent.details,
                    bonusAmount: parseInt(event.nativeEvent.target.value) || undefined
                }
            })
        }
    }

    return (
        <div>
            <Input value={modalContent.name || ''} onChange={handlePromoCodeNameChange} style={styles.modalRowItem} placeholder='Promo Code Name' maxLength={32} disabled={disabled} />
            <Input value={modalContent.details?.bonusAmount || ''} onChange={handleBonusAmountChange} style={styles.modalRowItem} placeholder='Bonus Amount' suffix="€" disabled={disabled} />
            <RangePickerCustom updatedContent={updatedContent} modalContent={modalContent} setModalContent={setModalContent} disabled={disabled} />
        </div>
    )
}