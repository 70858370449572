import { PromoCodeRequest } from '../pages/campaings/campaingTypes';
import { authorisedRequest } from './authorisedRequest';

export const deleteCampaing = (id: string) => {
  return authorisedRequest(`/promo-codes/${id}`, 'DELETE');
}

export const createNewCampaing = (payload: PromoCodeRequest) => {
    return authorisedRequest(`/promo-codes`, 'POST', payload);
}

export const createNewInviteFriendCampaing = (payload: PromoCodeRequest) => {
    return authorisedRequest(`/promo-codes/invite-friend`, 'POST', payload);
}


