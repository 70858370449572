import React, { useContext, useState} from "react";

import { Layout, Select, Form, Button, Modal, notification } from 'antd';

import { InnerLayout } from '../../../components/InnerLayout';
import { Pager } from '../../../components/pager/Pager';
import { EditOutlined } from "@ant-design/icons";
import { isUserSuperAdmin } from "../../../utils/helpers";
import { MainContext } from "../../../App";
import { TranslationModalContent } from './TranslationModalContent';
import { newTranslation, editTranslation } from "../../../api/translations";

const { Option } = Select;

export interface TranslationValue {
    language: string;
    value: string;
}

export interface ModalContent {
    id?: string;
    key?: string;
    appType?: string;
    values: TranslationValue[];
    type?: string;
}


export const Translations = ({ configs }: { configs?: any }) => {
    const modifiedConfigs = configs.map((config: any) => {
        return {
            ...config,
            params: {
                ...config.params,
                TYPE: 'TEXT'
            }
        }
    });

    const defaultModalContent = {
        values: [
            { language: 'en', value: ''}, 
            {language: 'de', value: ''},
    ]}
    const [forceReload, setForceReload] = useState(0);
    const [type, setType] = useState('TEXT');
    const [configsForTranslations, setConfigs] = useState(modifiedConfigs);
    const [isModalOpened, toggleModal] = useState(false);
    // eslint-disable-next-line
    const { userAuthData, setUserAuthData } = useContext(MainContext);
    const [ modalType, setModalType] = useState('new');
    const [ modalContent, setModalContent ] = useState<ModalContent>(defaultModalContent);
    const [ loading, setLoading ] = useState(false);
    const [ appType, setAppType ] = useState('CLIENT')
    const url = `/translations/admin`;

    const columns = [
        {
            title: 'Keyword',
            dataIndex: 'key',
        },
        {
            title: 'English',
            dataIndex: 'values',
            render: (values: any) => {
                return values.filter((value: any) => {
                    return value.language === 'en'
                })[0]?.value
            }
        },
        {
            title: 'German',
            dataIndex: 'values',
            render: (values: any) => {
                return values.filter((value: any) => {
                    return value.language === 'de'
                })[0]?.value
            }
        },
        {
            title: '',
            render: (record: any) => {
                return (
                    <div style={{ width: 'fit-content'}}>
                      <Button
                          onClick={handleEditClick(record)}
                          type='link'
                          icon={<EditOutlined />}
                      />
                    </div>
              )}
        }
    ];

    const handleEditClick = (record: any) => () => {
        setModalType('edit');
        toggleModal(true);
        setModalContent({
            ...record,
            values: [
                record.values.filter((value: TranslationValue) => value.language === 'en')[0] || defaultModalContent.values[0],
                record.values.filter((value: TranslationValue) => value.language === 'de')[0] || defaultModalContent.values[1],
             ]
        });
    }

    const handleTypeChange = (type: string) => {
        const configs = configsForTranslations.map((config: any) => {
            return {
                ...config,
                params: {
                    ...config.params,
                    type
                }
            }
        });
        setType(type);
        setConfigs(configs);
    }


    
    const handleNewTranslationButtonClick = () => {
        toggleModal(true);
        setModalContent({
            ...modalContent,
            appType,
            type: 'TEXT',
        })
        setModalType('new');
    }

    const handleCancelClick = () => {
        toggleModal(false);
        setModalContent(defaultModalContent);
    }

    const showNotFilleError = () => {
        notification.error({
            message: 'All fields should be filled!',
            duration: 2,
        })
    }

    const handleTranslationChange = (modalType: string) => async () => {
        if (!modalContent.key?.trim()) {
            return showNotFilleError();
        }
        const values = modalContent.values.map((item:TranslationValue)=>{
            return {...item, value:item.value.trim()};
        });
        setLoading(true);
        let resp;
        
        if(modalType === 'new') {
            resp = await newTranslation({
                ...modalContent,
                key: modalContent.key.trim(),
                values
            });
        }
        if(modalType === 'edit') {
            resp = await editTranslation(modalContent.id!, {
                key: modalContent.key.trim(),
                values,
            });
        }

        setLoading(false);
        toggleModal(false);
        setModalContent(defaultModalContent);
        if( resp.status === 'ERROR') {
            return notification.error({
                message: 'Something went wrong!',
                duration: 2,
            })
        }
        setForceReload(Math.random());
    }

    const getModalFooter = (modalType: string) => {
        switch(modalType) {
            case 'new':
                return [
                    <Button onClick={handleCancelClick} key='cancel'>Cancel</Button>,
                    <Button onClick={handleTranslationChange('new')} loading={loading} type='primary' key='create'>Create</Button>
                ]
            case 'edit': 
                return [
                    <Button onClick={handleCancelClick} key='cancel'>Cancel</Button>,
                    <Button onClick={handleTranslationChange('edit')} loading={loading} type='primary' key='edit'>Save</Button>
                ]
            default: return null;
        }
    }

  const headerComponentsInLeft = [
    <Form.Item key='type'>
        <Select value={type} onChange={handleTypeChange}>
            <Option value='TEXT'>Text</Option>
            <Option value='PUSH'>Push</Option>
        </Select>
    </Form.Item>
  ]

  const headerComponentsInRight = [
      <div  key='newTranslation' style={styles.headerRightComponent as React.CSSProperties}>
          <Button type='primary' onClick={handleNewTranslationButtonClick}>New Translation</Button>
      </div>
  ]

  const handleLabelChange = (labelIndex: number) => {
      setAppType(configsForTranslations[labelIndex]?.params.appType)
  }

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={styles.Layout}>
        <Pager
            headerComponentsInLeft={headerComponentsInLeft}
            headerComponentsInRight={type === 'TEXT' && isUserSuperAdmin(userAuthData) ? headerComponentsInRight : null}
            url={url}
            columns={columns}
            configs={configsForTranslations}
            onLabelChange={handleLabelChange}
            rowClick={() => null}
            forceReload={forceReload}
        />
      </Layout.Content>
        <Modal
            visible={isModalOpened}
            title={modalType === 'new' ? 'New Translation' : 'Edit Translation'}
            footer={getModalFooter(modalType)}
            onCancel={() => { toggleModal(false); setModalContent(defaultModalContent) }}
        >
            <TranslationModalContent modalContent={modalContent} setModalContent={setModalContent} />
        </Modal>
    </InnerLayout>
  )
}

const styles = {
    Layout: {
        padding: '20px 30px',
        marginTop: 64,
    },
    headerRightComponent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    }
}
