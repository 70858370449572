import React, { useState, useMemo } from 'react';
import { Pager } from '../../components/pager/Pager';
import { Typography, Space, DatePicker } from 'antd';
import providersImg from '../../icons/providers.svg';
import './card.css';
import moment from 'moment';
const { Text } = Typography;
const { RangePicker } = DatePicker;

let configs = [{
    params: {}
}];
const url = `/dashboard/new-providers`;
const columns = [
    {
        title: 'Full Name',
        dataIndex: 'fullName',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
];

export const NewProviders: React.FC = () => {
    const [period, setPeriod] = useState<any>({
        createdDateFrom: moment().format('YYYY-MM-DD'),
        createdDateTo:moment().format('YYYY-MM-DD'),
    })

    configs = useMemo(() => {
     return [{params: {...period}}]
    }, [period]);

    const handlePeriodChange = (period: any) => {
        period &&
        setPeriod({
            createdDateFrom: period[0]?.format('YYYY-MM-DD'),
            createdDateTo: period[1]?.format('YYYY-MM-DD'),
        })
    }

    const defaultComponent =
        <div className={'imgWrapper'}>
            <img src={providersImg} alt={'ProvidersImg'} />
            <br />
            <Text className={'infoText'}>
                {'There is no providers for this period'}
            </Text>
        </div>

    return(
        <>
            <Space className={'headerCard'} >
                <Text className={'titleText'}>New providers</Text>
                <RangePicker
                    value={period?.createdDateFrom ? [moment(period?.createdDateFrom), moment(period?.createdDateTo)] : null}
                    onChange={handlePeriodChange}
                />
            </Space>
            <Pager
                configs={configs}
                url={url}
                columns={columns}
                rowClick={() => null }
                notSearchable={true}
                defaultComponent={defaultComponent}
                showHeader={false}
            />
        </>

    )
}