import React from 'react';
import { Layout } from 'antd';
import { InnerLayout } from '../../../components/InnerLayout';
import { Pager } from '../../../components/pager/Pager';
import './serviceCities.css';

export interface ICityPayload {
  city: string;
  countryCode: string;
  createdDate: string;
  id: string;
  label: string;
  value: string;
}

export const ServiceCities = () => {
  const configs = [
    {
      label: 'Service Cities',
      params: {
        url: `/service-cities`,
        size: 10,
      },
      sort: 'city',
    },
  ]

  const url = `/service-cities`;

  const columns = [
    {
      title: 'Country Code',
      dataIndex: 'countryCode',
      width: '50%',
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: '50%',
    },
  ];


  return (
    <InnerLayout>
        <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
          <Pager
              url={url}
              columns={columns}
              configs={configs}
              rowClick={() => {}}
          />
        </Layout.Content>
    </InnerLayout>
  )
}
