export enum ModalTitle {
    NEW_ADMIN = 'New Admin',
    DELETE_ADMIN = 'Delete Admin',
    EDIT_ADMIN = 'Edit Admin',
}

export enum ErrorCodes {
    USER_MOBILE_ALREADY_IN_USE = 'Mobile Number Already In Use',
    USER_EMAIL_ALREADY_IN_USE = 'Email Already In Use',
    DEFAULT_ERROR = 'Something went wrong!',

}

export enum User {
    Admin= 'ADMIN',
    Marketing = 'MARKETING',
}