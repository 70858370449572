import React, { useContext, useState } from 'react';
import { Layout, Select, Form, Button, Modal, Space, Input } from 'antd';

import { InnerLayout } from '../../../components/InnerLayout';
import { Pager } from '../../../components/pager/Pager';
import { HelpCenterItem, HelpCenterItemType } from './HelpCenterItem';
import { createItem, editItem } from '../../../api/helpCenterItems';
import { isUserRoleMarketing } from '../../../utils/helpers';
import { MainContext } from '../../../App';

const { Option } = Select;
const { TextArea} = Input;

export const ContentManagerHelpCenter = ({ configs }: { configs?: any }) => {
    const modifiedConfigs = configs.map((config: any) => {
        return {
            ...config,
            params: {
                ...config.params,
                language: 'EN'
            }
        }
    });
    const [forceReload, setForceReload] = useState(0);
    const [activeHelpCenterItem, setActiveHelpCenterItem] = useState<HelpCenterItemType | null>();
    const [language, setLanguage] = useState('EN');
    const [configsForCMHelpCenter, setConfigs] = useState(modifiedConfigs);
    const [isQuestionModalOpened, toggleQuestionModal] = useState(false);
    const { userAuthData } = useContext(MainContext);
    const url = `/help-center/items`;

    const columns = [
        {
            title: 'Type',
            dataIndex: 'serviceType',
            render(serviceType: string) {
                return getTypeText(serviceType, language)
            }
        },
        {
            title: 'Description',
            dataIndex: 'text',
        },
    ];

    const handleLanguageChange = (language: string) => {
        const configs = configsForCMHelpCenter.map((config: any) => {
            return {
                ...config,
                params: {
                    ...config.params,
                    language
                }
            }
        });
        setLanguage(language);
        setConfigs(configs);
    }

    const rowClick = (id: string, _: any, record: any) => {
        setActiveHelpCenterItem(record);
    };

    const onUpdateItem = () => {
        setActiveHelpCenterItem(null);
        setForceReload(Math.random());
    }
    
    const handleNewQuestionButtonClick = () => {
        toggleQuestionModal(true);
    }

  const headerComponentsInLeft = [
    <Form.Item key='language'>
        <Select value={language} onChange={handleLanguageChange}>
            <Option value='EN'>English</Option>
            <Option value='DE'>German</Option>
        </Select>
    </Form.Item>
  ]

  const headerComponentsInRight = [
      <Button type='primary' onClick={handleNewQuestionButtonClick} key='newQuestion' disabled={isUserRoleMarketing(userAuthData)}>New Question</Button>
  ]

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={styles.Layout}>
        <Pager
            headerComponentsInLeft={headerComponentsInLeft}
            headerComponentsInRight={headerComponentsInRight}
            url={url}
            columns={columns}
            configs={configsForCMHelpCenter}
            rowClick={rowClick}
            forceReload={forceReload}
        />
      </Layout.Content>
      {activeHelpCenterItem &&
        <Layout.Sider
          width={400}
          style={{
            padding: '84px 30px 84px 0',
            backgroundColor: 'transparent',
            overflow: 'auto',
            height: '100vh',
          }}
        >
            {activeHelpCenterItem && <HelpCenterItem language={language} item={activeHelpCenterItem} onUpdate={onUpdateItem} /> }
        </Layout.Sider>}
        <Modal
            visible={isQuestionModalOpened}
            title='New Question'
            footer={null}
            onCancel={() => toggleQuestionModal(false)}
        >
            <QuestionModalContent
                onModalClose={() => toggleQuestionModal(false)}
                onUpdatePage={() => setForceReload(Math.random())}
            />
        </Modal>
    </InnerLayout>
  )
}

interface QuestionModalContentProps {
    questionData?: HelpCenterItemType;
    onModalClose: () => void;
    onUpdatePage: () => void;
}

export const QuestionModalContent: React.FC<QuestionModalContentProps> = ({ onModalClose, onUpdatePage, questionData }) => {
    const [language, setLanguage] = useState('EN');
    const [userType, setUserType] = useState('PROVIDER');
    const [questionType, setQuestionType] = useState(questionData?.serviceType ?? 'INAPPROPRIATE_BEHAVIOR');
    const [description, setDescription] = useState(questionData?.text ?? '');
    const [loading, setLoading] = useState(false); 

    const handleDescriptionChange = (event: any) => {
        setDescription(event.currentTarget.value)
    }

    const handleSubmitClick = async () => {
        setLoading(true);
        const item = questionData ? {
            serviceType: questionType,
            text: description
        } : {
            language,
            type: userType,
            serviceType: questionType,
            text: description
        };
        const submitResp = questionData ? await editItem(item, questionData.id) : await createItem(item);
        setLoading(false);
        if (submitResp.status !== 'ERROR') {
            onModalClose();
            onUpdatePage();
        }
    }

    return (
        <React.Fragment>
            {!questionData &&<Space>
                <Select onChange={setUserType} value={userType} style={styles.NewQestionModalOptionsSelect}>
                    <Option value='PROVIDER'>Provider</Option>
                    <Option value='CLIENT'>Client</Option>
                </Select>
                <Select onChange={setLanguage} value={language} style={styles.NewQestionModalOptionsSelect}>
                    <Option value='EN'>English</Option>
                    <Option value='DE'>German</Option>
                </Select>
            </Space>}
            <Select onChange={setQuestionType} value={questionType} style={styles.NewQestionModalTypesSelect}>
                <Option value={'INAPPROPRIATE_BEHAVIOR'}>{getTypeText('INAPPROPRIATE_BEHAVIOR', language)}</Option>
                <Option value={'MISSING_ITEMS'}>{getTypeText('MISSING_ITEMS', language)}</Option>
                <Option value={'OTHER'}>{getTypeText('OTHER', language)}</Option>
                <Option value={'REVIEW_SERVICE_AND_PAYMENT'}>{getTypeText('REVIEW_SERVICE_AND_PAYMENT', language)}</Option>
            </Select>
            <TextArea value={description} onChange={handleDescriptionChange} placeholder='Description' />
            <Space style={styles.ButtonsContainer as React.CSSProperties}>
                <Button onClick={onModalClose} style={styles.Button}>Cancel</Button>
                <Button loading={loading} onClick={handleSubmitClick} style={styles.Button} type='primary'>
                    {questionData ? 'Save' : 'Create'}
                </Button>
            </Space>
        </React.Fragment>
    );
}

export const getTypeText = (serviceType: string, language: string) => {
    switch(serviceType) {
        case 'INAPPROPRIATE_BEHAVIOR': {
            return language === 'EN' ? 'Inappropriate Behavior' : 'Unangemessenes Verhalten';
        }

        case 'MISSING_ITEMS': {
            return language === 'EN' ? 'Missing Items' : 'Fehlende Gegenstände';
        }
        case 'OTHER': {
            return language === 'EN' ? 'Other' : 'Andere Probleme';
        }
        case 'REVIEW_SERVICE_AND_PAYMENT': {
            return language === 'EN' ? 'Review Service and Payment' : 'Überprüfungsdienst und Zahlung'
        }
        default:
            return 'N/A'
    }
}

const styles = {
    Layout: {
        padding: '20px 30px',
        marginTop: 64,
    },
    ButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        margin: '16px 8px 0'
    },
    Button: {
        width: 90,
    },
    NewQestionModalTypesSelect: {
        width: '100%',
        margin: '16px 0'
    },
    NewQestionModalOptionsSelect : {
        minWidth: 145,
    }
}
