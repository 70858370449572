import { authorisedRequest } from './authorisedRequest';

interface NewAdminPayload {
    fullName: string;
    email: string;
    countryCode: string;
    mobile: string;
    role: string;
}

export interface EditAdminPayload {
    fullName?: string;
    email?: string;
    countryCode?: string;
    mobile?: string;
    role?: string;
}

export const createAdmin = (payload: NewAdminPayload) => {
    return authorisedRequest(`/admins`, 'POST', payload);
}

export const deleteAdmin = (adminId: string) => {
    return authorisedRequest(`/admins/${adminId}`, 'DELETE');
}

export const editAdmin = (adminId: string, editedPayload: EditAdminPayload) => {
    return authorisedRequest(`/admins/${adminId}`, 'PUT', editedPayload);
}


