import React, { useContext, useState } from 'react';
import ISO6391 from 'iso-639-1';
import { Skeleton, Card, Descriptions, Button, Input, Modal, Spin, Typography, DatePicker, Select, Space } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import moment from 'moment'
import QRCode from "react-qr-code";
import { useProviderActions } from './useProviderActions';
import './carousel.css';
import { formatDate, isUserRoleMarketing, textToSentence } from '../../utils/helpers';
import { MainContext } from '../../App';
import { commonDocuments, AllCitizenHouseHoldHelperDocumentsModels, AllNonCitizenHouseHoldHelperDocumentsModels, AllCitizenCareHelperDocumentsModels, AllNonCitizenCareHelperDocumentsModels } from './ProviderDocuments';
const { Paragraph } = Typography;
const { Option } = Select;
const { TextArea } = Input;


interface Language {
  id: string;
  name: string;
}
interface LocalDataType {
  provider: {
    blocked?: boolean,
    fullName?: string,
    type?: string,
    providerTypeId?: string | null;
    birthDate?: string,
    gender?: Gender,
    user?: {
      email?: string,
      comment?: string,
    },
    address?: Address,
    languages?:Language[],
    workingPlaces?: IWorkingPlace[],
    availability?: string,
    shortBio?: string,
    longBio?: string,
    position?: string,
  }
}

enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

interface Address {
  country?: string;
  city?: string;
  region?: string;
  street?: string;
  street1?: string;
  buildingNumber?: string;
  postalCode?: string;
}

const dateFormat = 'YYYY/MM/DD';

export const ProviderDetails = ({ id, onChange, data, loading, hasRequiredDocs, providerTypes }: { loading: boolean, data: any, id: string, onChange: Function, hasRequiredDocs: boolean, providerTypes: any}) => {

  const [ accountStatus, setAccountStatus ] = useState();
  const [ paymentVerificationErrors, setPaymentErrors ]  = useState([]);
  const { userAuthData } = useContext(MainContext);
  const [ localData, setLocalData ] = useState<LocalDataType>({ provider: {} });
  const [ isLanguagesEditable, setLanguagesEditable ] = useState(false);
  const [ editedLanguages, setEditedLanguages] = useState<Language[]>([]);
  const [ isModalRejectReasonVisible, setModalRejectReasonVisible ] = useState(false);
  const [ rejectReason, setRejectReason ] = useState('');
  const [commentText, setCommentText] = useState(data.provider.user?.comment); 
  const { loading: actionInProgress, veifyLoading, updateLoading, approve, reject, verify, update, changeProviderComment } = useProviderActions(id, onChange, setAccountStatus, setPaymentErrors);

  const handleInfoChange = async (payload: any, loadFor: string) => {
    setLocalData({ provider: {...localData.provider, ...payload }, });
    const resp = loadFor === 'languages' ? await update({languages: payload?.languages?.map((language: any) => language.id)}, loadFor) : await update(payload, loadFor);
    resp?.successCode === "OK" ? setLocalData({ provider: {...resp.content, address: resp.content.addresses[0]}}) : setLocalData({ provider: { ...data.provider, address: data.provider.addresses[0]}});
  }

  React.useEffect(() => {
    setCommentText(data.provider.user?.comment)
  }, [data.provider.user])

  const onEditLanguages = () => {
    setEditedLanguages([]);
    setLanguagesEditable(!isLanguagesEditable)
  }

  const onSaveLanguages = () => {
    handleInfoChange({ languages: editedLanguages }, 'languages');
    setEditedLanguages([]);
    setLanguagesEditable(false);
  }

  const handleLanguagesChanges = (newLanguages:  any) => {
    const languages = newLanguages?.map((newLanguage: any) => {
      const splited = newLanguage?.split('#');
      return {
        id: splited[0],
        name: splited[1],
      }
    })
    setEditedLanguages(languages);
  }

  const getExperienceYear = (experience?: number) => {
    switch(experience) {
      case 0: return 'Zero to two years';
      case 2: return 'Two to five years';
      case 5: return 'More than five years';
      default: return 'None specified';
    }
  }

  const handleBlockClick = () => {
    handleInfoChange({ isBlocked: !(localData.provider.blocked ?? data.provider.blocked)}, 'block');
  }

  const handleCommentSave = () => {
    changeProviderComment(data.provider.user.id, commentText).then(() => {
      setLocalData({ provider: {...localData.provider, user: {
        ...localData.provider.user,
        comment: commentText
      } }, });
    })
  }

  const handleCommentChange = (e: any) => {
    setCommentText(e.target.value);
  }
  let canApprove = false;

  // canApprove = data.provider.documents?.every((document: any) => {
  //   return document.status === 'OK'
  // });

  const providerTypeName = providerTypes.find((type: any) => type.id === data?.provider?.providerTypeId)?.name;
  const AllCitizenDocumentsModels =
      providerTypeName !== 'Household Helper'?
          // documents.find((doc: any) => commonDocuments.slice(0, commonDocuments.length-1).includes(doc.type))) ?
          // || oldDocuments.find((doc: any) => commonDocuments.slice(0, commonDocuments.length-1).includes(doc.type))) ?
          AllCitizenCareHelperDocumentsModels : AllCitizenHouseHoldHelperDocumentsModels;


  const AllNonCitizenDocumentsModels =
      providerTypeName !== 'Household Helper' ?
          // documents.find((doc: any) => commonDocuments.slice(0, commonDocuments.length-1).includes(doc.type)))?
          // || oldDocuments.find((doc: any) => commonDocuments.slice(0, commonDocuments.length-1).includes(doc.type))) ?
          AllNonCitizenCareHelperDocumentsModels : AllNonCitizenHouseHoldHelperDocumentsModels;
  const documentsModel = data.provider.isCitizen ? AllCitizenDocumentsModels : AllNonCitizenDocumentsModels;

  const actualDocTypes = data?.provider?.documentTypes?.filter((docType: any) => {
    return documentsModel.find((item) => item.type === docType.type)
  })
  canApprove = actualDocTypes?.every((documentType: any) => {
    const filteredDocumentTypes = documentType?.documents?.filter((item: any) => item.status !== 'ARCHIVE');
        if (filteredDocumentTypes?.length){
          return (filteredDocumentTypes?.every((document: any) => {
            return (document.status === 'OK');
          }));
        }
        return  (commonDocuments.includes(documentType.type))

  });

  let actions = [
    <Button key='Approve' disabled={ isUserRoleMarketing(userAuthData) || !canApprove || loading || (accountStatus && accountStatus !== 'ACTIVE') || data.provider.accountStatus !== 'ACTIVE' || !hasRequiredDocs} type="primary" onClick={data.provider?.status === 'ACTIVE' ? () => update({needsAction: false}, 'approving')  : approve} loading={actionInProgress || updateLoading === 'approving'}>Approve</Button>,
    <Button key='Verify' disabled={isUserRoleMarketing(userAuthData) || loading || (accountStatus && accountStatus !== 'NOT_VERIFIED' && data.provider.status !== 'ACTIVE') || (data.provider.accountStatus && data.provider.accountStatus !== 'NOT_VERIFIED' && data.provider.status !== 'ACTIVE')}  type="default" onClick={verify} loading={veifyLoading}>Verify</Button>,
    <Button onClick={() => setModalRejectReasonVisible(true)} disabled={isUserRoleMarketing(userAuthData)} danger key='Decline'>Decline</Button>,
  ];

  if (data.provider.status === 'ACTIVE') {
    if (data.provider.needsAction) {
      actions = actions.filter((action, index) => {
        if (index !== 2) {
          return action;
        }
        return null;
      })
    } else {
      actions = actions.filter((action, index) => {
        if (index === 1) {
          return action;
        }
        return null;
      })
    }
  } else {
    if (data.provider.status !== 'IN_REVIEW') {
      actions = [];
    }
  }


  actions.push(<Skeleton loading={loading} active>
      <Button
        loading={updateLoading === 'block'}
        onClick={handleBlockClick}
        type={localData.provider.blocked ?? data.provider.blocked ? 'primary' : 'default'}
        key='Block' danger icon={localData.provider.blocked ?? data.provider.blocked ? <UnlockOutlined /> : <LockOutlined />}
        disabled={isUserRoleMarketing(userAuthData)}
      >
        {localData.provider.blocked ?? data.provider.blocked ? 'Unblock' : 'Block'}
      </Button>
    </Skeleton>)

  const providerTypeExist = data.providerTypes.find((providerType:any) => providerType.id === localData.provider?.providerTypeId || providerType.id === data?.provider?.providerTypeId)
  const downloadQRCode = () => {
    const svg = document.getElementById("qRCode") as HTMLCanvasElement;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);

      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = `${data?.provider?.fullName}`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <Card>
      <Skeleton loading={loading} active>
        <Descriptions title="Personal Info" column={6} layout='vertical'>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Full Name">
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 10}}>
              <Paragraph
                  style={{ fontWeight: 600, wordBreak: 'break-all', margin: 0, marginRight: 10,  marginLeft: 5, display: 'table-row' }}
                  editable={!isUserRoleMarketing(userAuthData) && { onChange: (newValue: string) => newValue.trim() !== (localData.provider.fullName || data.provider.fullName) && handleInfoChange({fullName: newValue.trim()}, 'fullName') }}>
                {localData.provider.fullName || data.provider.fullName}
              </Paragraph>
              {updateLoading === 'fullName' && <Spin size='small'/>}
            </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Provider Type">
            <div style={{ display: 'flex', flexDirection: 'column' }} >
              <Select
                  value={providerTypeExist ? localData.provider?.providerTypeId || data?.provider?.providerTypeId : ''}
                  onChange={(value: string) => handleInfoChange({providerTypeId: value}, 'type')}
                  style={{ width: 165 }}
                  disabled={isUserRoleMarketing(userAuthData)}
              >
                {data?.providerTypes?.map((providerType:any) => {
                  return(
                      <Option
                          value={providerType.id}
                          key={providerType.id}
                      >
                        {
                          providerType.name || `${providerType.type[0]}${providerType.type.slice(1).toLowerCase()}`
                        }
                      </Option>
                  )
                })}
              </Select>
            </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Email">
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Paragraph
                  style={{ fontWeight: 600, wordBreak: 'break-all', margin: 0, marginRight: 10, display: 'table-row' }}
                  editable={!isUserRoleMarketing(userAuthData) && { onChange: (newValue: string) => newValue.trim() !== (localData.provider.user?.email || data.provider.user?.email) && handleInfoChange({ user: {email: newValue.trim()}}, 'email') }}>
                {localData.provider.user?.email || data.provider.user?.email}
              </Paragraph>
              {data.provider.user?.emailVerified
              ? <CheckOutlined style={{ marginRight: 10, paddingTop: 5, color:'#1890ff' }} />
              : <ExclamationCircleOutlined style={{ marginRight: 10, paddingTop: 5, color:'#ff5959' }}/>}
              {updateLoading === 'email' && <Spin size='small'/>}
            </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Date of Birth">
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <DatePicker
                allowClear={false}
                style={{ marginRight: 10 }}
                onChange={(momentObj: any) => { momentObj && handleInfoChange({ birthDate: new Date(`${moment(momentObj._d).format(dateFormat)}Z00:00:00.000`)}, 'birthDate') }}
                value={moment(localData.provider.birthDate || data.provider.birthDate, dateFormat)}
                format={dateFormat}
                disabled={isUserRoleMarketing(userAuthData)}
              />
              {updateLoading === 'birthDate' && <Spin size='small'/>}
            </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Gender">
            <Select
              onChange={(gender: Gender) => handleInfoChange({ gender }, 'gender')}
              value={localData.provider.gender || data.provider.gender}
              style={{ width: 165 }}
              loading={updateLoading === 'gender'}
              disabled={isUserRoleMarketing(userAuthData)}
            >
              <Option value={Gender.MALE}>Male</Option>
              <Option value={Gender.FEMALE}>Female</Option>
              <Option value={Gender.OTHER}>Rather not to say</Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Experience">
              <Paragraph style={{ fontWeight: 600 }}>
                {getExperienceYear(data.provider.experience)}
              </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Last login">
            <Paragraph style={{ fontWeight: 600 }}>
              {data.provider.user?.lastLoginDate
                ? formatDate(data.provider.user?.lastLoginDate, 'DD-MM-YYYY HH:mm')
                : 'N/A'}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Last time app opened">
            <Paragraph style={{ fontWeight: 600 }}>
              {data.provider.user?.lastActiveDate
                ? formatDate(data.provider.user?.lastActiveDate, 'DD-MM-YYYY HH:mm')
                : 'N/A'}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Last modification in the app">
            <Paragraph style={{ fontWeight: 600 }}>
              {data.provider.user?.lastUpdatedDate
                ? formatDate(data.provider.user?.lastUpdatedDate, 'DD-MM-YYYY HH:mm')
                : 'N/A'}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Registration Date">
            <Paragraph style={{ fontWeight: 600 }}>
              {data.provider.user?.createdDate
                ? formatDate(data.provider.user?.createdDate, 'DD-MM-YYYY HH:mm')
                : 'N/A'}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Approved Date">
            <Paragraph style={{ fontWeight: 600 }}>
              {data.provider.approvalDate
                ? formatDate(data.provider.approvalDate, 'DD-MM-YYYY HH:mm')
                : 'N/A'}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Code of Conducts">
            {data.provider.user?.codeOfConductsAccepted
              ? <Space>
                  <Typography style={{ fontWeight: 600 }}>Accepted</Typography>
                  <CheckOutlined style={{ color:'#1890ff'}} />
                </Space>
              : <Space>
                  <Typography style={{ fontWeight: 600 }}>Non Accepted</Typography>
                  <ExclamationCircleOutlined style={{ color:'#ff5959'}} />
                </Space>}
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Terms and Conditions">
            {data.provider.user?.termsAndConditionsAccepted
              ? <Space>
              <Typography style={{ fontWeight: 600 }}>Accepted</Typography>
              <CheckOutlined style={{ color:'#1890ff'}} />
            </Space>
          : <Space>
              <Typography style={{ fontWeight: 600 }}>Non Accepted</Typography>
              <ExclamationCircleOutlined style={{ color:'#ff5959'}} />
            </Space>}
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5}} label="Languages">
            <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <Select
                style={{ maxWidth: 300 }}
                bordered={isLanguagesEditable}
                loading={updateLoading === 'languages'}
                disabled={!isLanguagesEditable || isUserRoleMarketing(userAuthData)}
                mode="multiple"
                allowClear={false}
                value={((editedLanguages?.length && editedLanguages) || localData?.provider?.languages || data?.provider?.languages)?.map((language: any) => `${language?.id}#${language?.name}`)}
                onChange={handleLanguagesChanges}
              >
                {data?.allLanguages?.map((language: any) => (
                  <Option value={`${language.id}#${language.name}`} key={language.id}>{ISO6391.getName(language.name.toLowerCase())}</Option>
                ))}
              </Select>
              <Space style={{ flexDirection: "row", display: 'flex', }}>
                <Button
                    style={{ margin: 0, padding: 0}} type='link'
                    onClick={onEditLanguages}
                    disabled={isUserRoleMarketing(userAuthData)}
                >
                  {isLanguagesEditable ? 'Cancel' :'Edit'}
                </Button>
                {isLanguagesEditable && <Button type='link' onClick={onSaveLanguages} style={{ marginLeft: 5, padding: 0 }}>Save</Button>}
              </Space>
            </Space>
          </Descriptions.Item>
          {data?.provider?.serviceTypes?.length &&
          <Descriptions.Item style={{ paddingBottom: 5}}  label="Service types">
            <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              {
                 data?.provider?.serviceTypes?.map((serviceType:{id: any; serviceName: any}) => {
                  return (
                      serviceType.id &&
                      <Paragraph style={{ marginBottom: 3, padding: '4px 8px', border: '1px solid #d9d9d9', borderRadius: 2, cursor: 'pointer' }} key={serviceType.id}>
                        {serviceType.serviceName}
                      </Paragraph>)
                })
              }
            </Space>
          </Descriptions.Item>}
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Availability">
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 10}}>
              <Paragraph style={{ fontWeight: 600, wordBreak: 'break-all', margin: 0, marginRight: 10,  marginLeft: 5, display: 'table-row' }}>
                {localData.provider?.availability || data.provider?.availability || 'N/A'}
              </Paragraph>
            </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Short Bio">
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 10}}>
            <Paragraph style={{ fontWeight: 600, wordBreak: 'break-all', margin: 0, marginRight: 10,  marginLeft: 5, display: 'table-row' }}>
              {localData.provider?.shortBio || data.provider?.shortBio || 'N/A'}
            </Paragraph>
            </div>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: 5 }} label="Long Bio">
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 10}}>
              <Paragraph style={{ fontWeight: 600, wordBreak: 'break-all', margin: 0, marginRight: 10,  marginLeft: 5, display: 'table-row' }}>
                {localData.provider?.longBio || data.provider?.longBio || 'N/A'}
              </Paragraph>
            </div>
          </Descriptions.Item>
        </Descriptions>
        {data.paymentMethods?.cards?.length > 0 &&
          <Descriptions title="Cards" column={6} layout='vertical' style={{ marginTop: 20 }}>
            {data?.paymentMethods?.cards?.map((method: any) => (
              <Descriptions.Item style={{ paddingBottom: 5}} key={method.last4} label={method.brand}>{method.last4}</Descriptions.Item>
            ))}
          </Descriptions>
        }
        {data.provider.addresses?.length &&
          <Descriptions
            layout='vertical'
            title={<>Address{updateLoading === 'address' && <Spin style={{ marginLeft: 20}} size='small'/>}</>}
            style={{ marginTop: 20 }}
            column={7}
          >
            {((localData?.provider?.address && [localData?.provider?.address]) || data?.provider?.addresses)?.map((address: Address, index: number) => {
              return (
                <React.Fragment key={index}>
                  {Object.keys(address)?.map((addressKey: string, index: number) => {
                    return (address as any)[addressKey] ? (
                      <Descriptions.Item style={{ paddingBottom: 5, marginLeft: index === 0 ? 30 : 0}} key={addressKey} label={textToSentence(addressKey)}>
                        <Paragraph
                          style={{ fontWeight: 600, wordBreak: 'break-all', marginRight: 10, display: 'table-row', marginLeft: index === 0 ? 15 : 0 }}
                          editable={!isUserRoleMarketing(userAuthData) && { onChange: (newValue: string) => {newValue.trim() !== (address as any)[addressKey] && handleInfoChange({ address: {...(localData.provider.address || data.provider.addresses[0]), [addressKey]: newValue.trim() }}, 'address') }}}
                        >
                          {(address as any)[addressKey]}
                        </Paragraph>
                      </Descriptions.Item>) : null;
                  })}
                </React.Fragment>
              )
            })}
          </Descriptions>
        }
        {data.paymentMethods?.bankAccounts?.length > 0 &&
          <Descriptions title="Bank accounts" column={6} layout='vertical' style={{ marginTop: 20 }}>
            {data?.paymentMethods?.bankAccounts?.map((method: any) => (
              <Descriptions.Item key={method.last4} label={method.brand}>{method.last4}</Descriptions.Item>
            ))}
          </Descriptions>
        }
        <Descriptions style={{ marginTop: 20 }} title='Comment'>
          <Descriptions.Item>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start' }}>
              <TextArea
                placeholder='Your Comment...'
                style={{ width: '50%'}}
                value={commentText}
                onChange={handleCommentChange}
                rows={4}
                contentEditable
                disabled={isUserRoleMarketing(userAuthData)}
              />
              {((localData.provider.user?.comment || data.provider.user?.comment) !== commentText) &&
                <Button
                    loading={updateLoading === 'comment'}
                    onClick={handleCommentSave}
                    type='primary'
                    style={{ marginTop: 15 }}
                    disabled={isUserRoleMarketing(userAuthData)}
                >
                  Save Comment
                </Button>}
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Skeleton>
      <Modal
        title="Payment Verification Error"
        visible ={!!paymentVerificationErrors.length}
        footer={[
          <Button
              key="back"
              onClick={() => setPaymentErrors([])}
              disabled={isUserRoleMarketing(userAuthData)}
          >
            OK
          </Button>
        ]}
        width="800px"
        closable={false}
      >
        {
          paymentVerificationErrors?.map((error, index) => {
            return (
              <p key={`${index}-${error}`}>{error}</p>
            )
          })
        }
      </Modal>
      <Modal
        title="Reason for rejection"
        onCancel={() => { setModalRejectReasonVisible(false); setRejectReason(''); }}
        visible ={!!isModalRejectReasonVisible}
        footer={[
          <Button
              danger
              onClick={() => {reject(rejectReason); setModalRejectReasonVisible(false) }}
              disabled={isUserRoleMarketing(userAuthData)}
          >
            Reject
          </Button>
        ]}
        width="800px"
        closable={true}
      >
        <Input.TextArea
          placeholder='Reject Reason'
          value={rejectReason}
          onChange={(e: any) => setRejectReason(e.currentTarget.value)}
          maxLength={300}
          disabled={isUserRoleMarketing(userAuthData)}
        />
      </Modal>
      {!loading &&<Space style={{ position: 'absolute', display: 'flex' , alignItems: 'center', top: -105, left: 280}}>{actions}</Space>}
      <Descriptions column={6} style={{ position: 'absolute', top: -78, right: 350, width: 300,}} >
        <Descriptions.Item style={{ paddingBottom: 5}} label="Position">
          <div style={{ flexDirection: 'column', display: 'flex', marginLeft: 10, width: '100%'}}>
            <Paragraph style={{ fontWeight: 600, margin: 0, marginRight: 10,  marginLeft: 5, width: '100%'}}
              editable={ !isUserRoleMarketing(userAuthData)  && { onChange: (newValue: string) => {
                  newValue.trim() !== (localData.provider.position || data.provider.position)
                  && handleInfoChange({position: newValue.trim()}, 'position')
              },
                maxLength: 25, autoSize: { maxRows: 1, minRows: 1 },
            }}
            >
              {localData.provider.position || data.provider.position}
            </Paragraph>
          </div>
        </Descriptions.Item>
      </Descriptions>
      {data?.provider?.referralCode &&
          <Typography.Text style={{ position: 'absolute', top: -110, right: 350, width: 300, fontWeight: 600, cursor: 'pointer' }}>
            {`Referral Code: ${data?.provider?.referralCode}`}
          </Typography.Text>
      }
      {data.provider.status && data.provider.status !== 'DRAFT' &&
          <div style={{ position: 'absolute', top: -129, right: 100, display: 'flex', alignItems: 'flex-end' }} >
            <QRCode
                id={'qRCode'}
                style={  {  width: '110px', height: 'auto', marginRight: '10px' }}
                value={`fp:${id}`}
            />
            <Button onClick={downloadQRCode} disabled={isUserRoleMarketing(userAuthData)}>Download QR</Button>
          </div>
      }
      {data.provider.rejectMessage &&
        <Space
          style={{
            position: 'absolute',
            display: 'flex',
            top: data.provider.rejectMessage ? -225 :-200 ,
            right: 0,
            maxWidth: '85%',
            backgroundColor: '#fff',
            border: '1px solid #ff4d4f',
            padding: '5px 16px',
            marginBottom: 16
          }}
        >
          <Typography>
            <span style={{ color: '#ff4d4f'}}><ExclamationCircleOutlined /> Rejected: </span>
            {data.provider.rejectMessage}
          </Typography>
        </Space>
      }
    </Card>
  );
};


interface IWorkingPlace {
  city: string;
  countryCode?: string;
  id: string;
  serviceCityId: string | null;
}
