import React from 'react';
import { Image, Input, Select, Space, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ModalContent } from './Admins';
import countryCodes from '../../utils/countryCodes';
import { ModalTitle, User } from './admins-enums';

const { Text } = Typography;
const { Option } = Select;

interface AdminModalContentProps {
    modalTitle: ModalTitle;
    setModalContent(content: ModalContent): void;
    modalContent: ModalContent; 
}

export const AdminModalContent: React.FC<AdminModalContentProps> = ({ modalTitle, setModalContent, modalContent }) => {
        switch(modalTitle) {
            case ModalTitle.NEW_ADMIN:
            case ModalTitle.EDIT_ADMIN: 
                return <NewEditAdminContent
                            setModalContent={setModalContent}
                            modalContent={modalContent}
                        />
            case ModalTitle.DELETE_ADMIN: 
                return <DeleteAdminContent adminName={modalContent.fullName} />
            default:
                return <div></div>
        }
}


interface NewEditAdminContentProps {
    setModalContent(content: ModalContent): void;
    modalContent: ModalContent;
}

const NewEditAdminContent: React.FC<NewEditAdminContentProps> = ({ setModalContent, modalContent }) => {
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModalContent({...modalContent, email: event.target.value})
    } 

    const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModalContent({...modalContent, fullName: event.target.value})
    } 

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const number = event.target.value
        if (((parseInt(number[number.length - 1]) || parseInt(number[number.length - 1]) === 0) &&
                typeof parseInt(event.target.value) === 'number') || number === '')  {
            setModalContent({...modalContent, phoneNumber: event.target.value});
        } 
    } 

    const handleChangeCallingCode = (countryCode: string) => {
        setModalContent({...modalContent, countryCode })
        const country = countryCodes.find(country => {
            return country.key === countryCode
        });
        setModalContent({...modalContent, countryCode, callCode: country!.callingCode })
    }

    const handleUserSelect = (role: string) => {
        setModalContent({...modalContent, role})
    }

    return (
        <Space style={styles.AdminContentContainer as React.CSSProperties}>
            <Select onChange={handleUserSelect} value={modalContent.role} style={{ width: '100%'}}>
                <Option value={User.Admin}>Admin User</Option>
                <Option value={User.Marketing}>Marketing User</Option>
            </Select>
            <Input type='text' value={modalContent.fullName} placeholder='Full Name' onChange={handleFullNameChange} />
            <Input type='email' value={modalContent.email} placeholder='Email' onChange={handleEmailChange} />
            <Space>
                <InfoCircleOutlined />
                <Text>Password will be sent to this email</Text>
            </Space>
            <div style={styles.PhoneNumberContainer as React.CSSProperties}>
                <Select style={{ width: 120}} value={modalContent.countryCode} onChange={handleChangeCallingCode}>
                    {countryCodes.map(country => {
                        return (
                            <Option key={country.key} value={country.key}>
                                <Space>
                                    <Image width={20} src={country.flag} />
                                    <Text>{`+${country.callingCode}`}</Text>
                                </Space>
                            </Option>
                        )
                    })}
                </Select>
                <Input onChange={handlePhoneNumberChange} value={modalContent.phoneNumber} style={{ margin: '0 8px'}}  />
            </div>
        </Space>
    )
}

interface DeleteAdminContentProps {
    adminName: string;
}

export const DeleteAdminContent: React.FC<DeleteAdminContentProps> = ({ adminName }) => {
    return (
        <Text>
            Are you sure, you want to delete <span style={{fontWeight: 'bold'}}>{`${adminName}`}</span> Admin?
        </Text>
    )
}

const styles = {
    AdminContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 200,
        alignItems: 'stretch',
    },
    PhoneNumberContainer: {
        display: 'flex',
        flexDirection: 'row',
    }
}