import React from "react"
import { ModalTitle } from "./Campaings"
import { CampaingType, PromoCodeRequest } from "./campaingTypes";
import { DeleteModalContent } from "./DeleteModalContent";
import { NewCampaingContent } from "./NewCampaingContent";


interface CampaingnModalContentProps {
    modalTitle: ModalTitle;
    modalContent: PromoCodeRequest;
    setModalContent: (content: PromoCodeRequest) => void;
    campaingType: CampaingType;
    setCampaingType: (campaingType: CampaingType) => void;
}

export const CampaingnModalContent: React.FC<CampaingnModalContentProps> = ({ modalTitle, modalContent, setModalContent, campaingType, setCampaingType }) => {

        switch(modalTitle) {
            case ModalTitle.deleteCampaing: 
                return <DeleteModalContent />
            case ModalTitle.newCampaing:
            case ModalTitle.viewCampaign:
                return <NewCampaingContent
                            modalContent={modalContent}
                            setModalContent={setModalContent}
                            campaingType={campaingType}
                            setCampaingType={setCampaingType}
                            modalTitle={modalTitle}
                        />;
            default: return null;
        }

}

