import React from 'react';
import {
  Redirect
} from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Alert,
  Typography,
  Space,
} from 'antd';
import {
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';

import { LoginResult, LoginStatus, useLogin, LoginResponse } from './useLogin';

import './login.css';

const { Text } = Typography;

export const Login: React.FC<{}> = () => {
  const { loading, logIn, loginResult, loginResp, verify } = useLogin();

  const onFinish = ({ email, password }: any) => {
    logIn({ email, password });
  };

  const onVerify = ({ verificationCode }: any) => {
    verify(verificationCode)
  }

  if (loginResult && (loginResult.status === LoginStatus.PENDING_VERIFIED || loginResult.status === LoginStatus.VERIFICATION_ERROR)) {
    return (
      <VerificationForm
        loginResult={loginResult}
        loading={loading}
        onVerify={onVerify}
        loginResp={loginResp}
      />
    );
  }

  if (loginResult && loginResult.status === LoginStatus.VERIFIED) {
    return (
      <Redirect to="/dashboard" />
    );
  }

  return (
    <div className="login-form">
      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        size="large"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <div style={{ marginBottom: 20 }}>
          {loginResult.status === LoginStatus.ERROR && <Alert message="Invalid username or password" type="error" />}
          {loginResult.status === LoginStatus.LIMIT_EXCEEDED && <Alert message="Please Try Again in a 1 Minute" type="error" />}
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

interface VerificationFormProps {
  onVerify(params: any): void;
  loading: boolean;
  loginResult: LoginResult;
  loginResp: LoginResponse;
}

const VerificationForm: React.FC<VerificationFormProps> = ({ loading, onVerify, loginResult, loginResp }) => {
  return (
    <div className="login-form">
      <Form
        name="login"
        initialValues={{ remember: false }}
        onFinish={onVerify}
        size="large"
      >
        <Space style={styles.VerificationFormTitleContainer as React.CSSProperties}>
          <Text>You Get a Message On Number</Text>
          <Text>{loginResp.mobileNumberMasked}</Text>
          <Text>{loginResp.smsCode && `For dev mode please enter ${loginResp.smsCode}`}</Text>
        </Space>
        <Form.Item
          name="verificationCode"
          rules={[{ required: true, message: 'Enter SMS Code' }]}
        >
          <Input placeholder="Code" />
        </Form.Item>
        <div style={{ marginBottom: 20 }}>
          {loginResult.status === LoginStatus.VERIFICATION_ERROR && <Alert message="Invalid Verification Code" type="error" />}
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
            Verify
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

const styles = {
  VerificationFormTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8
  }
}
