import React from 'react';
import { Modal } from 'antd';
import { TransactionItem, TransactionItemData } from './TransactionItem';

interface TransactionHistoryProps {
  setVisible: (visible: boolean) => void;
  transactions: TransactionItemData[] | null;
  visibleModal: boolean;
}


export const TransactionHistory: React.FC<TransactionHistoryProps> = ({ setVisible, transactions, visibleModal }) => {
  return (
    <Modal
      title="Transaction History"
      visible={visibleModal}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
    >
      {
        !transactions && <div>Loading...</div>
      }
      {
        transactions && !transactions.length && <div>Empty Transactions</div>
      }
      {transactions && transactions.map((transactionItem: TransactionItemData, index: number) => {
        return (
          <TransactionItem
            key={`${transactionItem.createdDate}-${transactionItem.type}-${index}`}
            itemData={transactionItem}
          />
        )
      })}
    </Modal>
  )
}